import { Box, Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { useState } from "react";

export const FlightExperience = ({ experience, crewType }) => {
  const [totalPicHours, setTotalPicHours] = useState(0);
  const [totalSicHours, setTotalSicHours] = useState(0);

  useEffect(() => {
    if (!experience || !experience.length) return;
    if (crewType === "flight crew") {
      setTotalPicHours(
        experience?.reduce((acc, curr) => {
          return acc + curr.pic_hours;
        }, 0)
      );
      setTotalSicHours(
        experience?.reduce((acc, curr) => {
          return acc + curr.sic_hours;
        }, 0)
      );
    }
  }, [experience, crewType]);
  return (
    <Box
      sx={{
        padding: "0.8rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "0.6rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      {crewType === "flight crew" ? (
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper} sx={{ mt: "0.7rem" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#F4F4FB",
                  }}
                >
                  <TableCell sx={{ fontWeight: "bold" }}>Aircraft</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    PIC Hours
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    SIC Hours
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Total Hours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {experience &&
                  experience?.length > 0 &&
                  experience?.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.aircraft_type?.manufacturer +
                          "-" +
                          row.aircraft_type?.type +
                          `(${row.aircraft_type?.model})`}
                      </TableCell>
                      <TableCell align="right">{row.pic_hours}</TableCell>
                      <TableCell align="right">{row.sic_hours}</TableCell>
                      <TableCell align="right">
                        {row.pic_hours + row.sic_hours}
                      </TableCell>
                    </TableRow>
                  ))}
                {experience && experience?.length > 1 && (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      component="th"
                      scope="row"
                    >
                      Total
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      {totalPicHours}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      {totalSicHours}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      {totalPicHours + totalSicHours}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
              Total Years Experience: {experience?.total_years}
            </Typography>
            <Box sx={{ width: "2rem" }} />
            <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
              Corporate Years Experience: {experience?.corporate_years}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
