import { useDispatch } from "react-redux";
import { Box, IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { AuthenticationFeature } from "../../../features/authentication";
import { Modal } from "../../../components/backdrop-modal";
import { LoadingButton } from "../../../components/buttons/loading-button";
import { CustomTextField } from "../../../components/inputs";
import { icons } from "../../../icons";

export const ChangePassword = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = async ({ currentPassword, newPassword }, helpers) => {
    await AuthenticationFeature.changePassword({ currentPassword, newPassword })(dispatch);

    helpers.resetForm();

    onClose();
  };

  const formik = useFormik({
    initialValues: { currentPassword: "", newPassword: "" },
    validationSchema: yup.object({
      currentPassword: yup.string().min(6).required("Password is required"),
      newPassword: yup.string().min(6).required("Password is required"),
    }),
    onSubmit: handleSubmit,
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      headerTitle="Change Password"
      open={open}
      onClose={handleCancel}
      headerActions={
        <Box>
          <IconButton sx={{ padding: "0.2rem" }} onClick={handleCancel}>
            {icons.close({})}
          </IconButton>
        </Box>
      }
      modalActions={
        <Box>
          <LoadingButton variant="outlined" onClick={formik.submitForm} isLoading={formik.isSubmitting} fullWidth>
            {formik.isSubmitting ? "Saving" : "Save"}
          </LoadingButton>
        </Box>
      }
    >
      <Box component="form" noValidate>
        <CustomTextField
          label="Current Password"
          name="currentPassword"
          type="password"
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.currentPassword && formik.errors.currentPassword)}
          helperText={formik.touched.currentPassword && formik.errors.currentPassword}
          placeholder="Enter your Current Password"
        />
        <CustomTextField
          label="New Password"
          name="newPassword"
          type="password"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          placeholder="Enter your New Password"
        />
      </Box>
    </Modal>
  );
};
