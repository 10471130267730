import { Avatar, Box, Chip, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ElevatedCard } from "../elevated-card";

export const CandidateCard = ({ candidate, selectState }) => {
  const { id, isPremium, image, name, role, createdAt, jobTitle } = candidate;

  const navigate = useNavigate();

  return (
    <ElevatedCard
      sx={{
        padding: "1rem",
        // mt: { xs: "1rem", sm: "1.2rem" },
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "1rem",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/candidates/view?candidateId=${id}`)}
    >
      <Avatar src={image} sx={{ height: "3.6rem", width: "3.6rem" }} />
      <Box sx={{ flex: 1, ml: "1rem" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Typography variant="h6">{name}</Typography>
          </Box>
          {isPremium && (
            <Chip
              sx={{
                ml: "1rem",
                padding: "0px",
                height: "1.6rem",
                textTransform: "uppercase",
              }}
              label="featured"
            />
          )}
        </Box>
        <Typography variant="subtitle2">{role}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Typography variant="caption" sx={{ color: "grey" }}>
            Applied on {moment(createdAt).format("MMM DD, YYYY.")}
          </Typography>
          <Typography>{!selectState && jobTitle}</Typography>
        </Box>
      </Box>
    </ElevatedCard>
  );
};
