import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { axiosInterceptors } from "../core/sources/network";
import { AuthenticationFeature } from "../features/authentication";
import { PageTopLoader } from "../components/loaders/page-top-loader";

export const AuthStateListener = ({ children }) => {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  const location = useLocation();

  const [prevRoute, setPrevRoute] = useState(location?.pathname);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (location.pathname !== prevRoute) {
      setPrevRoute(location?.pathname);
      setIsLoading(true);
    }
  }, [location, prevRoute]);

  axiosInterceptors(() => AuthenticationFeature.reInitialize(dispatch));

  useEffect(() => {
    if (isLoading || !authentication.initialized) {
      AuthenticationFeature.initializeAccount()(dispatch).then(() => {
        setIsLoading(false);
      });
    }
  }, [isLoading, authentication.initialized, dispatch]);

  if (!authentication.initialized) {
    return <PageTopLoader />;
  }

  return children;
};
