import axios from "axios";
import { handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const fetchAircraftTypes = ({ search }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get("https://static-resources.herokuapp.com/aircraft-types", {
        params: { search, limit: 100 },
      });

      return handleAxiosResponse(response).data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
