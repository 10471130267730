import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";

import { getAppStoreCurrentState } from "../../../store";
import { actions as mainProfileActions } from "../../main-profile/slice";
import { handleError } from "../../app-messages";

export const verifyAccount = ({ code }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.patch("/authentication/verifications/email", {
        email: mainProfile.email,
        code,
      });

      const { data } = handleAxiosResponse(response);

      const recruiterProfile = appLocalStorage.get(lskeys.recruiterProfile);

      appLocalStorage.save({
        [lskeys.recruiterProfile]: { ...recruiterProfile, ...data },
      });

      dispatch(
        mainProfileActions.setProfileVerified({
          verified: data.verified,
        })
      );

      return { verified: data.verified };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
