import { Fragment, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { JobTypeDropDown } from "../../../components/dropdowns/job-type";
import { SectorDropDown } from "../../../components/dropdowns/sector";
import { CrewTypeDropDown } from "../../../components/dropdowns/crew-type";
import { designations, RoleDropDown } from "../../../components/dropdowns/role";
import { ElevatedCard } from "../../../components/elevated-card";
import { useDispatch, useSelector } from "react-redux";
import { PostJobFormFeature } from "../../../features/post-job-form";

export const JobTypeAndRoleStep = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { formState } = useSelector((state) => state.postJobForm);
  const { contractType, sector, crewType, role } = formState;
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      contractType: contractType || "full time",
      sector: sector || "airline",
      crewType: crewType || "flight crew",
      role: role || "captain",
    },

    validationSchema: yup.object({
      contractType: yup.string().required("Required"),
      sector: yup.string().required("Required"),
      crewType: yup.string().required("Required"),
      role: yup.string().required("Required"),
    }),

    onSubmit: async (typeInfo) => {
      setSaving(true);
      await PostJobFormFeature.saveToDraft({ jobInfo: typeInfo })(dispatch);
      setSaving(false);
      onNext();
    },
  });

  const handleCrewTypeChange = (event) => {
    formik.setFieldValue("crewType", event.target.value);
    formik.setFieldValue("role", designations[event.target.value][0]);
  };

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          padding: { xs: "0rem 1.2rem", sm: "0.6rem 2rem 2rem 2rem" },
          backgroundColor: "#FFFAFA",
        }}
      >
        <JobTypeDropDown
          error={Boolean(formik.touched.contractType && formik.errors.contractType)}
          helperText={formik.touched.contractType && formik.errors.contractType}
          onChange={formik.handleChange}
          value={formik.values.contractType}
        />
        <SectorDropDown
          error={Boolean(formik.touched.sector && formik.errors.sector)}
          helperText={formik.touched.sector && formik.errors.sector}
          onChange={formik.handleChange}
          value={formik.values.sector}
        />
        <CrewTypeDropDown
          error={Boolean(formik.touched.crewType && formik.errors.crewType)}
          helperText={formik.touched.crewType && formik.errors.crewType}
          onChange={handleCrewTypeChange}
          value={formik.values.crewType}
        />
        <RoleDropDown
          error={Boolean(formik.touched.role && formik.errors.role)}
          helperText={formik.touched.role && formik.errors.role}
          onChange={formik.handleChange}
          value={formik.values.role}
          crewType={formik.values.crewType}
        />
      </ElevatedCard>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onBack} sx={{ borderRadius: "1rem" }}>
          Back
        </Button>
        <Box sx={{ width: "2rem" }} />
        <Button onClick={formik.submitForm} sx={{ borderRadius: "1rem" }} disabled={saving}>
          {saving ? <CircularProgress size="1.4rem" /> : "Continue"}
        </Button>
      </Box>
    </Fragment>
  );
};
