import { Box, InputBase, Typography } from "@mui/material";
import { TextFieldBackground } from "./background";

export const TextField = ({ label, name, value, onChange, onBlur, error, helperText, ...otherProps }) => {
  return (
    <Box sx={{ my: "0.8rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>
      <TextFieldBackground>
        <InputBase {...{ label, name, value, onChange, onBlur, ...otherProps }} />
      </TextFieldBackground>
      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
