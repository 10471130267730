import { Fragment, useCallback, useLayoutEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { PostJobModal } from "./components/confirm-job-posting";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BasicDetailsStep } from "./components/job-basic-details-step";
import { JobTypeAndRoleStep } from "./components/job-type-step";
import { JobDescriptionStep } from "./components/job-description-step";
import { RequirementsStep } from "./components/job-requirements-step";
import { useDispatch, useSelector } from "react-redux";
import { PostJobFormFeature } from "../../features/post-job-form";
import { JobInsightsStep } from "./components/job-insight-step";
import { icons } from "../../icons";

export const PostJobPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postJobForm } = useSelector((state) => state);

  const [activeStep, setActiveStep] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [searchParams] = useSearchParams();

  const initializationCall = useCallback(async () => {
    await PostJobFormFeature.initializeForm()(dispatch);

    const existingJobId = searchParams.get("jobId");

    if (existingJobId) {
      if (postJobForm.draftId) {
        const discard = window.confirm(
          "There is already a draft available. Do you want to discard it?"
        );
        if (discard) {
          await PostJobFormFeature.discardDraft()(dispatch);
        }
      }

      await PostJobFormFeature.fetcExistingJob({ jobId: existingJobId })(
        dispatch
      );
    }

    setInitialized(true);
  }, [dispatch, searchParams, postJobForm.draftId]);

  useLayoutEffect(() => {
    initializationCall();
  }, []);

  const onBack = () => {
    setActiveStep((prev) => (activeStep === 0 ? prev : prev - 1));
  };

  const onNext = () => {
    setActiveStep((prev) => (activeStep === 4 ? prev : prev + 1));
  };

  return (
    <Fragment>
      <Box
        sx={{
          margin: { xs: "1rem", sm: "2rem" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Post New Job
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant="text"
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "0.6rem",
            fontWeight: "bold",
          }}
        >
          Cancel
        </Button>
      </Box>
      {!initialized ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "88vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          <Box sx={{ margin: { xs: "0rem 1rem", sm: "0rem 2rem" } }}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              sx={{
                margin: { xs: "2rem 0rem", lg: "2rem" },
                "& .MuiStepConnector-line": {
                  ml: { sm: "7px" },
                  borderLeftColor: "divider",
                  borderLeftWidth: "2px",
                },
              }}
            >
              {[
                {
                  label: "Basic Information",
                  content: <BasicDetailsStep onNext={onNext} />,
                },
                {
                  label: "Type Details",
                  content: (
                    <JobTypeAndRoleStep onBack={onBack} onNext={onNext} />
                  ),
                },
                {
                  label: "Description & Benefits",
                  content: (
                    <JobDescriptionStep onBack={onBack} onNext={onNext} />
                  ),
                },
                {
                  label: "Requirements",
                  content: <RequirementsStep onBack={onBack} onNext={onNext} />,
                },
                {
                  label: "Other Insights",
                  content: (
                    <JobInsightsStep
                      onBack={onBack}
                      onNext={() => setConfirmModalOpen(true)}
                    />
                  ),
                },
              ].map(({ label, content }, index) => {
                return (
                  <Step key={label}>
                    <StepLabel StepIconComponent={StepIcon}>
                      <Typography
                        sx={{ ml: "1rem", fontWeight: "bold" }}
                        variant="h6"
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                    <StepContent
                      sx={{
                        ml: { sm: "19px" },
                        borderLeftColor: "divider",
                        borderLeftWidth: "2px",
                        paddingRight: "0rem",
                        ...(activeStep === index && { py: 4 }),
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "900px",
                          margin: "auto",
                          pl: { sm: "1rem", md: "1.6rem" },
                        }}
                      >
                        {content}
                      </Box>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <PostJobModal
            open={confirmModalOpen}
            onClose={() => setConfirmModalOpen(false)}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const StepIcon = ({ active, completed, icon }) => {
  const highlight = active || completed;

  return (
    <Avatar
      sx={{
        height: { xs: "1.8rem", sm: "2.6rem" },
        width: { xs: "1.8rem", sm: "2.6rem" },
        ...(highlight && {
          backgroundColor: "secondary.main",
          color: "secondary.contrastText",
        }),
      }}
      variant="rounded"
    >
      {completed ? icons.checked() : icon}
    </Avatar>
  );
};
