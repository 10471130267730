import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";
import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { PostJobFormFeature } from "../../post-job-form";
import { actions } from "../slice";

export const postNewJob = ({ formState, paymentMethodId = null }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("/jobs", {
        jobInfo: formState,
        paymentMethodId,
      });

      const { data } = handleAxiosResponse(response);

      appLocalStorage.clearItem([lskeys.paymentSessionId, lskeys.jobFormState]);

      await PostJobFormFeature.discardDraft()(dispatch);

      dispatch(actions.jobPosted({ job: data }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
