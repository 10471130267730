import { Container } from "@mui/material";
import { PageLayout } from "../../components/page-layout";
import { useWindowSize } from "../../hooks/use-window-size";

import { ConversationsList } from "./components/conversations-list";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRouteConstants } from "../../navigations/routes";
import { useEffect } from "react";

export const ConversationsPage = (props) => {
  const size = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      size.width > 1200 &&
      location.pathname === AppRouteConstants.conversations
    ) {
      navigate(AppRouteConstants.viewConversation, { replace: true });
    }
  }, [navigate, location.pathname, size.width]);

  return (
    <PageLayout pageName="Conversations" showAppBar>
      <Container sx={{ height: "calc(100vh - 4rem)", p: { xs: "1rem", md: "2rem" }, display: "flex" }}>
        <ConversationsList />
      </Container>
    </PageLayout>
  );
};
