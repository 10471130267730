import {
  axiosInstance,
  handleAxiosResponse,
} from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const fetchJobseekers = ({ filterOptions, page, search }) => {
  return async (dispatch) => {
    try {
      console.info("searc;", search);
      const pageSize = 10;
      if (
        getAppStoreCurrentState().companyProfile.currentPlan !== "enterprise"
      ) {
        throw new Error(
          "Please upgrade your plan to enterprise to use this feature"
        );
      }
      const response = await axiosInstance.get(`/jobseekers`, {
        params: { filterOptions, page, pageSize, search },
      });

      const { data } = handleAxiosResponse(response);
      console.info("data is", data);
      const payload = {
        count: data.jobseekersCount,
        currentPage: data.pagination.currentPage,
        jobseekers: data.jobseekers,
      };
      console.info("seekers", payload);
      dispatch(actions.setJobseekers(payload));
      return data.pagination.totalPages;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
