import { PageLayout } from "../../../components/page-layout";
import { useEffect, useRef, useState } from "react";
import { PageTopLoader } from "../../../components/loaders/page-top-loader";
import { Box, CircularProgress, Button, Chip, Divider, Paper, Typography } from "@mui/material";
import { SupportFeature } from "../../../features/support";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import UserFeedbackModal from "./user-feedback-modal";
import { icons } from "../../../icons";
import { UploadAttachmentImage } from "./upload-attachment-image";
import { Message } from "./message";
import { MessageInput } from "./message-input";

export const TicketDetailsPage = () => {
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const scrollRef = useRef(null);

  const [ticket, setTicket] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [comments, setComments] = useState([]);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [issueResolved, setIssueResolved] = useState(false);
  const [status, setStatus] = useState("open");
  const [imageUploadModal, setImageUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const ticketId = query.get("ticketId");
    if (!ticketId) return;
    const fetch = async () => {
      setLoading(true);
      const fetchedTicket = await SupportFeature.fetchSupportTicketDetails({
        supportId: ticketId,
      })(dispatch);
      if (fetchedTicket?.resolvedByAgent && fetchedTicket?.status !== "resolved") {
        setFeedbackModalOpen(true);
      }
      if (fetchedTicket?.status) {
        setStatus(fetchedTicket.status);
      }
      setTicket(fetchedTicket);
      setComments(fetchedTicket.comments);
      setInitialized(true);
      setLoading(false);
    };

    fetch();
  }, [dispatch, query]);

  useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 400);
  }, [comments]);

  const markAsResolved = async (supportId) => {
    try {
      const res = await SupportFeature.markIssueAsResolved({ supportId })(dispatch);
      if (res) {
        setIssueResolved(true);
        setFeedbackModalOpen(true);
        setStatus("resolved");
      }
    } catch (error) {}
  };
  return (
    <PageLayout pageName="Support">
      <Box
        sx={{
          margin: { xs: "0rem 1rem 1rem 1rem", sm: "0rem 2rem 2rem 2rem" },
        }}
      >
        {!initialized && <PageTopLoader />}
      </Box>
      {ticket && (
        <Paper
          sx={{
            p: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: "1rem" }}>
                  {ticket.title}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    sx={{
                      mr: "1rem",
                      alignSelf: "flex-start",
                      borderRadius: "2rem",
                    }}
                    onClick={() => {
                      markAsResolved(ticket.id);
                    }}
                    disabled={issueResolved || status === "resolved"}
                  >
                    {issueResolved ? "Resolved" : "Mark this issue as resolved"}
                  </Button>
                  <Chip
                    label={ticket.category}
                    sx={{
                      mr: "1rem",
                      mb: "1rem",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Chip
                  label={ticket.priority} // low, medium, high
                  sx={{
                    mr: "1rem",
                    mb: "1rem",
                    backgroundColor:
                      ticket.priority === "low" ? "#4caf50" : ticket.priority === "medium" ? "#ff9800" : "#f44336",
                    color: "#fff",
                  }}
                  icon={
                    ticket.priority === "low"
                      ? icons.lowPriority({})
                      : ticket.priority === "medium"
                      ? icons.priority({})
                      : icons.highPriority({})
                  }
                />
                <Chip
                  label={status}
                  sx={{
                    mr: "1rem",
                    mb: "1rem",
                    backgroundColor: status === "open" ? "#4caf50" : status === "resolved" ? "#f44336" : "#ff9800",
                    color: "#fff",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Description
                </Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold", mr: "1rem" }}>
                  Created on: {moment(ticket.createdAt).format("DD MMM YYYY")}
                </Typography>
              </Box>
              <Typography variant="subtitle1" sx={{ mt: "1rem" }}>
                {ticket.description}
              </Typography>
              <Divider sx={{ mt: "0.5rem" }} />
              {comments?.length > 0 ? (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      mt: "2rem",
                      mb: "1rem",
                    }}
                  >
                    Comments:
                  </Typography>

                  <Box
                    sx={{
                      height: "40vh",
                      border: "1px solid #ccc",
                      borderRadius: "0.5rem",
                      overflowY: "scroll",
                      padding: "1rem",
                    }}
                  >
                    {comments?.map((comment, index) => (
                      <Message comments={comments} comment={comment} index={index} scrollRef={scrollRef} key={index} />
                    ))}
                  </Box>
                </>
              ) : (
                <>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Typography variant="subtitle1" sx={{ mt: "2rem", mb: "1rem", color: "#777" }}>
                      {status === "resolved"
                        ? "This ticket has been resolved."
                        : "There is no update to this ticket yet. Please check back later."}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
          <MessageInput
            setImageUploadModal={setImageUploadModal}
            issueResolved={issueResolved}
            status={status}
            setComments={setComments}
            comments={comments}
          />
        </Paper>
      )}
      <UserFeedbackModal
        open={feedbackModalOpen}
        handleClose={() => setFeedbackModalOpen(false)}
        ticketId={ticket?.id}
        setIssueResolved={setIssueResolved}
        setStatus={setStatus}
        issueResolved={issueResolved}
      />
      <UploadAttachmentImage
        open={imageUploadModal}
        onClose={(comment) => {
          setImageUploadModal(false);
          if (comment) {
            setComments([...comments, comment]);
          }
        }}
        ticketId={ticket?.id}
      />
    </PageLayout>
  );
};
