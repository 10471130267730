import {
  axiosInstance,
  handleAxiosResponse,
} from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const fetchCandidates = ({
  jobId,
  status,
  filterOptions,
  page,
  pageSize = 10,
  returnResult = false,
  search,
}) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/candidates`, {
        params: { jobId, status, filterOptions, page, pageSize, search },
      });

      const { data } = handleAxiosResponse(response);
      if (returnResult) {
        return data.candidates;
      }

      dispatch(
        actions.setCandidates({
          count: data.candidatesCount,
          currentPage: data.pagination.currentPage,
          status: data.status.toLowerCase(),
          candidates: data.candidates,
        })
      );
      console.info("Candie is", data);
      return data.pagination.totalPages;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
