import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { AuthenticationFeature } from "../../../../features/authentication";
import { TextInput } from "../../../../components/inputs/text";
import { icons } from "../../../../icons";

export const ForgotPasswordForm = (props) => {
  const dispatch = useDispatch();

  const handleSubmit = async ({ email }, helpers) => {
    await AuthenticationFeature.sendResetPasswordLink({ email })(dispatch);
    helpers.resetForm();
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup.string().email("Must be a valid email").max(255).required("Email is required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography variant="h4" sx={{ mb: "0.4rem" }}>
          Forgot Password
        </Typography>
        <Typography>Enter your email address, password reset link will be sent to your email.</Typography>
      </Box>

      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Box>
          <TextInput
            label="Email Address"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            placeholder="Please enter your email"
          />
        </Box>

        <Box sx={{ height: "1rem" }} />

        <Button disabled={formik.isSubmitting} type="submit" variant="contained" sx={{ p: "0.6rem" }}>
          Send
        </Button>
      </Box>

      <Box sx={{ mt: "1.6rem", alignSelf: "flex-end" }}>
        <BackButton />
      </Box>
    </Box>
  );
};

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      sx={{ ml: { xs: "0.8rem", sm: "1.2rem" }, p: "0.3rem 1rem 0.3rem 0.6rem", borderRadius: "2rem" }}
      onClick={() => navigate(-1)}
      variant="contained"
    >
      {icons.arrowLeft({})}
      Back
    </Button>
  );
};
