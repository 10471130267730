import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PageTopLoader } from "../../components/loaders/page-top-loader";

import { PageLayout } from "../../components/page-layout";
import { JobseekersFeature } from "../../features/jobseekers";
import { JobseekerBasicCard } from "./components/jobseeker-basic-card";
import { JobseekerDocuments } from "./components/jobseeker-documents";
import { JobseekerResumePdf } from "./components/jobseeker-resume";
import { JobseekerProfessionalGallery } from "./components/jobseeker-professional-gallery";
import { JobseekerCoverLetterPdf } from "./components/jobseeker-cover-letter";
import CalendarComponent from "./components/candidate-availability-calendar";

const JobseekerDetailsPage = () => {
  const dispatch = useDispatch();
  const [jobseeker, setJobseeker] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [searchParams] = useSearchParams();

  const jobseekerId = searchParams.get("jobseekerId");

  useEffect(() => {
    const fetch = async () => {
      if (jobseekerId) {
        setInitialized(false);
        const fetchedJobseeker = await JobseekersFeature.fetchJobseekerDetails({
          jobseekerId,
        })(dispatch);
        setJobseeker(fetchedJobseeker);
      }
    };

    fetch().finally(() => setInitialized(true));
  }, [dispatch, jobseekerId]);

  return (
    <PageLayout
      pageName="Jobseeker"
      showBackButton
      showPostJobButton
      showAppBar
    >
      {!initialized && <PageTopLoader />}
      {initialized && (
        <Box
          sx={{
            margin: { xs: "1rem 1rem 1rem 1rem", sm: "0rem 2rem 2rem 2rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              alignItems: { xs: "stretch", lg: "flex-start" },
            }}
          >
            <Box sx={{ flex: 1.6 }}>
              <JobseekerBasicCard jobseeker={jobseeker} />
              <Box
                sx={{
                  width: { xs: "1rem", md: "2rem" },
                  height: { xs: "1rem", md: "2rem" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  alignItems: { xs: "stretch", lg: "flex-start" },
                  gap: "1rem",
                }}
              >
                <JobseekerResumePdf
                  jobseekerId={jobseeker?.profileId}
                  jobseekerName={jobseeker?.name}
                  resume={jobseeker?.resume}
                />
                <JobseekerCoverLetterPdf
                  jobseekerId={jobseeker?.profileId}
                  jobseekerName={jobseeker?.name}
                  coverLetter={jobseeker?.coverLetter}
                />
              </Box>
              <Box
                sx={{
                  width: { xs: "1rem", md: "2rem" },
                  height: { xs: "1rem", md: "2rem" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  alignItems: { xs: "stretch", lg: "flex-start" },
                  gap: "1rem",
                }}
              >
                {/* professional gallery */}
                <JobseekerProfessionalGallery jobseeker={jobseeker} />
              </Box>
              <Box
                sx={{
                  width: { xs: "1rem", md: "2rem" },
                  height: { xs: "1rem", md: "2rem" },
                }}
              />
              <JobseekerDocuments jobseeker={jobseeker} />
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "1rem", md: "2rem" },
              height: { xs: "1rem", md: "2rem" },
            }}
          />
          <CalendarComponent jobseekerId={jobseeker?.profileId} />
        </Box>
      )}
    </PageLayout>
  );
};

export { JobseekerDetailsPage };
