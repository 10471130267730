import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store/index";

import { handleError, showSuccess } from "../../app-messages";
import { actions } from "../slice";

export const detachCustomerCard = ({ paymentMethodId }) => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.delete(
        `/billing/${companyProfile.stripeCustomerId}/cards/${paymentMethodId}`,
        { params: { companyId: companyProfile.id } }
      );

      const { data, message } = handleAxiosResponse(response);

      dispatch(actions.removeCard());
      dispatch(showSuccess(message));
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
