import { Fragment, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../components/page-layout";
import { useWindowSize } from "../../hooks/use-window-size";
import { useSelector } from "react-redux";
import { ConversationsList } from "./components/conversations-list";
import { ConversationBox } from "./components/conversation-box";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRouteConstants } from "../../navigations/routes";

export const ConversationPage = (props) => {
  const size = useWindowSize();
  const conversations = useSelector((state) => state.conversations.conversations);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (conversations.length > 0) {
      if (searchParams.get("conversationId") === undefined || searchParams.get("conversationId") === null) {
        navigate(`${AppRouteConstants.viewConversation}?conversationId=${conversations[0].id}`, { replace: true });
      }
    }
  }, [conversations, navigate, searchParams]);

  return (
    <PageLayout pageName="Conversations" showBackButton showAppBar>
      <Container sx={{ height: "calc(100vh - 4rem)", p: { xs: "1rem", md: "2rem" }, display: "flex" }}>
        {size.width > 1200 && (
          <Fragment>
            <ConversationsList />
            <Box sx={{ width: "1rem", display: { xs: "none", md: "block" } }} />
          </Fragment>
        )}
        <ConversationBox />
      </Container>
    </PageLayout>
  );
};
