import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { AuthenticationFeature } from "../../../../features/authentication";
import { CustomTextField } from "../../../../components/inputs";

export const ResetPasswordForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const handleSubmit = async ({ password }, helpers) => {
    const successful = await AuthenticationFeature.resetPassword({ email, token, password })(dispatch);

    helpers.resetForm();

    if (successful) {
      navigate("/authentication?action=sign-in", { replace: true });
    }
  };

  const formik = useFormik({
    initialValues: { password: "" },
    validationSchema: yup.object({
      password: yup
        .string()
        .min(8, "Password must be minimum 8 character long")
        .max(255)
        .required("Password is required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography variant="h4" sx={{ mb: "0.4rem" }}>
          Reset Passsword
        </Typography>
        <Typography variant="subtitle1"> Reset your password, this link is valid for 10 mins</Typography>
      </Box>

      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Box>
          <CustomTextField
            label="Password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            placeholder="Enter new password"
          />
        </Box>

        <Box sx={{ height: "1rem" }} />

        <Button disabled={formik.isSubmitting} type="submit" variant="contained" sx={{ p: "0.6rem" }}>
          Reset Password
        </Button>
      </Box>
    </Box>
  );
};
