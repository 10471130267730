import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const fetchCompanyMembers = () => {
  return async (dispatch) => {
    try {
      const companyProfile = getAppStoreCurrentState().companyProfile;

      const response = await axiosInstance.get(`/companies/${companyProfile.id}/recruiters`);

      const { data } = handleAxiosResponse(response);

      dispatch(actions.setMembers({ members: data }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
