import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const slice = createSlice({
  name: "appMessages",

  initialState: [...initialState],

  reducers: {
    set: (state, { payload }) => {
      state.push(payload.message);
    },

    remove: (state, { payload }) => {
      return state.filter(({ id }) => id !== payload.id);
    },
  },
});

export const { reducer, actions } = slice;
