import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const slice = createSlice({
  name: "companyMembers",

  initialState: [...initialState],

  reducers: {
    setMembers: (_, { payload }) => {
      return payload.members;
    },

    addMember: (state, { payload }) => {
      state.unshift(payload.member);
    },

    removeMember: (state, { payload }) => {
      return state.filter((member) => {
        return member.email !== payload.memberEmail;
      });
    },
  },
});

export const { reducer, actions } = slice;
