import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { handleError, showSuccess } from "../../app-messages";

export const sendResetPasswordLink = ({ email }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("/authentication/password-reset/token", { email });

      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
