import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const fetchCandidatesByCompany = ({ companyId, status, filterOptions, page }) => {
  return async (dispatch) => {
    try {
      const pageSize = 10;

      const response = await axiosInstance.get(`/candidates/get-by-company`, {
        params: {
          companyId,
          status,
          filterOptions,
          page,
          pageSize,
        },
      });

      const { data } = handleAxiosResponse(response);
      const payload = {
        count: data.candidatesCount,
        currentPage: data.pagination.currentPage,
        status: data.status.toLowerCase(),
        candidates: data.candidates,
      };

      dispatch(actions.setCandidates(payload));

      return data.pagination.totalNoOfPages;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
