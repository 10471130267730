import { Box, IconButton, Badge } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CandidatesContainer } from "../../components/candidates-container";
import { PageTopLoader } from "../../components/loaders/page-top-loader";
import { PageLayout } from "../../components/page-layout";
import { StatusFiltersTab, statuses } from "./components/status-filters-tab";
import { CandidatesFeature } from "../../features/candidates";
import { JobSelect } from "./components/job-select";
import { PostJobBanner } from "./components/post-job-banner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRouteConstants } from "../../navigations/routes";
import { useFilterOptions } from "../../hoc/use-filter-options";
import { icons } from "../../icons";
import { JobsFeature } from "../../features/jobs";
import { useCallback } from "react";
import { SearchBar } from "../../components/searchbar";
import { FiltersSideBar } from "../../components/filters-sidebar";

export const CandidatesPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  const [postedJobs, setPostedJobs] = useState({});
  const [loadingPostedJobs, setLoadingPostedJobs] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null);
  const [status, setStatus] = useState(statuses[0]);
  const [filterOptions, setFilterOptions] = useFilterOptions();
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const candidates = useSelector((state) => state.candidates);

  useEffect(() => {
    const jobId = searchParams.get("job");
    if (jobId !== null || jobId !== undefined) {
      setSelectedJob(
        [...(postedJobs?.open ?? []), ...(postedJobs?.closed ?? [])].filter(
          (job) => job._id === jobId
        )[0]
      );
    }
    setStatus(searchParams.get("tab") ?? statuses[0]);
    setCurrentPage(Number(searchParams.get("page") ?? 1));
    setSearch(searchParams.get("search") ?? "");
  }, [postedJobs?.closed, postedJobs?.open, searchParams, status]);

  useEffect(() => {
    JobsFeature.fetchPostedJobsTitle()(dispatch).then((jobs) => {
      const jobId = searchParams.get("job");
      if (jobId !== null || jobId !== undefined) {
        setSelectedJob(
          [...(jobs?.open ?? []), ...(jobs?.closed ?? [])].filter(
            (job) => job._id === jobId
          )[0]
        );
      }
      setPostedJobs(jobs);
      setLoadingPostedJobs(false);
    });
  }, [searchParams, dispatch]);

  const fetchCandidates = useCallback(
    async (page) => {
      setIsLoading(true);
      const totalNoOfPages = await CandidatesFeature.fetchCandidates({
        jobId: selectedJob?._id ?? null,
        status,
        search,
        filterOptions,
        page: page ?? currentPage,
      })(dispatch);
      setTotalPages(totalNoOfPages);
      if (page) handlePageChange(null, page);
      setIsLoading(false);
    },
    [currentPage, dispatch, filterOptions, selectedJob, status, search]
  );

  useEffect(() => {
    if (!loadingPostedJobs) {
      fetchCandidates();
    }
  }, [loadingPostedJobs, fetchCandidates]);

  // useEffect(() => {
  //   // Reset filter options when the page is changed
  //   setFilterOptions({
  //     role: "",
  //     homebase: [],
  //     country: [],
  //     licenses: [],
  //     passports: [],
  //     visas: [],
  //     minPicHours: 0,
  //     maxPicHours: 0,
  //     minSicHours: 0,
  //     maxSicHours: 0,
  //     minCorporateYears: 0,
  //     maxCorporateYears: 0,
  //     minTotalYears: 0,
  //     maxTotalYears: 0,
  //   });
  // }, []);

  const handlePageChange = (_, value) => {
    const page = searchParams.get("page") ?? 1;

    if (page !== value) {
      navigate(`${AppRouteConstants.candidates}?tab=${status}&page=${value}`);
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleJobSelectChange = (job) => {
    if (job) {
      setFilterOptions({
        role: "",
        homebase: [],
        country: [],
        licenses: [],
        passports: [],
        visas: [],
        minPicHours: 0,
        maxPicHours: 0,
        minSicHours: 0,
        maxSicHours: 0,
        minCorporateYears: 0,
        maxCorporateYears: 0,
        minTotalYears: 0,
        maxTotalYears: 0,
      });
      navigate(
        `${AppRouteConstants.candidates}?job=${job._id}&tab=${status}&page=${1}`
      );
    } else {
      navigate(`${AppRouteConstants.candidates}?tab=${status}&page=${1}`);
    }
    setSelectedJob(job);
  };

  const numberOfFiltersApplied = Object.values(filterOptions).filter(
    (value) => value && value !== "" && value.length !== 0
  ).length;

  return (
    <PageLayout pageName="Candidates" showPostJobButton showAppBar>
      {isLoading && <PageTopLoader />}
      <Box
        sx={{
          flex: 1,
          mx: { xs: "1rem", md: "2rem" },
          mb: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <JobSelect
            isLoading={loadingPostedJobs}
            options={postedJobs}
            value={selectedJob}
            onChange={handleJobSelectChange}
          />
          <Badge
            badgeContent={numberOfFiltersApplied}
            color="primary"
            sx={{ mr: "1rem" }}
          >
            <IconButton
              sx={{
                ml: "1rem",
                height: "3rem",
                width: "3rem",
                backgroundColor: "primary.main",
                ":hover": { backgroundColor: "#d22824" },
              }}
              color="primary.main"
              onClick={handleDrawerToggle}
            >
              {icons.tune({ sx: { fontSize: "1.4rem", color: "white" } })}
            </IconButton>
          </Badge>
          <FiltersSideBar
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            isOpen={isDrawerOpen}
            onClose={handleDrawerToggle}
            crewType={selectedJob?.crew_type ?? null}
          />
        </Box>
        {!loadingPostedJobs &&
        [...(postedJobs?.open ?? []), ...(postedJobs?.closed ?? [])].length ===
          0 ? (
          <PostJobBanner />
        ) : (
          <Fragment>
            <StatusFiltersTab
              status={status}
              job={selectedJob ?? null}
              isLoading={isLoading}
            />
            <SearchBar
              navigateUrl={
                selectedJob
                  ? `${AppRouteConstants.candidates}?job=${
                      selectedJob._id
                    }&tab=${status}&page=${1}`
                  : `${AppRouteConstants.candidates}?tab=${status}&page=${currentPage}`
              }
              navigateSearchUrl={(search) =>
                selectedJob
                  ? `${AppRouteConstants.candidates}?job=${
                      selectedJob._id
                    }&tab=${status}&page=${1}&search=${search}`
                  : `${AppRouteConstants.candidates}?tab=${status}&page=${currentPage}&search=${search}`
              }
              sx={{ mb: "1rem" }}
            />
            <CandidatesContainer
              displayedCandidates={candidates[status][currentPage] ?? []}
              isLoading={isLoading}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
              selectState={selectedJob}
              fetchCandidates={fetchCandidates}
            />
          </Fragment>
        )}
      </Box>
    </PageLayout>
  );
};
