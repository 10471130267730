import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError, showSuccess } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";
import { CompanyProfileFeature } from "../../company-profile";
import { actions } from "../slice";

export const subscribeToEnterprise = ({ paymentMethodId, billingPeriod }) => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.put(`/subscriptions/${companyProfile.stripeCustomerId}/enterprise`, {
        companyId: companyProfile.id,
        paymentMethodId,
        billingCycle: billingPeriod,
      });

      const { data: subscription, message } = handleAxiosResponse(response);

      CompanyProfileFeature.updateCurrentPlan({ plan: "enterprise" })(dispatch);

      dispatch(actions.setSubscription({ subscription }));
      dispatch(showSuccess(message));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
