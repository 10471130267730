import { Box, Typography } from "@mui/material";
import moment from "moment";

// This is one of the components that is used in the candidate profile page
export const Education = ({ education }) => {
  return (
    <Box
      sx={{
        padding: "0.8rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "0.6rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
          {education.school_name}
        </Typography>
        <Typography sx={{ fontSize: "1rem" }}>
          {education.course_name}
        </Typography>
        <Typography variant="subtitle2">
          {moment(education.start).format("MMMM YYYY")} -{" "}
          {education.end === null
            ? "Present"
            : `${moment(education.end).format("MMMM YYYY")}`}
        </Typography>
      </Box>
    </Box>
  );
};
