import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { JobsFeature } from "../../features/jobs";
import { PageLayout } from "../../components/page-layout";
import { PageTopLoader } from "../../components/loaders/page-top-loader";
import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { ElevatedCard } from "../../components/elevated-card";
import { TextInput } from "../../components/inputs/text";
import { QuillEditor } from "../../components/quill-editor";

export const EditJobPage = () => {
  const dispatch = useDispatch();
  const [query] = useSearchParams();

  const [initialized, setInitialized] = useState(false);
  const [job, setJob] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      setJob(
        await JobsFeature.fetchJobDetails({ jobId: query.get("jobId") })(
          dispatch
        )
      );
      setInitialized(true);
    };

    fetch();
  }, [query, dispatch]);

  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    const res = await JobsFeature.updateJobDetails({
      jobId: job._id,
      jobInfo: values,
    })(dispatch);
    if (res) {
      formik.resetForm();
      navigate(`/jobs/view?jobId=${job._id}`);
    }
  };
  const formik = useFormik({
    initialValues: {
      title: job?.title ?? "",
      description: job?.description || "",
      benefits: job?.benefits || "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      title: yup.string().required("Required"),
      description: yup.string().required("Required"),
      benefits: yup.string().required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <PageLayout pageName="Edit Job">
      {!initialized && <PageTopLoader />}
      {initialized && (
        <Box
          sx={{
            margin: { xs: "0rem 1rem 1rem 1rem", sm: "0rem 2rem 2rem 2rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              alignItems: { xs: "stretch", lg: "flex-start" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ElevatedCard sx={{ width: "60%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextInput
                    label="Job Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched.title && formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    placeholder="Please enter the job title"
                  />

                  <Typography
                    sx={{ fontWeight: "bold", mt: "1rem", mb: "0.6rem" }}
                  >
                    Job Description
                  </Typography>
                  <QuillEditor
                    onChange={(content) =>
                      formik.setFieldValue("description", content)
                    }
                    placeholder="Give a detailed describtion of the job"
                    sx={{
                      fontFamily: "Torek",
                      height: "18rem",
                      mb: "1.6rem",
                      backgroundColor: "white",
                      borderRadius: "0.6rem",
                    }}
                    value={formik.values.description}
                  />
                  {Boolean(
                    formik.touched.description && formik.errors.description
                  ) && (
                    <Typography
                      variant="subtitle2"
                      sx={{ m: "0.6rem", color: "red" }}
                    >
                      {formik.touched.description && formik.errors.description}
                    </Typography>
                  )}
                  <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>
                    Benefits
                  </Typography>
                  <QuillEditor
                    onChange={(content) =>
                      formik.setFieldValue("benefits", content)
                    }
                    placeholder="Please detail the benefits for this position"
                    sx={{
                      height: "18rem",
                      mb: { xs: "1.6rem", sm: "0rem" },
                      backgroundColor: "white",
                      borderRadius: "0.6rem",
                    }}
                    value={formik.values.benefits}
                  />
                  {Boolean(
                    formik.touched.benefits && formik.errors.benefits
                  ) && (
                    <Typography
                      variant="subtitle2"
                      sx={{ m: "0.6rem", color: "red" }}
                    >
                      {formik.touched.benefits && formik.errors.benefits}
                    </Typography>
                  )}
                  <Button
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                    variant="contained"
                    sx={{
                      borderRadius: "2rem",
                      mt: "1.6rem",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      alignSelf: "flex-end",
                    }}
                  >
                    Update Job
                  </Button>
                </Box>
              </ElevatedCard>
              <ElevatedCard sx={{ width: "30%", height: "fit-content" }}>
                <Typography
                  sx={{ fontWeight: "bold", mb: "0.2rem", color: "#DA2128" }}
                >
                  Note:
                </Typography>
                <Typography sx={{ mb: "0.6rem" }}>
                  You can only edit the Title, Description and Benefits of a job
                  posting.
                </Typography>
              </ElevatedCard>
            </Box>
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};
