import { account } from "./account";
import { arrowLeft } from "./arrow-left";
import { arrowRight } from "./arrow-right";
import { arrowUp } from "./arrow-up";
import { article } from "./article";
import { bookmark } from "./bookmark";
import { bookmarkBorder } from "./bookmark-border";
import { close } from "./close";
import { contactCalendar } from "./contact-calendar";
import { copy } from "./copy";
import { currency } from "./currency";
import { expandLess } from "./expand-less";
import { expandMore } from "./expand-more";
import { facebook } from "./facebook";
import { forum } from "./forum";
import { home } from "./home";
import { info } from "./info";
import { instagram } from "./instagram";
import { linkedin } from "./linkedin";
import { person } from "./person";
import { questionAnswer } from "./question-answer";
import { settings } from "./settings";
import { sort } from "./sort";
import { twitter } from "./twitter";
import { visibilityOff } from "./visibility-off";
import { visibilityOn } from "./visibility-on";
import { helpCenter } from "./help-center";
import { flight } from "./flight";
import { editPen } from "./edit";
import { sentimentVeryDissatisfied } from "./sentiment-very-dissatisfied";
import { sentimentDissatisfied } from "./sentiment-dissatisfied";
import { sentimentSatisfied } from "./sentiment-satisfied";
import { sentimentSatisfiedAlt } from "./sentiment-satisfied-alt";
import { sentimentVerySatisfied } from "./sentiment-very-satisfied";
import { image } from "./image";
import { flagRounded } from "./flag-rounded";
import { flagOutlined } from "./flag-outline";
import { more } from "./more";
import { checked } from "./checked";
import { deleteIcon } from "./delete";
import { upload } from "./upload";
import { hourglassTop } from "./hourglass-top";
import { hourglassBottom } from "./hourglass-bottom";
import { add } from "./add";
import { share } from "./share";
import { search } from "./search";
import { chat } from "./chat";
import { circle } from "./circle";
import { send } from "./send";
import { right } from "./right";
import { left } from "./left";
import { tune } from "./tune";
import { error } from "./error";
import { email } from "./email";
import { phone } from "./phone";
import { map } from "./map";
import { location } from "./location";
import { download } from "./download";
import { lowPriority } from "./low-priority";
import { highPriority } from "./high-priority";
import { priority } from "./priority";
import { menu } from "./menu";
import { work } from "./work";
import { lock } from "./lock";
import { people } from "./people";
import { storage } from "./storage";
import { apartment } from "./apartment";

export const icons = Object.freeze({
  account,
  arrowLeft,
  arrowRight,
  arrowUp,
  article,
  bookmark,
  bookmarkBorder,
  close,
  contactCalendar,
  copy,
  currency,
  expandLess,
  expandMore,
  facebook,
  forum,
  home,
  info,
  instagram,
  linkedin,
  person,
  questionAnswer,
  settings,
  lock,
  sort,
  twitter,
  visibilityOff,
  visibilityOn,
  helpCenter,
  flight,
  editPen,
  sentimentVeryDissatisfied,
  sentimentDissatisfied,
  sentimentSatisfied,
  sentimentSatisfiedAlt,
  sentimentVerySatisfied,
  image,
  flagRounded,
  flagOutlined,
  more,
  checked,
  deleteIcon,
  upload,
  hourglassTop,
  hourglassBottom,
  add,
  share,
  search,
  chat,
  circle,
  send,
  right,
  left,
  tune,
  error,
  email,
  phone,
  map,
  location,
  download,
  lowPriority,
  highPriority,
  priority,
  menu,
  work,
  people,
  storage,
  apartment,
});
