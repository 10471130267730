import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BodyCell, HeaderCell } from "./table-cells";

export const PlanFeaturesColumn = ({ featuresList }) => {
  return (
    <Box sx={{ flex: 1, paddingY: "1rem" }}>
      <HeaderCell sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="overline" sx={{ color: "primary.main" }}>
          Feature
        </Typography>
      </HeaderCell>
      {featuresList.map((option) => (
        <BodyCell key={option.name}>
          <Typography variant="subtitle2">{option.name}</Typography>
        </BodyCell>
      ))}
    </Box>
  );
};
