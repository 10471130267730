import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SubscriptionsFeature } from "../../features/subscription";
import { SectionCard } from "../../components/section-card";
import { AllPlansAndPricings } from "./components/all-plans-and-pricings";

const MySubscription = (props) => {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state);

  useEffect(() => {
    SubscriptionsFeature.fetchCurrentSubsription()(dispatch);
  }, [dispatch]);

  return (
    <>
      <Box sx={{ mb: "1.8rem" }}>
        <SectionCard
          title={
            <Box
              component="span"
              sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
              <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                <Box component="span">Current Plan</Box>
                <PlanChip plan={subscription.plan} />
              </Box>
              {subscription.inTrial && (
                <Typography
                  component="span"
                  sx={{
                    fontSize: "0.88rem",
                    ml: "0.6rem",
                    padding: "0rem 0.6rem",
                    backgroundColor: "primary.main",
                    color: "white",
                    borderRadius: "0.4rem",
                    textTransform: "uppercase",
                  }}
                >
                  in trial
                </Typography>
              )}
            </Box>
          }
        >
          <Box sx={{ mt: "1rem", mb: "-0.4rem", display: "flex", flexDirection: "column" }}>
            <CardRow title="Billing Scheme" value={subscription.billingCycle} />
            <CardRow title="Status" value={subscription.status} />
            <CardRow
              title={subscription.status === "cancelled" ? "Expires In" : "Renews In"}
              value={moment(subscription.validTill).diff(Date.now(), "days") + " days"}
            />
          </Box>
        </SectionCard>
      </Box>
      <AllPlansAndPricings />
    </>
  );
};

const PlanChip = ({ plan }) => {
  return (
    <Typography
      component="span"
      sx={{
        fontSize: "0.88rem",
        ml: "0.6rem",
        padding: "0rem 0.6rem",
        backgroundColor: "primary.main",
        color: "white",
        borderRadius: "0.4rem",
        textTransform: "uppercase",
      }}
    >
      {plan}
    </Typography>
  );
};

const CardRow = ({ title, value }) => {
  return (
    <Box sx={{ mt: "0.4rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography sx={{ fontSize: "1.08rem", textTransform: "capitalize" }}>{title}</Typography>
      <Typography sx={{ fontSize: "0.96rem", textTransform: "capitalize" }}>{value}</Typography>
    </Box>
  );
};

const MemoizedMySubscription = React.memo(MySubscription);

export { MemoizedMySubscription as MySubscription };
