import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError, showSuccess } from "../../app-messages";

export const changePassword = ({ currentPassword, newPassword }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.put("/authentication/password-change", {
        email: mainProfile.email,
        currentPassword,
        newPassword,
      });

      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
