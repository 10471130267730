import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  Rating,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SupportFeature } from "../../../features/support";
import PropTypes from "prop-types";
import { icons } from "../../../icons";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: icons.sentimentVeryDissatisfied({ color: "error", sx: { fontSize: "3rem" } }),
    label: "Very Dissatisfied",
  },
  2: {
    icon: icons.sentimentDissatisfied({ color: "error", sx: { fontSize: "3rem" } }),
    label: "Dissatisfied",
  },
  3: {
    icon: icons.sentimentSatisfied({ color: "warning", sx: { fontSize: "3rem" } }),
    label: "Neutral",
  },
  4: {
    icon: icons.sentimentSatisfiedAlt({ color: "success", sx: { fontSize: "3rem" } }),
    label: "Satisfied",
  },
  5: {
    icon: icons.sentimentVerySatisfied({ color: "success", sx: { fontSize: "3rem" } }),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

// this modal is to confirm if the issue is resolved or not
const UserFeedbackModal = ({ open, handleClose, ticketId, setIssueResolved, setStatus, issueResolved }) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(4);
  const [description, setDescription] = useState("");
  const [resolved, setResolved] = useState(false);
  const [sending, setSending] = useState(false);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async () => {
    setSending(true);
    if (resolved) {
      setStatus("resolved");
      setIssueResolved(true);
    }
    await SupportFeature.addFeedback({
      supportId: ticketId,
      feedbackInfo: {
        rating,
        description,
        resolved: issueResolved ? issueResolved : resolved,
      },
    })(dispatch);

    setSending(false);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          backgroundColor: "white",
          width: "80%",
          maxWidth: "38rem",
          minWidth: { xs: "90%", sm: "26rem" },
          padding: "0.7rem 1.4rem",
          borderRadius: "0.8rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1.4rem",
          }}
        >
          <Typography variant="h6">
            {/* write a title to ask user about the experience of support */}
            How was your experience with our support?
          </Typography>
          <IconButton onClick={handleClose}>{icons.close({})}</IconButton>
        </Box>
        {!issueResolved && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "1.4rem",
              gap: "1rem",
            }}
          >
            <Typography variant="body1">Please let us know the issue status from your end.</Typography>
            {/* mui checkbox to get the resolve value */}
            {/* <Checkbox
              checked={resolved}
              onChange={(event) => {
                setResolved(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            /> */}
            {/* use an mui dropdown having two options: Solved, Need more support. if solved, then we setResolved to true, false otherwise */}
            <Select
              // native
              value={resolved}
              onChange={(event) => {
                setResolved(event.target.value);
              }}
              inputProps={{
                name: "resolved",
                id: "resolved",
              }}
              sx={{ width: "100%" }}
            >
              <MenuItem value={true}>Solved</MenuItem>
              <MenuItem value={false}>Need more support</MenuItem>
            </Select>
          </Box>
        )}
        {/* 
        <Typography variant="body1" sx={{ marginBottom: "1.4rem" }}>
          How would you rate our support?
        </Typography> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1.4rem",
          }}
        >
          {/* <Typography variant="body1">Rate us</Typography> */}
          <StyledRating
            name="simple-controlled"
            value={rating}
            onChange={handleRatingChange}
            size="large"
            sx={{ fontSize: "5rem" }}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
          />
        </Box>
        {/* did we solve your issue? */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1.4rem",
          }}
        >
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            variant="outlined"
            placeholder="Write your feedback here..."
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              fontSize: "1rem",
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
            disabled={sending}
          >
            {sending && (
              <>
                {" "}
                &nbsp;&nbsp;&nbsp;
                <CircularProgress size="1.4rem" />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}

            {!sending && "Submit"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserFeedbackModal;

// Path: src/components/modals/feedback-modal.js
