import "react-phone-number-input/style.css";
import PhoneNumberInput from "react-phone-number-input";
import { TextFieldBackground } from "../textfield/background";
import { Box, InputBase, Typography } from "@mui/material";
import React from "react";

const InputComponent = React.forwardRef((props, ref) => {
  return <InputBase {...props} inputRef={ref} fullWidth />;
});

export const PhoneNumberField = ({ label, name, value, onChange, onBlur, error, helperText }) => {
  const handleChange = (newNumber) => {
    onChange({ target: { name, value: newNumber } });
  };

  return (
    <Box sx={{ my: "0.8rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>
      <TextFieldBackground>
        <PhoneNumberInput
          inputComponent={InputComponent}
          style={{ width: "100%" }}
          numberInputProps={{ sx: { ml: "0.3rem" } }}
          defaultCountry="US"
          value={value}
          onChange={handleChange}
          placeholder="Please enter phone number"
        />
      </TextFieldBackground>
      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
