import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { getAppStoreCurrentState } from "../../../../store";
import { AuthenticationFeature } from "../../../../features/authentication";
import { SignOutButton } from "../../../../components/buttons/sign-out";
import { TextInput } from "../../../../components/inputs/text";
import { AppRouteConstants } from "../../../../navigations/routes";

export const VerifyAccountForm = (props) => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  useEffect(() => {
    if (getAppStoreCurrentState().mainProfile.verified) {
      naviagte("/", { replace: true });
    }
  }, [naviagte]);

  const handleSubmit = async ({ otp }, helpers) => {
    const { verified } = await AuthenticationFeature.verifyAccount({ code: otp })(dispatch);

    helpers.resetForm();

    if (verified) {
      naviagte(AppRouteConstants.createOrJoinCompany, { replace: true });
    }
  };

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: yup.object({
      otp: yup.string().length(6, "Enter valid OTP code").required("OTP field cannot be empty"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography variant="h4" sx={{ mb: "0.4rem" }}>
          Account Verification
        </Typography>
        <Typography variant="subtitle1">Verify your email to continue to the site</Typography>
      </Box>

      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          label="OTP Code"
          name="otp"
          value={formik.values.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.otp && formik.errors.otp)}
          helperText={formik.touched.otp && formik.errors.otp}
          placeholder="Please enter the otp code"
        />

        <Box sx={{ height: "1rem" }} />

        <Button disabled={formik.isSubmitting} type="submit" variant="contained" sx={{ p: "0.6rem" }}>
          Verify Account
        </Button>
      </Box>

      <ResendOtpLink />

      <Box sx={{ mt: "1.6rem", alignSelf: "flex-end" }}>
        <SignOutButton />
      </Box>
    </Box>
  );
};

const ResendOtpLink = () => {
  const dispatch = useDispatch();

  const onClick = async () => {
    await AuthenticationFeature.sendOTPVerificationEmail()(dispatch);
  };

  return (
    <Box sx={{ mt: "2rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography variant="subtitle2" sx={{ color: "black" }}>
        The OTP code will be valid for 10 mins
      </Typography>
      <Typography
        onClick={onClick}
        sx={{
          cursor: "pointer",
          textAlign: "center",
          ":hover": {
            textDecoration: "underline",
          },
        }}
      >
        Resend OTP
      </Typography>
    </Box>
  );
};
