import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SupportFeature } from "../../../features/support";

export const CreateTicketModal = ({ open, onClose }) => {
  const [category, setCategory] = useState("");
  const [priority, setPriority] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [priorityError, setPriorityError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setCategoryError(false);
  };

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
    setPriorityError(false);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setTitleError(false);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError(false);
  };

  const resetFields = () => {
    setCategory("");
    setPriority("");
    setTitle("");
    setDescription("");
    setCategoryError(false);
    setPriorityError(false);
    setTitleError(false);
    setDescriptionError(false);
    setSubmitting(false);
  };

  const handleSubmit = async () => {
    try {
      if (!category || !priority || !title || !description) {
        if (!category) setCategoryError(true);
        if (!priority) setPriorityError(true);
        if (!title) setTitleError(true);
        if (!description) setDescriptionError(true);
        return;
      }
      if (title.length > 80) {
        setTitleError(true);
        return;
      }

      setSubmitting(true);
      const ticket = {
        category,
        priority,
        title,
        description,
      };
      const response = await SupportFeature.createNewTicket({
        ticketInfo: ticket,
      })(dispatch);
      if (response) {
        resetFields();
        onClose({ token: response.token, response });
      } else {
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose({ token: "" })}>
      <DialogTitle>Create Ticket</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill out the following information to create a ticket.</DialogContentText>
        <Box sx={{ mt: "1rem" }}>
          <FormControl fullWidth>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={category}
              label="Category"
              onChange={handleCategoryChange}
              error={categoryError}
              sx={{ textTransform: "capitalize" }}
            >
              {categories.map((category, i) => (
                <MenuItem key={i} value={category} sx={{ textTransform: "capitalize" }}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: "1rem" }}>
          <FormControl fullWidth>
            <InputLabel id="priority-select-label">Priority</InputLabel>
            <Select
              labelId="priority-select-label"
              id="priority-select"
              value={priority}
              label="Priority"
              onChange={handlePriorityChange}
              error={priorityError}
            >
              <MenuItem value={"low"}>Low</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"high"}>High</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: "1rem" }}>
          <TextField
            fullWidth
            id="title"
            label="Title"
            value={title}
            onChange={handleTitleChange}
            error={titleError}
            inputProps={{ maxLength: 80 }}
            placeholder="Please keep your title short and concise."
          />
        </Box>
        <Box sx={{ mt: "1rem" }}>
          <TextField
            fullWidth
            id="description"
            label="Description"
            multiline
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            error={descriptionError}
            placeholder="Write as much as you need to describe your issue."
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose({ token: "" });
            resetFields();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={submitting}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const categories = ["general", "billing", "technical", "accounts", "complaint", "feature request", "other"];
