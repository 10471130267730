import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";
import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";
import { actions as mainProfileActions } from "../../main-profile/slice";
import { actions as companyProfileActions } from "../../company-profile/slice";

export const acceptInviteAndJoinCompany = ({ invitationId }) => {
  return async (dispatch) => {
    try {
      const mainProfile = getAppStoreCurrentState().mainProfile;

      const response = await axiosInstance.post(`/recruiters/${mainProfile.email}/invitations/${invitationId}`);

      const { data } = handleAxiosResponse(response);

      const recruiterProfile = { ...mainProfile, company: data.id };

      appLocalStorage.save({ [lskeys.companyProfile]: data });
      appLocalStorage.save({ [lskeys.recruiterProfile]: recruiterProfile });

      dispatch(companyProfileActions.setProfile({ profile: data }));
      dispatch(mainProfileActions.setCompany({ company: recruiterProfile.company }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
