import { useState } from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import { icons } from "../../icons";

export const CustomTextField = ({ label, name, type, value, onChange, onBlur, error, helperText, ...otherProps }) => {
  const inputProps = { name, type, value, onChange, onBlur, error, fullWidth: true };

  return (
    <Box sx={{ flex: 1, my: "0.8rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>
      <Box sx={{ padding: "0.44rem 0.88rem", borderRadius: "4px", backgroundColor: "#F4F4FB" }}>
        {type === "password" ? (
          <PasswordInputBase {...inputProps} {...otherProps} />
        ) : (
          <InputBase {...inputProps} {...otherProps} />
        )}
      </Box>
      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

const PasswordInputBase = (props) => {
  const [show, toggleShow] = useState(false);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <InputBase {...props} type={show ? "text" : "password"} />
      <Box onClick={() => !props.disabled && toggleShow((prev) => !prev)} sx={{ mb: "-0.4rem", cursor: "pointer" }}>
        {show ? icons.visibilityOn({}) : icons.visibilityOff({})}
      </Box>
    </Box>
  );
};
