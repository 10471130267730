import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { CandidatesFeature } from "../../../features/candidates";
import { ElevatedCard } from "../../../components/elevated-card";
import { CircularProgress } from "@mui/material";
import { CandidateCard } from "../../../components/cards/candidate";

export const RecentCandidates = ({ jobId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const [loaded, setLoaded] = useState(false);
  const [recentCandidates, setRecentCandidates] = useState([]);

  useEffect(() => {
    CandidatesFeature.fetchCandidates({ jobId: query.get("jobId"), page: 1, pageSize: 5, returnResult: true })(
      dispatch
    ).then((candidates) => {
      console.log(candidates);
      setRecentCandidates(candidates);
      setLoaded(true);
    });
  }, []);

  return (
    <ElevatedCard sx={{ mt: "2rem" }}>
      <Box>
        <Box sx={{ mt: "-0.4rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", textTransform: "capitalize" }}>
            Recent Candidates
          </Typography>
          <Button
            onClick={() => navigate(`/candidates?tab=awaiting review&job=${query.get("jobId")}`)}
            variant="contained"
            sx={{ ml: "0.8rem", borderRadius: "2rem", textTransform: "capitalize" }}
          >
            View All
          </Button>
        </Box>
        <Divider sx={{ mt: "1rem", mb: "1rem" }} />
        <Box>
          {loaded ? (
            recentCandidates.length === 0 ? (
              <Typography>No Candidates Yet</Typography>
            ) : (
              <Box>
                {recentCandidates.map((candidate) => (
                  <CandidateCard key={candidate.id} selectState={query.get("jobId")} candidate={candidate} />
                ))}
              </Box>
            )
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Box>
    </ElevatedCard>
  );
};
