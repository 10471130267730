import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { CompanyMembersFeature } from "../../../features/company-members";
import { Modal } from "../../../components/backdrop-modal";
import { CustomTextField } from "../../../components/inputs";
import { LoadingButton } from "../../../components/buttons/loading-button";
import { icons } from "../../../icons";

const InviteMemberModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = async ({ email }, helpers) => {
    await CompanyMembersFeature.inviteMember({ email })(dispatch);

    helpers.resetForm();

    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: { email: "" },

    validationSchema: yup.object({ email: yup.string().required("email required") }),

    onSubmit: handleSubmit,
  });

  const modalHeaderActions = (
    <Box>
      <IconButton
        onClick={() => {
          formik.resetForm();
          onClose();
        }}
        sx={{ padding: "0.6rem" }}
      >
        {icons.close({})}
      </IconButton>
    </Box>
  );

  const modalActions = (
    <Box>
      <LoadingButton variant="outlined" onClick={formik.submitForm} isLoading={formik.isSubmitting} fullWidth>
        {formik.isSubmitting ? "Sending" : "Invite"}
      </LoadingButton>
    </Box>
  );

  return (
    <Modal
      headerTitle="Invite Member To Your Company"
      headerActions={modalHeaderActions}
      open={open}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      modalActions={modalActions}
    >
      <Box component="form" onSubmit={formik.handleSubmit} noValidate>
        <CustomTextField
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          placeholder="Please enter the email"
        />
      </Box>
    </Modal>
  );
};

const MemoizedInviteMemberModal = React.memo(InviteMemberModal);

export { MemoizedInviteMemberModal as InviteMemberModal };
