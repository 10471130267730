import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const authentication = useSelector((state) => state.authentication);
  const mainProfile = useSelector((state) => state.mainProfile);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (authentication.isAuthenticated && mainProfile.profileId && !socket) {
      setSocket(
        io(process.env.REACT_APP_CONVERSATIONS_API_BASE_URL, {
          query: {
            profileId: mainProfile.profileId,
            type: "recruiters",
          },
        })
      );
    } else if (!authentication.isAuthenticated) {
      socket?.disconnect();
      setSocket(null);
    }
    return () => {
      socket?.disconnect();
      setSocket(null);
    };
  }, [authentication.isAuthenticated, mainProfile.profileId]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export const useSocket = () => {
  return useContext(SocketContext);
};
