import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../hoc/protected-route";
import { AuthenticationPage } from "../pages/authentication";
import { JobsPage } from "../pages/jobs";
import { JobDetailsPage } from "../pages/jobs/job-details";
import { EditJobPage } from "../pages/jobs/edit-job";
import { PostJobPage } from "../pages/jobs/post-job";
import { CandidatesPage } from "../pages/candidates";
import { JobseekersPage } from "../pages/jobseekers";
import { JobseekerDetailsPage } from "../pages/jobseekers/jobseeker-details";
import { CandidateDetailsPage } from "../pages/candidates/candidate-details";
import { ConversationsPage } from "../pages/conversations";
import { ConversationPage } from "../pages/conversations/conversation";
import { AccountPage } from "../pages/account";
import { CompanyProfilePage } from "../pages/company-profile";
import SupportPage from "../pages/support";
import { TicketDetailsPage } from "../pages/support/components/ticket-details";

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/authentication" element={<AuthenticationPage />} />
        <Route index path="/" element={<ProtectedRoute children={<JobsPage />} />} />
        <Route path="/jobs/view" element={<ProtectedRoute children={<JobDetailsPage />} />} />
        <Route path="/jobs/edit" element={<ProtectedRoute children={<EditJobPage />} />} />
        <Route path="/jobs/post" element={<ProtectedRoute children={<PostJobPage />} />} />
        <Route path="/candidates" element={<ProtectedRoute children={<CandidatesPage />} />} />
        <Route path="/candidates/view" element={<ProtectedRoute children={<CandidateDetailsPage />} />} />
        <Route path="/jobseekers" element={<ProtectedRoute children={<JobseekersPage />} />} />
        <Route path="/jobseekers/view" element={<ProtectedRoute children={<JobseekerDetailsPage />} />} />
        <Route path="/conversations" element={<ProtectedRoute children={<ConversationsPage />} />} />
        <Route path="/conversations/view" element={<ProtectedRoute children={<ConversationPage />} />} />
        <Route path="/account" element={<ProtectedRoute children={<AccountPage />} />} />
        <Route path="/company-account" element={<ProtectedRoute children={<CompanyProfilePage />} />} />
        <Route path="/support" element={<ProtectedRoute children={<SupportPage />} />} />
        <Route path="/support/view" element={<ProtectedRoute children={<TicketDetailsPage />} />} />
      </Routes>
    </>
  );
};
