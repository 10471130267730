import moment from "moment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const ActivityStatus = ({ activity }) => {
  if (activity.status === "offline") {
    return (
      <Typography sx={{ mt: "0.2rem", fontSize: "0.8rem" }}>
        {activity.updatedAt ? "Online " + moment(activity?.updatedAt).fromNow() : "Offline"}
      </Typography>
    );
  }

  if (activity?.status === "online") {
    const size = "0.6rem";

    return (
      <Box
        sx={{
          mt: "0.2rem",
          height: size,
          width: size,
          borderRadius: "50%",
          backgroundColor: "green",
        }}
      />
    );
  }
};
