import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./root-reducer.js";
import { Provider } from "react-redux";

const appStore = configureStore({ reducer: rootReducer });

export const AppStoreProvider = ({ children }) => {
  return <Provider store={appStore}>{children}</Provider>;
};

export const getAppStoreCurrentState = appStore.getState;

export default appStore;
