import { createSlice } from "@reduxjs/toolkit";

const initialState = { newMessagesCount: 0, conversations: [], messages: {} };

export const slice = createSlice({
  name: "conversations",

  initialState: { ...initialState },

  reducers: {
    setNewMessagesCount: (state, { payload }) => {
      state.newMessagesCount = payload;
    },

    updateNewMessagesCount: (state, { payload }) => {
      if (state.newMessagesCount !== 0) {
        state.newMessagesCount = state.newMessagesCount + payload;
      }
    },

    setConversations: (state, { payload }) => {
      const filteredConversations = filterAndSortConversations([
        ...state.conversations,
        ...payload,
      ]);
      state.conversations = filteredConversations;
    },

    addConversation: (state, { payload }) => {
      state.conversations.push(payload);
    },

    updateConversationLatestMessage: (state, { payload }) => {
      const { conversationId, message } = payload;

      const updatedConversations = state.conversations.map((conversation) => {
        if (conversation.id === conversationId) {
          conversation.latestMessage = message;
          conversation.updatedAt = message.createdAt;
        }
        return conversation;
      });

      state.conversations = filterAndSortConversations(updatedConversations);
    },

    removeConversation: (state, { payload }) => {
      state.conversations = state.conversations.filter(
        ({ id }) => id !== payload.id
      );
    },

    clearConversations: (state) => {
      state.conversations = [];
    },

    setMessages: (state, { payload }) => {
      const { conversationId, messages } = payload;

      const filteredMessages = Array.from(
        new Map(
          [...messages, ...(state.messages[conversationId] ?? [])].map(
            (item) => [item.id, item]
          )
        ).values()
      );

      state.messages[conversationId] = filteredMessages;
    },

    addMessage: (state, { payload }) => {
      const { conversationId, message } = payload;

      const filteredMessages = Array.from(
        new Map(
          [...(state.messages[conversationId] ?? []), message].map((item) => [
            item.id,
            item,
          ])
        ).values()
      );

      state.messages[conversationId] = filteredMessages;
    },

    clearMessages: (state) => {
      state.messages = {};
    },
  },
});

export const { reducer, actions } = slice;

const filterAndSortConversations = (conversations) => {
  const uniqueConversationsMap = new Map(
    conversations.map((item) => [item.id, item])
  );
  return Array.from(uniqueConversationsMap.values()).sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );
};
