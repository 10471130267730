import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const unarchiveConversation = (conversationId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations/${conversationId}/unarchive`
      );
      const { data, message } = handleAxiosResponse(response);

      dispatch(actions.removeConversation({ id: conversationId }));
      dispatch(actions.clearMessages());

      return { data, message };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
