import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { getAppStoreCurrentState } from "../../../../store";
import { AppRouteConstants } from "../../../../navigations/routes";
import { TextInput } from "../../../../components/inputs/text";
import { SignOutButton } from "../../../../components/buttons/sign-out";
import { CompanyProfileFeature } from "../../../../features/company-profile";
import { useEffect, useState } from "react";
import { CompanyMembersFeature } from "../../../../features/company-members";
import { ElevatedCard } from "../../../../components/elevated-card";

export const CreateOrJoinCompanyForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mainProfile } = useSelector((state) => state);

  const [invitations, setInvitations] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    CompanyMembersFeature.fetchRecruiterInvitations()(dispatch).then((fetchedInvitations) => {
      setInvitations(fetchedInvitations);
      setInitialized(true);
    });
  }, [dispatch]);

  useEffect(() => {
    if (mainProfile.company) {
      return navigate(AppRouteConstants.jobs, { replace: true });
    }
  }, [mainProfile.company, invitations.length, navigate]);

  const handleSubmit = async (values) => {
    const profileDetails = { name: values.name, about: values.about };

    await CompanyProfileFeature.createCompanyProfile({ profileDetails })(dispatch);

    if (getAppStoreCurrentState().mainProfile.company) {
      return navigate(AppRouteConstants.jobs, { replace: true });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      about: "",
    },

    validationSchema: yup.object({
      name: yup.string().min(6, "Name must be minimum 6 character long").max(255).required("Name is required"),
      about: yup.string().required("Required"),
    }),

    onSubmit: handleSubmit,
  });

  if (!initialized) {
    return (
      <Box sx={{ alignSelf: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (invitations.length !== 0) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ mb: "1rem" }}>
          <Typography variant="h4" sx={{ mb: "0.4rem" }}>
            You Have Invitations
          </Typography>
          <Typography variant="subtitle1">
            Please accept to join this company or decline and set up your own company.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", my: "1rem" }}>
          {invitations.map((invitation) => {
            return <Invitation key={invitation.id} invitation={invitation} setInvitations={setInvitations} />;
          })}
        </Box>

        <Box sx={{ mt: "1.6rem", alignSelf: "flex-end" }}>
          <SignOutButton />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography variant="h4" sx={{ mb: "0.4rem" }}>
          Create Your Company Profile
        </Typography>
        <Typography variant="subtitle1">Register with a new account to continue</Typography>
      </Box>

      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          sx={{ my: "0.8rem" }}
          label="Company Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          placeholder="Please enter your company name"
        />
        <TextInput
          sx={{ my: "0.8rem" }}
          label="About Your Company"
          name="about"
          value={formik.values.about}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.about && Boolean(formik.errors.about)}
          helperText={formik.touched.about && formik.errors.about}
          placeholder="Please write something about your company"
          multiline
          rows={2}
        />
        <Box sx={{ height: "1rem" }} />
        <Button disabled={formik.isSubmitting} type="submit" variant="contained" sx={{ p: "0.6rem" }} disableElevation>
          Create And Continue
        </Button>
      </Box>

      <Box sx={{ mt: "1.6rem", alignSelf: "flex-end" }}>
        <SignOutButton />
      </Box>
    </Box>
  );
};

const Invitation = ({ invitation, setInvitations }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const handleDecline = async () => {
    setSubmitting(true);
    await CompanyMembersFeature.declineInvitation({
      invitationId: invitation.id,
    })(dispatch);
    setInvitations((prev) => prev.filter(({ id }) => invitation.id !== id));
    setSubmitting(false);
  };

  const handleAccept = async () => {
    setSubmitting(true);
    await CompanyMembersFeature.acceptInviteAndJoinCompany({
      invitationId: invitation.id,
    })(dispatch);
    setInvitations((prev) => prev.filter(({ id }) => invitation.id !== id));
    setSubmitting(false);
  };

  return (
    <ElevatedCard
      sx={{
        my: "0.6rem",
        padding: "1rem",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {invitation.company?.image && (
          <Box
            component="img"
            src={invitation.company.image}
            sx={{
              height: "3rem",
              width: "3rem",
              borderRadius: "2rem",
              mr: "1rem",
            }}
          />
        )}
        <Typography>
          {invitation.recruiterName} has invited you to join {invitation.company.name}.
        </Typography>
      </Box>
      <Box width="2rem" />
      <Box sx={{ display: "flex", alignSelf: { xs: "flex-end", sm: "center" } }}>
        <Button
          color="secondary"
          sx={{ padding: "0.2rem", borderRadius: "2.5rem" }}
          onClick={handleDecline}
          disabled={submitting}
        >
          Decline
        </Button>
        <Box width="1rem" />
        <Button sx={{ padding: "0.2rem", borderRadius: "2.5rem" }} onClick={handleAccept} disabled={submitting}>
          Accept
        </Button>
      </Box>
    </ElevatedCard>
  );
};
