import React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "../../hooks/use-navigate";
import { icons } from "../../icons";
// import { icons } from "../../icons";

const SideBarLinkTile = ({
  href,
  title,
  icon,
  backgroundColor = "white",
  pathIndicator,
}) => {
  const navigate = useNavigate(href);
  const isSelected = useLinkSelection(pathIndicator);

  return (
    <Box
      sx={{
        my: "1.6rem",
        background: "white",
        display: "flex",
        alignItems: "center",
        padding: "0.6rem 0.8rem",
        borderRadius: "0.6rem",
        backgroundColor,
        color: backgroundColor !== "white" ? "white" : "black",
        ":hover": {
          color: backgroundColor !== "white" ? "#EFEFEF" : "#e53935",
          cursor: "pointer",
        },
      }}
      onClick={navigate}
    >
      {icon({ fontSize: "small" })}
      <Box width="1rem" />
      <Typography>{title}</Typography>
      {isSelected &&
        icons.arrowRight({
          size: "small",
          color: "#e53935",
          sx: { ml: "auto" },
        })}
    </Box>
  );
};

const useLinkSelection = (pathIndicator) => {
  const location = useLocation();
  const path = location.pathname + location.search;
  const isSelected =
    path.includes(pathIndicator) ||
    (pathIndicator === "jobspage" &&
      (path === "/" ||
        path.includes("jobs/view") ||
        (path.includes("status=") &&
          path.includes("search=") &&
          path.includes("sortBy=") &&
          path.includes("page="))));
  return isSelected;
};

const MemoizedSideBarLinkTile = React.memo(SideBarLinkTile);

export { MemoizedSideBarLinkTile as SideBarLinkTile };
