import {
  axiosInstance,
  handleAxiosResponse,
} from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const fetchPostedJobs = ({
  status,
  page,
  search,
  sortBy,
  recruiterProfileId = null,
}) => {
  return async (dispatch) => {
    try {
      const pageSize = 10;

      const response = await axiosInstance.get("/jobs", {
        params: {
          recruiterProfileId,
          status,
          page,
          pageSize,
          search,
          sortBy,
        },
      });

      const { data } = handleAxiosResponse(response);

      const payload = {
        count: data.postedJobsCount,
        currentPage: data.pagination.currentPage,
        status: data.status.toLowerCase(),
        jobs: data.postedJobs,
      };

      dispatch(actions.setJobs(payload));

      return data.pagination.totalPages;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
