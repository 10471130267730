import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { BillingFeature } from "../../../features/billing";
import { SectionCard } from "../../../components/section-card";
import { GeneratePaymentMethodIdModal } from "../../../components/payments/generate-payment-method-id";

export const PaymentMethod = () => {
  const dispatch = useDispatch();
  const billing = useSelector((state) => state.billing);
  const recruiterProfile = useSelector((state) => state.mainProfile);
  const companyProfile = useSelector((state) => state.companyProfile);

  const [openAddCardDetails, setOpenAddCardDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setOpenAddCardDetails(false);
  };

  const handleComplete = async (paymentMethodId) => {
    await BillingFeature.attachCustomerCard({ paymentMethodId })(dispatch);
  };

  const handleDetachCard = async () => {
    setLoading(true);
    await BillingFeature.detachCustomerCard({ paymentMethodId: billing.card.paymentMethodId })(dispatch);
    setLoading(false);
  };

  return (
    <>
      <SectionCard
        title="Payment Method"
        actions={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {billing.card && (
              <Button
                color="secondary"
                sx={{ borderRadius: "2rem", padding: "0.2rem 0.4rem", mr: { xs: "0.6rem", sm: "1rem" } }}
                onClick={handleDetachCard}
                disabled={recruiterProfile.permission !== "admin" || loading || companyProfile.currentPlan !== "lite"}
              >
                remove
              </Button>
            )}
            <Button
              sx={{ borderRadius: "2rem", padding: "0.2rem 0.6rem" }}
              onClick={() => setOpenAddCardDetails(true)}
              disabled={recruiterProfile.permission !== "admin"}
            >
              update
            </Button>
          </Box>
        }
      >
        {billing.card === null ? (
          <Box sx={{ mt: "1rem", textAlign: "center" }}>
            <Typography>Card Not Added</Typography>
          </Box>
        ) : (
          <Table sx={{ mb: "-1.6rem" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: "0.6rem" }} align="left">
                  Brand
                </TableCell>
                <TableCell sx={{ py: "0.6rem" }} align="center">
                  Last 4 Digits
                </TableCell>
                <TableCell sx={{ py: "0.6rem" }} align="right">
                  Expiry
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: "none" }} align="left">
                  {billing.card.brand}
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} align="center">
                  {billing.card.last4Digits}
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} align="right">
                  {billing.card.expiryMonth}/{billing.card.expiryYear}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </SectionCard>
      <GeneratePaymentMethodIdModal open={openAddCardDetails} onClose={handleCloseModal} onComplete={handleComplete} />
    </>
  );
};
