import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const startNewConversation = ({ recipientId }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations`, {
        recipientId,
      });
      const { data } = handleAxiosResponse(response);

      dispatch(actions.addConversation(data));

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
