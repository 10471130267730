import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { icons } from "../../../icons";

export const RecruiterSelect = ({ value, onSelected }) => {
  const anchorRef = useRef(null);

  const companyMembers = useSelector((state) => state.companyMembers);
  const recruiterProfile = useSelector((state) => state.mainProfile);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Box>
      <Button
        sx={{
          ml: "0.8rem",
          padding: "0.2rem 0.2rem 0.2rem 0.6rem",
          width: "6rem",
          borderRadius: "0.6rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => setMenuOpen(true)}
        ref={anchorRef}
      >
        {getButtonText(value, recruiterProfile.email)}
        {icons.arrowUp({ sx: { transform: "rotate(180deg)" } })}
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={anchorRef.current}
        onClose={() => setMenuOpen(false)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{ sx: { padding: "0rem" } }}
        PaperProps={{
          elevation: 6,
          style: {
            borderRadius: "0.4rem",
            maxHeight: "16rem",
            width: anchorRef?.current?.offsetWidth,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            onSelected(null);
            setMenuOpen(false);
          }}
        >
          All Jobs
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelected({
              profileId: recruiterProfile.profileId,
              name: recruiterProfile.name,
              email: recruiterProfile.email,
            });
            setMenuOpen(false);
          }}
        >
          My Jobs
        </MenuItem>
        {companyMembers.map((member) => {
          if (member.email === recruiterProfile.email) {
            return null;
          }
          return (
            <MenuItem
              key={member.email}
              onClick={() => {
                onSelected(member);
                setMenuOpen(false);
              }}
            >
              <Typography sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {member.name.split(" ")[0]}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

const getButtonText = (value, recruiterProfile) => {
  if (value) {
    if (value.email === recruiterProfile.email) {
      return "My Jobs";
    } else {
      return value.name.split(" ")[0];
    }
  } else {
    return "All Jobs";
  }
};
