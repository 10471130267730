import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { PageLayout } from "../../components/page-layout";
import { PageTopLoader } from "../../components/loaders/page-top-loader";
import { TicketSelect } from "./components/ticket-select";
import { PrioritySelect } from "./components/priority-select";
import { CategorySelect } from "./components/category-select";
import { DateSelect } from "./components/date-select";
import { TicketsContainer } from "./components/tickets-container";
import { CreateTicketModal } from "./components/create-ticket-modal";
import { SupportFeature } from "../../features/support";
import { useDispatch } from "react-redux";

const NUMBER_OF_TICKETS_PER_PAGE = 6;

const SupportPage = () => {
  const dispatch = useDispatch();
  const memoizedDispatch = useCallback(
    (...args) => {
      dispatch(...args);
    },
    [dispatch]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateTicketModalOpen, setIsCreateTicketModalOpen] =
    React.useState(false);
  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState({
    open: false,
    token: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [tickets, setTickets] = React.useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectState, setSelectState] = useState({
    ticket: "all",
    category: "all",
    priority: "all",
    createdAt: "newest",
    initialized: true,
  });
  const [ticketsExist, setTicketsExist] = useState(false);

  const handlePageChange = async (event, value) => {
    setCurrentPage(value);
  };

  const fetch = useCallback(async () => {
    setIsLoading(true);
    const filters = {
      status: selectState.ticket === "all" ? null : selectState.ticket,
      category: selectState.category === "all" ? null : selectState.category,
      priority: selectState.priority === "all" ? null : selectState.priority,
      createdAt: selectState.createdAt,
    };
    const data = await SupportFeature.fetchSupportTitles({
      page: currentPage,
      filters,
      pageSize: NUMBER_OF_TICKETS_PER_PAGE,
    })(memoizedDispatch);

    const { supports, count, exist } = data;
    const totalNoOfPages = Math.ceil(count / NUMBER_OF_TICKETS_PER_PAGE);
    setTickets(supports);
    setTotalPages(totalNoOfPages);
    setTicketsExist(exist);
    setIsLoading(false);
  }, [
    currentPage,
    selectState.ticket,
    selectState.category,
    selectState.createdAt,
    selectState.priority,
    memoizedDispatch,
  ]);

  useEffect(() => {
    fetch();
  }, [
    selectState.ticket,
    selectState.category,
    selectState.createdAt,
    selectState.priority,
    currentPage,
    memoizedDispatch,
    fetch,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    selectState.ticket,
    selectState.category,
    selectState.createdAt,
    selectState.priority,
  ]);

  useEffect(() => {
    const filterTickets = () => {
      setFilteredTickets(tickets);
    };
    filterTickets();
  }, [
    tickets,
    selectState.ticket,
    selectState.category,
    selectState.createdAt,
    selectState.priority,
  ]);

  return (
    <PageLayout pageName="Support" showPostJobButton showAppBar>
      {isLoading && <PageTopLoader />}
      <Box
        sx={{
          flex: 1,
          mx: { xs: "1rem", md: "2rem" },
          mb: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: {
                xs: "0.5rem",
                md: "1.5rem",
              },
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                alignSelf: {
                  xs: "flex-start",
                  md: "center",
                },

                mr: {
                  xs: "0",
                  md: "1rem",
                },
                mt: "1rem",
              }}
            >
              Filter Issues
            </Typography>
            <TicketSelect
              selectState={selectState}
              setSelectState={setSelectState}
            />
            <PrioritySelect
              selectState={selectState}
              setSelectState={setSelectState}
            />
            <CategorySelect
              selectState={selectState}
              setSelectState={setSelectState}
            />
            <DateSelect
              selectState={selectState}
              setSelectState={setSelectState}
            />
          </Box>
          <Button
            variant="contained"
            sx={{ mt: "1rem", borderRadius: "2rem", maxHeight: "2.4rem" }}
            onClick={() => setIsCreateTicketModalOpen(true)}
          >
            Create Ticket
          </Button>
        </Box>
        <Divider sx={{ width: "100%", my: "1rem" }} />
        {!isLoading && tickets.length === 0 && !ticketsExist ? (
          <Box
            sx={{
              flex: 1,
              width: "100%",
              borderRadius: "1rem",
              backgroundColor: "#FFFAFA",
              marginTop: "2rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Are you facing any issues?
            </Typography>
            <Typography variant="subtitle1" sx={{ my: "1rem" }}>
              Please create a ticket to get help from our support team. We will
              get back to you as soon as possible.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: "1rem", borderRadius: "2rem" }}
              onClick={() => setIsCreateTicketModalOpen(true)}
            >
              Create Ticket
            </Button>
            <Box sx={{ mb: "6rem" }}></Box>
          </Box>
        ) : (
          <Fragment>
            <TicketsContainer
              tickets={filteredTickets}
              isLoading={isLoading}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Fragment>
        )}
        <CreateTicketModal
          open={isCreateTicketModalOpen}
          onClose={async ({ token, response }) => {
            if (token && response) {
              setIsCreateTicketModalOpen(false);
              setIsSubmittedModalOpen(
                (prevState) => (prevState = { open: true, token })
              );

              if (
                tickets.length === NUMBER_OF_TICKETS_PER_PAGE &&
                currentPage === totalPages
              ) {
                setCurrentPage(totalPages + 1);
              } else {
                // if (currentPage === totalPages) {
                setTickets([...tickets, response]);
                // }
              }
            } else {
              setIsCreateTicketModalOpen(false);
            }
          }}
        />
        <SubmittedModal
          open={isSubmittedModalOpen.open}
          setOpen={setIsSubmittedModalOpen}
          token={isSubmittedModalOpen.token}
        />
      </Box>
    </PageLayout>
  );
};

export default SupportPage;

const SubmittedModal = ({ open, setOpen, token }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen((prev) => (prev = { open: false, token: "" }))}
    >
      <DialogTitle>Thank you for contacting us!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your ticket has been submitted. Here is your support token:{" "}
          <strong>{token}</strong>
        </DialogContentText>
        <DialogContentText>
          We typically respond within 24 hours.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen((prev) => (prev = { open: false, token: "" }))}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
