import { Box, IconButton, InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { MainProfileFeature } from "../../../features/main-profile";
import { LoadingButton } from "../../../components/buttons/loading-button";
import { Modal } from "../../../components/backdrop-modal";
import { TextField } from "../../../components/textfield";
import { PhoneNumberField } from "../../../components/inputs/phone";
import { CustomTextField } from "../../../components/inputs";
import { icons } from "../../../icons";

export const EditRecruiterProfile = ({ recruiterProfile, open, onClose }) => {
  const dispatch = useDispatch();

  const { name, phone, linkedIn } = recruiterProfile;

  const handleSubmit = async (profileDetails, helpers) => {
    await MainProfileFeature.updateProfile({ profileDetails })(dispatch);
    helpers.resetForm();
    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { name, phone, linkedIn: linkedIn ?? "" },
    validationSchema: yup.object({
      name: yup.string().min(6).required("Required"),
      phone: yup.string().required("Required"),
      linkedIn: yup.string(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      headerTitle="Edit Recruiter Profile"
      open={open}
      onClose={() => {
        onClose();
        formik.resetForm();
      }}
      headerActions={
        <Box>
          <IconButton
            onClick={() => {
              onClose();
              formik.resetForm();
            }}
            sx={{ padding: "0.6rem" }}
          >
            {icons.close({})}
          </IconButton>
        </Box>
      }
      modalActions={
        <Box>
          <LoadingButton variant="outlined" onClick={formik.submitForm} isLoading={formik.isSubmitting} fullWidth>
            {formik.isSubmitting ? "Saving" : "Save"}
          </LoadingButton>
        </Box>
      }
    >
      <Box component="form" noValidate>
        <TextField
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <PhoneNumberField
          label="Phone"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        <CustomTextField
          label="LinkedIn Profile"
          name="linkedIn"
          value={formik.values.linkedIn}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.linkedIn && Boolean(formik.errors.linkedIn)}
          helperText={formik.touched.linkedIn && formik.errors.linkedIn}
          placeholder="Please enter your linkedIn profile id"
          startAdornment={
            <InputAdornment position="start" sx={{ mr: "0rem" }}>
              https://www.linkedin.com/in/
            </InputAdornment>
          }
        />
      </Box>
    </Modal>
  );
};
