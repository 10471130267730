import React from "react";
import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import { useScrollToTop } from "../../hooks/use-scroll-to-top";
import { icons } from "../../icons";

const ScrollBackToTop = () => {
  const [show, scrollToTop] = useScrollToTop();

  return (
    <Fade in={show}>
      <Fab
        onClick={scrollToTop}
        size="medium"
        sx={{
          position: "fixed",
          bottom: "0px",
          right: "0px",
          margin: { xs: "1rem", sm: "1.4rem 2rem" },
        }}
      >
        {icons.arrowUp({ fontSize: "large" })}
      </Fab>
    </Fade>
  );
};

const MemoizedScrollBackToTop = React.memo(ScrollBackToTop);

export { MemoizedScrollBackToTop as ScrollBackToTop };
