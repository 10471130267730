import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const getAccountSummary = ({ email }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/account/${email}/summary`);
      const { data } = handleAxiosResponse(response);

      return {
        recruiterProfile: data["recruiterProfile"],
        teamMembers: data["teamMembers"],
        companyProfile: data["companyProfile"],
        subscription: data["subscription"],
        card: data["card"],
      };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
      return null;
    }
  };
};
