import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Logo } from "../logo.js";
import { QuickLinks } from "./quick-links.js";
import { Socials } from "./socials.js";

export const AppFooter = () => {
  return (
    <Box sx={{ bgcolor: "#212121" }}>
      <Box component="footer" sx={{ padding: "1.8rem", color: "whitesmoke" }}>
        <QuickLinks />
        <Divider sx={{ my: "1rem", bgcolor: "gray" }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ mt: { xs: "1rem", sm: "0.6rem" }, display: "flex", alignItems: "center" }}>
            <Logo />
            <Typography variant="body2" ml="1rem">
              © The Matrix Group 2023
            </Typography>
          </Box>
          <Box height="1rem" />
          <Socials />
        </Box>
      </Box>
    </Box>
  );
};
