import { Box, Divider, IconButton, Typography, Badge } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageTopLoader } from "../../components/loaders/page-top-loader";
import { PageLayout } from "../../components/page-layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRouteConstants } from "../../navigations/routes";
import { useFilterOptions } from "../../hoc/use-filter-options";
import { JobseekersContainer } from "../../components/jobseekers-container";
import { FiltersSideBar } from "../../components/filters-sidebar";
import { JobseekersFeature } from "../../features/jobseekers";
import { icons } from "../../icons";
import { SearchBar } from "../../components/searchbar";
import { useCallback } from "react";

export const JobseekersPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [search, setSearch] = useState(searchParams.get("search") ?? "");

  const [filterOptions, setFilterOptions] = useFilterOptions();
  const [isLoading, setIsLoading] = useState(false);

  const { jobseekers } = useSelector((state) => state);
  const { companyProfile } = useSelector((state) => state);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    setCurrentPage(Number(searchParams.get("page") ?? 1));
    setSearch(searchParams.get("search") ?? "");
  }, [searchParams]);

  const fetch = useCallback(async () => {
    setIsLoading(true);
    const totalNoOfPages = await JobseekersFeature.fetchJobseekers({
      filterOptions,
      page: currentPage,
      search,
    })(dispatch);
    setTotalPages(totalNoOfPages);
    setIsLoading(false);
  }, [currentPage, dispatch, filterOptions, search]);

  useEffect(() => {
    fetch();
  }, [currentPage, fetch, search]);

  const handlePageChange = (_, value) => {
    const page = searchParams.get("page") ?? 1;
    if (page !== value) {
      navigate(`${AppRouteConstants.jobseekers}?page=${value}`);
    }
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const numberOfFiltersApplied = Object.values(filterOptions).filter(
    (value) => value && value !== "" && value.length !== 0
  ).length;
  return (
    <PageLayout pageName="Jobseekers Directory" showPostJobButton showAppBar>
      {isLoading && <PageTopLoader />}
      {companyProfile && companyProfile?.currentPlan === "enterprise" ? (
        <Box
          sx={{
            flex: 1,
            mx: { xs: "1rem", md: "2rem" },
            mb: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space between",
            }}
          >
            <SearchBar
              sx={{
                flex: "1",
              }}
              navigateUrl={`${AppRouteConstants.jobseekers}?page=${currentPage}`}
              navigateSearchUrl={(search) =>
                `${AppRouteConstants.jobseekers}?page=1&search=${search}`
              }
            />
            <Badge
              badgeContent={numberOfFiltersApplied}
              color="primary"
              sx={{
                position: "relative",
                top: "1.1rem",
              }}
            >
              <IconButton
                sx={{
                  ml: "1rem",
                  height: "3rem",
                  width: "3rem",
                  backgroundColor: "primary.main",
                  ":hover": { backgroundColor: "#d22824" },
                }}
                color="primary.main"
                onClick={handleDrawerToggle}
              >
                {icons.tune({ sx: { fontSize: "1.4rem", color: "white" } })}
              </IconButton>
            </Badge>
            <FiltersSideBar
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              isOpen={isDrawerOpen}
              onClose={handleDrawerToggle}
              jobseeker={true}
            />
          </Box>
          <Divider sx={{ width: "100%", my: "1rem" }} />
          <Fragment>
            {!jobseekers || jobseekers?.length < 1 ? ( // || !initialized
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  Get started by clicking on the filter{" "}
                  {icons.tune({ sx: { fontSize: "1.4rem", color: "gray" } })}{" "}
                  for the best personalized results.
                </Typography>
              </Box>
            ) : (
              <Box sx={{ width: "100%", height: "100%" }}>
                <JobseekersContainer
                  jobseekers={jobseekers.jobseekers}
                  isLoading={isLoading}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </Box>
            )}
          </Fragment>
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icons.lock({
            sx: {
              fontSize: "10rem",
              color: "primary.main",
              mb: "2rem",
            },
          })}
          <Typography
            sx={{
              mt: "2rem",
              px: {
                xs: "1rem",
                md: "10rem",
              },
            }}
          >
            Unlock the full potential of our Jobseekers Directory with our
            Enterprise plan! Upgrade now to get access to our latest feature and
            find the perfect candidate for your job opening. Don't wait -
            upgrade to Enterprise today!
          </Typography>
        </Box>
      )}
    </PageLayout>
  );
};
