import { Autocomplete, Box, InputBase, Typography } from "@mui/material";
import { useCallback, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppConfigsFeature } from "../../features/app-configs";

export const AirportSelect = ({ label, name, value, onChange, error, helperText, multiple = false }) => {
  const dispatch = useDispatch();
  const [airportOptions, setAirportOptions] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);

  const updateAirportOptions = useCallback(
    async (search) => {
      setFetching(true);
      const options = await AppConfigsFeature.fetchAirports({ search })(dispatch);
      setAirportOptions(options);
      setFetching(false);
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    let active = true;

    if (!initialized) {
      updateAirportOptions(value ? value.city : "").then(() => {
        if (active) {
          setInitialized(true);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [dispatch, initialized, updateAirportOptions, value]);

  const handleChange = async (_, value) => {
    onChange({ target: { name, value } });
  };

  return (
    <Box sx={{ my: "0.8rem" }}>
      {label && <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>}
      <Autocomplete
        sx={{ borderRadius: "4px", backgroundColor: "#F4F4FB", paddingRight: "1rem" }}
        onChange={handleChange}
        multiple={multiple}
        defaultValue={multiple ? [] : null}
        value={value}
        options={airportOptions.sort((a, b) => -b.country[0].localeCompare(a.country[0]))}
        loading={fetching}
        groupBy={(option) => option.country}
        isOptionEqualToValue={(option, value) => {
          return `${option.name} - ${option.icao}` === `${value.name} - ${value.icao}`;
        }}
        getOptionLabel={(option) => {
          return `${option.name} - ${option.icao}`;
        }}
        renderOption={(props, option) => {
          return (
            <Box value={option} {...props}>
              {option.name} - {option.icao}
            </Box>
          );
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              {...InputProps}
              {...rest}
              onChange={({ target }) => updateAirportOptions(target.value)}
              placeholder="Please enter the airport name"
              sx={{ padding: "0.44rem 0.88rem" }}
            />
          );
        }}
      />
      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
