import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";

import { handleError } from "../../app-messages";
import { actions as mainProfileActions } from "../../main-profile/slice";
import { actions as companyProfileActions } from "../../company-profile/slice";
import { actions } from "../slice";
import { actions as companyMembersActions } from "../../company-members/slice";
import { actions as subscriptionActions } from "../../subscription/slice";
import { MainProfileFeature } from "../../main-profile";
import { signOut } from "./sign-out";

export const initializeAccount = () => {
  return async (dispatch) => {
    try {
      const accessToken = appLocalStorage.get(lskeys.accessToken);
      let recruiterProfile = appLocalStorage.get(lskeys.recruiterProfile);
      let companyProfile = appLocalStorage.get(lskeys.companyProfile);

      if (!accessToken || !recruiterProfile) {
        return await signOut()(dispatch);
      }

      const summary = await MainProfileFeature.getAccountSummary({
        email: recruiterProfile.email,
      })(dispatch);

      if (!summary) {
        return await signOut()(dispatch);
      }

      recruiterProfile = { ...recruiterProfile, ...summary.recruiterProfile };

      if (summary.recruiterProfile.company) {
        companyProfile = { ...companyProfile, ...summary.companyProfile };
        dispatch(companyProfileActions.setProfile({ profile: companyProfile }));
        dispatch(companyMembersActions.setMembers({ members: summary.teamMembers }));
        dispatch(subscriptionActions.setSubscription({ subscription: summary.subscription }));
        dispatch(subscriptionActions.setInitialized());

        appLocalStorage.save({ [lskeys.companyProfile]: companyProfile });
      }

      appLocalStorage.save({ [lskeys.recruiterProfile]: recruiterProfile });

      dispatch(mainProfileActions.setProfile({ profile: recruiterProfile }));
      dispatch(actions.login());
      dispatch(actions.initialize());

      return { verified: recruiterProfile.verified };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};

export const reInitialize = (dispatch) => {
  dispatch(actions.initialize({ initialize: false }));
};
