import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError, showSuccess } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";

export const removeProfileImage = () => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.delete(`/recruiters/${mainProfile.email}/image`);

      const { data, message } = handleAxiosResponse(response);
      if (!data) {
        return handleError("No image found")(dispatch);
      }
      const recruiterProfile = { ...mainProfile, image: null };

      appLocalStorage.save({ [lskeys.recruiterProfile]: recruiterProfile });

      dispatch(actions.setProfileImage({ image: null }));
      dispatch(showSuccess(message));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
