import {
  Box,
  CircularProgress,
  Pagination,
  styled,
  Typography,
} from "@mui/material";
import { CandidateCard } from "./cards/candidate";
import { ElevatedCard } from "./elevated-card";
import { svgIcons } from "../icons/svgs";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { UpdateStatusMenu } from "../pages/candidates/components/candidate-status-select";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const CandidatesContainer = ({
  displayedCandidates,
  isLoading,
  currentPage,
  totalPages,
  handlePageChange,
  selectState,
  fetchCandidates,
}) => {
  const [searchParams] = useSearchParams();
  const [candidateIds, setCandidateIds] = useState([]);
  const [processingCandidates, setProcessingCandidates] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    setCandidateIds([]);
    setCheckAll(false);
  }, [displayedCandidates]);

  useEffect(() => {
    if (
      candidateIds?.length > 0 &&
      displayedCandidates?.length > 0 &&
      candidateIds?.length === displayedCandidates?.length
    )
      setCheckAll(true);
  }, [candidateIds, displayedCandidates]);

  return (
    <ElevatedCard
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        paddingTop: "0.6rem",
        width: "100%",
        borderRadius: "1rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <Box sx={{ minHeight: "80vh" }}>
        {isLoading || processingCandidates ? (
          <CircularProgress
            sx={{
              height: "100%",
              width: "100%",
              ml: "45%",
              mt: "30%",
              alignSelf: "center",
            }}
          />
        ) : (
          <>
            {displayedCandidates.length !== 0 ? (
              <TableContainer>
                <Table padding="none" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FlexBox>
                          <Checkbox
                            checked={checkAll}
                            onChange={(event) => {
                              setCheckAll(event.target.checked);
                              if (event.target.checked) {
                                setCandidateIds(
                                  displayedCandidates.map(
                                    (candidate) => candidate.id
                                  )
                                );
                              } else {
                                setCandidateIds([]);
                              }
                            }}
                          />
                        </FlexBox>
                      </TableCell>
                      <TableCell padding="normal" align="right">
                        <FlexBox
                          sx={{
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "1.2rem", mr: "0.5rem" }}>
                            Select All
                          </Typography>
                          <UpdateStatusMenu
                            sx={{
                              maxWidth: "30rem",
                            }}
                            candidateIds={candidateIds}
                            status={searchParams.get("tab")}
                            fetchCandidates={fetchCandidates}
                            multiCandidate={true}
                            setProcessingCandidates={setProcessingCandidates}
                          />
                        </FlexBox>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    sx={{
                      pt: "1rem",
                    }}
                  >
                    {displayedCandidates.map((candidate) => (
                      <TableRow
                        key={candidate.id}
                        sx={{
                          border: 0,
                          backgroundColor:
                            candidateIds.indexOf(candidate.id) !== -1
                              ? "#F4F4FB"
                              : "inherit",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            borderBottom: "none",
                            padding: 0,
                          }}
                        >
                          <FlexBox>
                            <Checkbox
                              checked={
                                candidateIds.indexOf(candidate.id) !== -1
                              }
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setCandidateIds((cIds) => [
                                    ...cIds,
                                    candidate.id,
                                  ]);
                                } else {
                                  console.info("un checking ");
                                  setCheckAll(false);
                                  setCandidateIds(
                                    candidateIds.filter(
                                      (id) => id !== candidate.id
                                    )
                                  );
                                }
                              }}
                            />
                          </FlexBox>
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="normal"
                          sx={{ borderBottom: "none" }}
                        >
                          <CandidateCard
                            selectState={selectState}
                            candidate={candidate}
                            key={candidate.id}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <FlexBox
                sx={{
                  flexDirection: "column",
                  minHeight: "80vh",
                }}
              >
                <Box
                  component="img"
                  src={svgIcons.notFound}
                  sx={{ height: "4rem", width: "4rem" }}
                />
                <Typography
                  variant="h6"
                  sx={{ mt: "0.6rem", fontWeight: "bold", color: "#C8C8C8" }}
                >
                  No Candidates Found
                </Typography>
              </FlexBox>
            )}
          </>
        )}
      </Box>
      <Pagination
        sx={{ mt: { xs: "1rem", sm: "2rem" } }}
        page={currentPage}
        count={totalPages}
        onChange={handlePageChange}
      />
    </ElevatedCard>
  );
};

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
