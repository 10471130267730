import { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { ElevatedCard } from "../elevated-card";
import { MobileViewPlanSelect } from "./mobile-view-plan-select";
import { BillingSchemeToggle } from "./billing-scheme-toggle";
import { PlanFeatureLimitsColumn } from "./plan-feature-limits-column";
import { PlanFeaturesColumn } from "./plan-features-column";

export const PlansAndPricingTable = ({ plansAndPricings, billingCycle, onBillingSchemeChange, actions = null }) => {
  const { companyProfile } = useSelector((state) => state);

  const [planInMobileView, setPlanInMobileView] = useState("lite");

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <BillingSchemeToggle value={billingCycle} onChange={onBillingSchemeChange} />
      </Box>
      <MobileViewPlanSelect value={planInMobileView} onChange={setPlanInMobileView} />
      <ElevatedCard sx={{ flex: 1, display: "flex", padding: 0, border: "none" }}>
        <Box sx={{ flex: 1, display: "flex" }}>
          <PlanFeaturesColumn featuresList={plansAndPricings[0].features} />
          <Box sx={{ flex: { xs: 1.4, sm: 3 }, display: "flex" }}>
            {plansAndPricings.map((planPricing) => {
              return (
                <PlanFeatureLimitsColumn
                  key={planPricing.plan}
                  planDetails={planPricing}
                  billingCycle={billingCycle}
                  planInMobileView={planInMobileView !== planPricing.plan}
                  active={companyProfile.currentPlan === planPricing.plan}
                >
                  {actions?.[planPricing.plan]}
                </PlanFeatureLimitsColumn>
              );
            })}
          </Box>
        </Box>
      </ElevatedCard>
    </Box>
  );
};
