import { createSlice } from "@reduxjs/toolkit";

const initialState = { draftId: null, formState: {}, limits: {} };

export const slice = createSlice({
  name: "postJobForm",

  initialState: { ...initialState },

  reducers: {
    setDraftId: (state, { payload }) => {
      state.draftId = payload.draftId;
    },

    setFormState: (state, { payload }) => {
      state.formState = payload.formState;
    },

    setCurrentLimits: (state, { payload }) => {
      state.limits = payload.limits;
    },

    clearDraftAndForm: (state) => {
      state.draftId = null;
      state.formState = {};
    },

    clearLimits: (state) => {
      state.limits = {};
    },
  },
});

export const { reducer, actions } = slice;
