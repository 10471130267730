import axios from "axios";
import { appLocalStorage, lskeys } from "./local-storage";
import { checkIfTokenExpired, containsInExceptionList } from "./utils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // "http://172.16.3.127:8081",
});

const axiosInterceptors = (reInitializeCB = () => {}) => {
  axiosInstance.interceptors.request.use(async (config) => {
    if (!containsInExceptionList(config.url)) {
      const accessToken = appLocalStorage.get(lskeys.accessToken);

      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      const urls = ["/authentication/sign-in", "/authentication/sign-up", "/authentication/tokens"];

      if (urls.includes(response.config.url)) {
        handleHeaderTokens(response.headers, reInitializeCB);
      }

      return response;
    },

    async (error) => {
      if (error?.response?.status === 401) {
        const accessToken = appLocalStorage.get(lskeys.accessToken);
        if (accessToken && checkIfTokenExpired(accessToken)) {
          reInitializeCB();
        }
      }

      return Promise.reject(error);
    }
  );
};

const handleHeaderTokens = (headers, reInitializeCB) => {
  const access = headers["x-access-token"];
  const resfresh = headers["x-refresh-token"];

  if (access !== null && access !== undefined) {
    appLocalStorage.save({ [lskeys.accessToken]: access });
  } else {
    reInitializeCB();
  }

  if (resfresh !== null && resfresh !== undefined) {
    appLocalStorage.save({ [lskeys.refreshToken]: resfresh });
  }
};

const handleAxiosResponse = (response) => {
  const { status, message, data } = response.data;

  if (status === "error") throw Error(message);

  return { message, data };
};

export { axiosInstance, handleAxiosResponse, axiosInterceptors };
