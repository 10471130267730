import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export const Link = ({ href, title, sx, openInNewPage = false }) => {
  return (
    <MuiLink
      href={href}
      {...(openInNewPage && { target: "_blank" })}
      sx={{ ":hover": { textDecorationColor: "white" } }}
    >
      <Typography variant="body1" sx={{ cursor: "pointer", fontWeight: "light", ...sx }}>
        {title}
      </Typography>
    </MuiLink>
  );
};
