import { Box, Button, IconButton, Typography } from "@mui/material";
import { Modal } from "../../../components/backdrop-modal";
import { LoadingButton } from "../../../components/buttons/loading-button";
import { useEffect, useState } from "react";
import { JobsFeature } from "../../../features/jobs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRouteConstants } from "../../../navigations/routes";
import { GeneratePaymentMethodIdModal } from "../../../components/payments/generate-payment-method-id";
import { icons } from "../../../icons";

export const PostJobModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formState = useSelector((state) => state.postJobForm.formState);
  const limits = useSelector((state) => state.postJobForm.limits);
  const [upgradeOption, setUpgradeOption] = useState({
    shouldShow: false,
    message: "",
  });
  const [openAddCardDetails, setViewSelectPaymentCard] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setUpgradeOption(
      calculatePaymentAmountAndUpgradeOptionMessage({
        contractType: formState.contractType,
        sponsor: formState.sponsor,
        limits,
      })
    );
  }, [formState.contractType, formState.sponsor, limits]);

  const handleJobPosting = async (paymentMethodId = null) => {
    setSubmitting(true);
    await JobsFeature.postNewJob({ formState, paymentMethodId })(dispatch);
    setSubmitting(false);
    onClose();
    navigate(-1);
  };

  const handleCloseModal = () => {
    setViewSelectPaymentCard(false);
  };

  return (
    <>
      <Modal
        headerTitle={upgradeOption ? "Ready to Post the job?" : "Post The Job"}
        open={open}
        onClose={onClose}
        headerActions={
          <IconButton onClick={onClose} sx={{ padding: "0.6rem" }}>
            {icons.close({})}
          </IconButton>
        }
        modalActions={
          !upgradeOption.shouldShow ? (
            <LoadingButton variant="outlined" onClick={() => handleJobPosting({})} isLoading={submitting} fullWidth>
              {submitting ? "Posting" : "Continue Posting"}
            </LoadingButton>
          ) : (
            <Box sx={{ display: "flex" }}>
              <Button
                variant="outlined"
                onClick={() => navigate(AppRouteConstants.mySubscription + "&return_to=/jobs/post")}
                disabled={submitting}
                fullWidth
              >
                Upgrade My Plan
              </Button>
              <Box width="2rem" />
              <LoadingButton onClick={() => setViewSelectPaymentCard((prev) => !prev)} isLoading={submitting} fullWidth>
                {submitting ? "Posting" : "Pay And Post Now"}
              </LoadingButton>
            </Box>
          )
        }
      >
        <Typography sx={{ mt: "1rem" }}>
          {!upgradeOption.shouldShow ? (
            `You are now posting your ${formState.contractType} job to the platform.`
          ) : (
            <Box component="span" dangerouslySetInnerHTML={{ __html: upgradeOption.message }} />
          )}
        </Typography>
      </Modal>
      <GeneratePaymentMethodIdModal
        modalTitle="Please Payment Card Details"
        open={openAddCardDetails}
        onClose={handleCloseModal}
        onComplete={handleJobPosting}
      />
    </>
  );
};

const calculatePaymentAmountAndUpgradeOptionMessage = ({ contractType, sponsor, limits }) => {
  const shouldShow =
    (contractType === "full time" && limits.reachedFullTimeLimit) ||
    (contractType === "freelance" && limits.reachedFreelanceLimit) ||
    (sponsor && limits.reachedSponsorCreditLimit);

  let message = "";

  if (
    (contractType === "full time" && limits.reachedFullTimeLimit) ||
    (contractType === "freelance" && limits.reachedFreelanceLimit)
  ) {
    message = `You have reached your monthly limit for ${contractType} job posting as per your current plan. 
    In order to post this job you can either upgrade your account or pay a one off fee of &pound;99.00. `;
  }

  if (sponsor && limits.reachedSponsorCreditLimit) {
    message = message + "You do not have credit to sponsor this job, you can pay &pound;89.00 to sponsor this job.";
  }

  return { shouldShow, message };
};
