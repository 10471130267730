import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSocket } from "./use-socket";

export const useUserActivityHandler = () => {
  const dispatch = useDispatch();
  const socket = useSocket();
  const mainProfile = useSelector((state) => state.mainProfile);

  useEffect(() => {
    if (socket) {
      if (mainProfile?.profileId) {
        socket.emit("user-connected", {
          userType: "recruiters",
          profileId: mainProfile?.profileId,
          status: "online",
        });
      }
    }
  }, [socket, mainProfile.profileId, dispatch]);
};
