import { Box, Switch, Typography } from "@mui/material";

export const CustomSwitch = ({ label, checked, onChange }) => {
  return (
    <Box sx={{ width: "100%", my: "1.6rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography sx={{ fontWeight: "bold" }}>{label}</Typography>
      <Switch checked={checked} onChange={({ target }) => onChange(target.checked)} />
    </Box>
  );
};
