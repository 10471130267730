import Button from "@mui/material/Button";

export const PlanActionButton = ({ children, onClick, disabled }) => {
  return (
    <Button
      sx={{
        borderRadius: "1rem",
        boxShadow: "0px 0px 8px #EFEFEF",
        transition: "ease-in-out 300ms",
        marginX: "1.6rem",
        mt: "1.6rem",
        mb: "0.6rem",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
