import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const fetchCandidateDetails = ({ candidateId }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/candidates/${candidateId}`);

      const { data } = handleAxiosResponse(response);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
