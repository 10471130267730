import { DropDown } from "./index";

export const RoleDropDown = (props) => {
  return (
    <DropDown
      label="Role"
      name="role"
      options={[...designations["flight crew"], ...designations["cabin crew"]]}
      // options={designations[props.crewType || "flight crew"]}
      {...props}
      placeholder="Please select the role"
    />
  );
};

export const designations = Object.freeze({
  "flight crew": [
    "Captain",
    "Captain / Instructor",
    "Captain / Instructor / Examiner",
    "Co - Pilot",
    "First Officer",
    "Senior First Officer",
    "Test Pilot",
  ],

  "cabin crew": [
    "purser",
    "CSM",
    "cabin senior",
    "first class",
    "business class",
    "flight attendant",
    "VIP lead FA",
    "VIP FA",
    "VVIP FA",
    "VIP PA",
    "VVIP / PA",
    "sky nanny",
  ],
});
