import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";

export const uploadProfileImage = ({ image }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      let formData = new FormData();

      formData.append("image", image);

      const response = await axiosInstance.patch(`/companies/${mainProfile.email}/image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const { data: updatedCompanyProfile } = handleAxiosResponse(response);

      const savedProfileDetails = appLocalStorage.get(lskeys.companyProfile);

      const companyProfile = {
        ...savedProfileDetails,
        image: updatedCompanyProfile.image,
      };

      appLocalStorage.save({ [lskeys.companyProfile]: companyProfile });

      dispatch(actions.setProfileImage({ image: companyProfile.image }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
