import { Box, ButtonBase, Menu, MenuItem, Typography } from "@mui/material";
import { useRef, useState } from "react";

import { icons } from "../../../icons";

export const PrioritySelect = ({ selectState, setSelectState }) => {
  const anchorRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Box>
      <Typography>Priority </Typography>
      <ButtonBase
        onClick={() => setMenuOpen(true)}
        sx={{
          padding: "0.6rem 1rem",
          minHeight: "2.6rem",
          borderRadius: "0.4rem",
          display: "flex",
          minWidth: "10rem",
          justifyContent: "space-between",
          background: "#dadada",
          width: {
            xs: "100%",
            md: "auto",
          },
        }}
        ref={anchorRef}
      >
        <Typography
          sx={{
            textAlign: "left",
            textTransform: "capitalize",
          }}
        >
          {selectState.priority}
        </Typography>
        <ExpandIcon menuOpen={menuOpen} />
      </ButtonBase>
      <TicketSelectMenuList
        {...{
          anchorRef,
          menuOpen,
          setMenuOpen,
          setSelectState,
        }}
      />
    </Box>
  );
};

const TicketSelectMenuList = ({
  anchorRef,
  menuOpen,
  setMenuOpen,
  setSelectState,
}) => {
  return (
    <Menu
      open={menuOpen}
      anchorEl={anchorRef.current}
      onClose={() => setMenuOpen(false)}
      sx={{ zIndex: 3000 }}
      MenuListProps={{ sx: { padding: "0rem" } }}
      PaperProps={{
        elevation: 6,
        style: {
          borderRadius: "0.4rem",
          maxHeight: "16rem",
          width: anchorRef && anchorRef.current?.offsetWidth,
        },
      }}
    >
      {priorities.map((priority, i) => (
        <MenuItem
          key={i}
          onClick={() => {
            setSelectState((prev) => ({
              ...prev,
              priority,
            }));

            setMenuOpen(false);
          }}
          sx={{
            whiteSpace: "break-spaces",
            wordBreak: "break-word",
            textTransform: "capitalize",
          }}
        >
          {priority}
        </MenuItem>
      ))}
    </Menu>
  );
};

const ExpandIcon = ({ menuOpen }) => {
  return (
    <Box sx={{ ml: "0.6rem", display: "flex" }}>
      {menuOpen ? icons.expandLess({}) : icons.expandMore({})}
    </Box>
  );
};

export const priorities = ["all", "low", "medium", "high"];
