import React from "react";
import Hidden from "@mui/material/Hidden";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const MobileViewPlanSelect = ({ value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <Hidden smUp>
      <Select
        value={value}
        onChange={handleChange}
        MenuProps={{ PaperProps: { sx: { borderRadius: "1rem" } } }}
        sx={{
          mb: "1.6rem",
          paddingY: "0rem",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          borderRadius: "1rem",
          boxShadow: "0px 0px 8px #EFEFEF",
          transition: "ease-in-out 300ms",
        }}
      >
        <MenuItem value="lite">Lite</MenuItem>
        <MenuItem value="pro">Pro</MenuItem>
        <MenuItem value="enterprise">Enterprise</MenuItem>
      </Select>
    </Hidden>
  );
};

const MemoizedMobileViewPlanSelect = React.memo(MobileViewPlanSelect);

export { MemoizedMobileViewPlanSelect as MobileViewPlanSelect };
