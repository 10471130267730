import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { AppSideBar } from "../app-sidebar";
import { AppFooter } from "../app-footer";
import { PageHeader } from "./header";
import { ScrollBackToTop } from "./scroll-to-top";

const PageLayout = ({ children, pageName, actions, showBackButton = true, showPostJobButton = true }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  return (
    <>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <AppSideBar drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
        <Box sx={{ flex: 1, minHeight: "96vh", display: "flex", flexDirection: "column" }}>
          <PageHeader
            pageName={pageName}
            actions={actions}
            showBackButton={showBackButton}
            showPostJobButton={showPostJobButton}
            toggleDrawer={toggleDrawer}
          />
          <Box component="main" sx={{ flex: 1, minHeight: "96vh", display: "flex", flexDirection: "column" }}>
            {children}
          </Box>
          <AppFooter />
        </Box>
      </Box>
      <ScrollBackToTop />
    </>
  );
};

const MemoizedPageLayout = React.memo(PageLayout);

export { MemoizedPageLayout as PageLayout };
