import { fetchEvents } from "./use-cases/fetch-events";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchEvents = fetchEvents;
}

export const CalendarFeature = new Feature();
