import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { CompanyProfileFeature } from "../../../features/company-profile";
import { ImageAvatar } from "../../../components/image-avatar";
import { SupportPin } from "../../../components/reference-pin";
import { ElevatedCard } from "../../../components/elevated-card";
import { svgIcons } from "../../../icons/svgs";
import { icons } from "../../../icons";
import { EditCompanyProfileModal } from "./edit-company-profile";

const CompanyProfileCard = (props) => {
  const dispatch = useDispatch();
  const companyProfile = useSelector((state) => state.companyProfile);
  const recruiterProfile = useSelector((state) => state.mainProfile);

  const [edit, setEdit] = useState(false);

  const handleImageUpload = async (image) => {
    await CompanyProfileFeature.uploadProfileImage({ image })(dispatch);
  };

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          mx: { xs: "1rem", md: "1.6rem" },
          padding: { xs: "1.2rem", md: "1.6rem" },
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "start",
          }}
        >
          <ImageAvatar
            image={companyProfile.image}
            onUpload={handleImageUpload}
            recruiter={false}
            disabled={recruiterProfile.permission !== "admin"}
          >
            Company <br /> Logo
          </ImageAvatar>
          <Box sx={{ width: "2rem", height: "0.8rem" }} />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>{companyProfile.name}</Typography>
              <PlanChip plan={companyProfile.currentPlan} />
            </Box>
            <Box sx={{ mb: "0.3rem" }}>
              <SupportPin color="red" supportPin={companyProfile.supportPin} />
            </Box>
            <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
              {companyProfile.about}
            </Typography>
            <Box sx={{ ml: "-0.8rem", mt: "0.2rem", mb: "-1rem" }}>
              <UrlIconButton
                icon={svgIcons.website}
                url={!companyProfile.website ? null : `https://${companyProfile.website}`}
              />
              <UrlIconButton
                icon={svgIcons.linkedin}
                url={!companyProfile.linkedIn ? null : `https://www.linkedin.com/in/${companyProfile.linkedIn}`}
              />
              <UrlIconButton
                icon={svgIcons.twitter}
                url={!companyProfile.twitter ? null : `https://twitter.com/${companyProfile.twitter}`}
              />
            </Box>
          </Box>
        </Box>
        <IconButton
          sx={{ padding: "0.6rem", margin: "-0.6rem" }}
          onClick={() => setEdit(true)}
          disabled={recruiterProfile.permission !== "admin"}
        >
          {icons.editPen({})}
        </IconButton>
      </ElevatedCard>
      <EditCompanyProfileModal open={edit} onClose={() => setEdit(false)} />
    </Fragment>
  );
};

const PlanChip = ({ plan }) => {
  return (
    <Typography
      sx={{
        fontSize: "0.88rem",
        ml: "0.6rem",
        padding: "0rem 0.6rem",
        backgroundColor: "primary.main",
        color: "white",
        borderRadius: "0.4rem",
        textTransform: "uppercase",
      }}
    >
      {plan}
    </Typography>
  );
};

const UrlIconButton = ({ url, icon }) => {
  return (
    <IconButton onClick={() => window.open(url, "_blank")} disabled={!url} sx={{ "&:disabled": { cursor: "default" } }}>
      <Avatar
        sx={{
          width: "1.8rem",
          height: "1.8rem",
          filter: `grayscale(${url ? "0" : "100%"})`,
        }}
        src={icon}
      />
    </IconButton>
  );
};

const MemoizedCompanyProfileCard = React.memo(CompanyProfileCard);

export { MemoizedCompanyProfileCard as CompanyProfileCard };
