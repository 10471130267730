import {
  Box,
  ButtonBase,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Fragment, useRef, useState } from "react";
// import { useDispatch } from "react-redux";

import { icons } from "../../../icons";

export const TicketSelect = ({ selectState, setSelectState }) => {
  const anchorRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Box>
      <Typography>Status </Typography>
      <ButtonBase
        onClick={() => setMenuOpen(true)}
        sx={{
          padding: "0.6rem 1rem",
          minHeight: "2.6rem",
          borderRadius: "0.4rem",
          display: "flex",
          minWidth: "10rem",
          justifyContent: "space-between",
          background: "#dadada",
          width: {
            xs: "100%",
            md: "auto",
          },
        }}
        ref={anchorRef}
      >
        {selectState.initialized ? (
          <>
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "left",
                textTransform: "capitalize",
              }}
            >
              {selectState.ticket}
            </Typography>
            <ExpandIcon menuOpen={menuOpen} />
          </>
        ) : (
          <CircularProgress size="1.6rem" />
        )}
      </ButtonBase>
      {selectState.initialized && (
        <TicketSelectMenuList
          {...{
            anchorRef,
            menuOpen,
            setMenuOpen,
            setSelectState,
          }}
        />
      )}
    </Box>
  );
};

const TicketSelectMenuList = ({
  anchorRef,
  menuOpen,
  setMenuOpen,
  setSelectState,
}) => {
  return (
    <Menu
      open={menuOpen}
      anchorEl={anchorRef.current}
      onClose={() => setMenuOpen(false)}
      sx={{ zIndex: 3000 }}
      MenuListProps={{ sx: { padding: "0rem" } }}
      PaperProps={{
        elevation: 6,
        style: {
          borderRadius: "0.4rem",
          maxHeight: "16rem",
          width: anchorRef && anchorRef.current?.offsetWidth,
        },
      }}
    >
      {statuses.map((status, i) => (
        <MenuItem
          key={i}
          onClick={() => {
            setSelectState((prev) => ({
              ...prev,
              ticket: status,
            }));

            setMenuOpen(false);
          }}
          sx={{
            whiteSpace: "break-spaces",
            wordBreak: "break-word",
            textTransform: "capitalize",
          }}
        >
          {status}
        </MenuItem>
      ))}
    </Menu>
  );
};

const ExpandIcon = ({ menuOpen }) => {
  return (
    <Box sx={{ ml: "0.6rem", display: "flex" }}>
      {menuOpen ? icons.expandLess({}) : icons.expandMore({})}
    </Box>
  );
};

export const statuses = ["all", "open", "in progress", "resolved"];
