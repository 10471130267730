import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageTopLoader } from "../../components/loaders/page-top-loader";
import { PageLayout } from "../../components/page-layout";
import { JobsFeature } from "../../features/jobs";
import { JobStatus } from "./components/job-status";
import { ElevatedCard } from "../../components/elevated-card";
import { CustomChip } from "../../components/chip";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
import AirplanemodeActiveRoundedIcon from "@mui/icons-material/AirplanemodeActiveRounded";
import { RecentCandidates } from "./components/recent-candidates";

export const JobDetailsPage = (props) => {
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const [statusChangeActivity, setStatusChangeActivity] = useState([]);

  const [initialized, setInitialized] = useState(false);
  const [job, setJob] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      await JobsFeature.fetchJobDetails({ jobId: query.get("jobId") })(
        dispatch
      ).then((res) => {
        setJob(res);
        setStatusChangeActivity(res.statusChangeActivity);
      });

      setInitialized(true);
    };

    fetch();
  }, [query, dispatch]);

  return (
    <PageLayout pageName="Job Details">
      {!initialized && <PageTopLoader />}
      {initialized && (
        <Box
          sx={{
            margin: { xs: "0rem 1rem 1rem 1rem", sm: "0rem 2rem 2rem 2rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              alignItems: { xs: "stretch", lg: "flex-start" },
            }}
          >
            <Box sx={{ flex: 1.6, display: "flex", flexDirection: "column" }}>
              <ElevatedCard>
                <JobMeta
                  id={job._id}
                  title={job.title}
                  crewType={job.crewType}
                  role={job.role}
                  airportName={job.airport.name}
                  city={job.city}
                  country={job.country}
                  region={job.region}
                  aircraftType={job.aircraftType}
                  contractType={job.contractType}
                  sector={job.sector}
                />
              </ElevatedCard>
              <ElevatedCard sx={{ mt: "2rem" }}>
                <Grid container spacing="0.6rem" columns={{ xs: 2, sm: 4 }}>
                  <InsightContainer
                    title="Impressions"
                    value={job.performance.impressions}
                  />
                  <InsightContainer
                    title="Views"
                    value={job.performance.views}
                  />
                  <InsightContainer
                    title="Shares"
                    value={job.performance.shares}
                  />
                  <InsightContainer
                    title="Saved"
                    value={job.performance.saves}
                  />
                </Grid>
              </ElevatedCard>

              <RecentCandidates />

              <ElevatedCard sx={{ mt: "2rem" }}>
                <Box>
                  <Box
                    sx={{
                      mt: "-0.4rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      description & benefits
                    </Typography>
                    <Button
                      onClick={() => navigate(`/jobs/edit?jobId=${job._id}`)}
                      variant="contained"
                      sx={{
                        ml: "0.8rem",
                        borderRadius: "2rem",
                        textTransform: "capitalize",
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                  <Divider sx={{ mt: "1rem", mb: "1rem" }} />
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: job.description }}
                    sx={{ "& ul": { marginLeft: "1rem" } }}
                  />
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: job.benefits }}
                    sx={{ "& ul": { marginLeft: "1rem" } }}
                  />
                </Box>
                {job.requirements && (
                  <JobRequirements requirements={job.requirements} />
                )}
              </ElevatedCard>
            </Box>
            <Box width="2rem" height="2rem" />
            <Box sx={{ flex: 0.88 }}>
              <JobStatus
                job={job}
                statusChangeActivity={statusChangeActivity}
                setStatusChangeActivity={setStatusChangeActivity}
              />
              <Box width="2rem" height="2rem" />
              {/* flags total count and flag.reason */}
              {job?.flags && (
                <ElevatedCard>
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "primary.main",
                    }}
                  >
                    Job Flagged: Action Required
                  </Typography>

                  <Typography sx={{ mb: "1rem" }}>
                    This job has been flagged by {job?.flags?.length} user
                    {job?.flags?.length > 1 ? "s" : ""}. Please review the
                    reason
                    {job?.flags?.length > 1 ? "s" : ""} below and take
                    appropriate action.
                  </Typography>

                  <Divider sx={{ mb: "1rem" }} />
                  <Box
                    sx={{
                      mt: "0.7rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      maxHeight: "10rem",
                      overflowY: "scroll",
                    }}
                  >
                    {job.flags.map((flag) => (
                      <Box key={flag._id} sx={{ mb: "1rem", display: "flex" }}>
                        <Avatar sx={{ mr: "1rem" }}>U</Avatar>
                        <Typography>{flag.reason}</Typography>
                      </Box>
                    ))}
                  </Box>
                </ElevatedCard>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};

const JobMeta = ({
  id,
  title,
  crewType,
  role,
  airportName,
  city,
  country,
  region,
  aircraftType,
  contractType,
  sector,
}) => {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            textTransform: "capitalize",
            color: "grey",
            fontWeight: "bold",
          }}
        >
          {crewType} - {role}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        sx={{
          mt: "0.4rem",
          mb: "0.6rem",
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        {title}
      </Typography>
      <Box sx={{ display: "flex", flexFlow: "wrap", color: "grey" }}>
        <Box sx={{ display: "flex", alignItems: "center", mr: "0.4rem" }}>
          <WorkRoundedIcon
            fontSize="1.4rem"
            sx={{ mb: "0.2rem", color: "black" }}
          />
          <Typography
            variant="body1"
            sx={{ ml: "0.4rem", textTransform: "capitalize" }}
          >
            {contractType}, {sector}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexFlow: "wrap", mr: "0.4rem" }}>
          <AirplanemodeActiveRoundedIcon
            sx={{
              width: "1.2rem",
              mr: "0.4rem",
              transform: "rotate(45deg)",
              color: "black",
            }}
          />
          <Typography variant="body1">
            {aircraftType.manufacturer} - {aircraftType.model}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexFlow: "wrap" }}>
          <PinDropRoundedIcon
            sx={{ width: "1.2rem", mr: "0.4rem", color: "black" }}
          />
          <Typography variant="body1" mr="0.4rem">
            {airportName},
          </Typography>
        </Box>
        <Typography variant="body1" mr="0.4rem">
          {city},
        </Typography>
        <Typography variant="body1" mr="0.4rem">
          {country},
        </Typography>
        <Typography variant="body1">{region}</Typography>
      </Box>
    </Box>
  );
};

const JobRequirements = ({ requirements }) => {
  return (
    <Section title="requirements" sx={{ mt: "1rem", mb: "-0.8rem" }}>
      <Box component="ul" sx={{ ml: "1rem" }}>
        {requirements.minimumTotalhours !== 0 &&
          requirements.minimumTotalhours !== undefined && (
            <Typography component="li" variant="body1">
              Total {requirements.minimumTotalhours} hours experience required,{" "}
              {requirements.minimumHoursOnType} hours required on type.
            </Typography>
          )}
        {requirements.minimumTotalPICHours !== 0 &&
          requirements.minimumTotalPICHours !== undefined && (
            <Typography component="li" variant="body1" sx={{ mt: "0.4rem" }}>
              Total {requirements.minimumTotalPICHours} hours experience
              required, {requirements.minimumPICHours} hours required on type.
            </Typography>
          )}
        {requirements.minimumYearsExperience !== undefined &&
          requirements.minimumYearsExperience !== 0 && (
            <Typography component="li" variant="body1">
              Total {requirements.minimumYearsExperience} years experience
              required, {requirements.minimumCorportateYearsExperience ?? 0}{" "}
              years corporate required.
            </Typography>
          )}
        {requirements.requiresLicense && (
          <Typography component="li" variant="body1" sx={{ mt: "0.4rem" }}>
            <Typography sx={{ mb: "0.4rem" }}>Required Licenses</Typography>
            {requirements.requiresTypeRating && (
              <CustomChip item="Requires Type Rating" />
            )}
            {requirements.requiredLicenses.map((license) => {
              return (
                <CustomChip
                  key={JSON.stringify(license)}
                  item={`${license.country} - ${license.issuer}`}
                />
              );
            })}
          </Typography>
        )}
        {requirements.requiresVisa && (
          <Typography component="li" variant="body1" sx={{ mt: "0.4rem" }}>
            <Typography sx={{ mb: "0.4rem" }}>Required Visas</Typography>
            <CustomChip item={requirements.requiredVisas} />
          </Typography>
        )}
        {requirements.languages.length !== 0 && (
          <Typography component="li" variant="body1" sx={{ mt: "0.4rem" }}>
            <Typography sx={{ mb: "0.4rem" }}>Required Languages</Typography>
            {requirements.languages.map((language) => (
              <CustomChip key={language} item={language} s />
            ))}
          </Typography>
        )}
      </Box>
    </Section>
  );
};

const Section = ({ title, children, ...props }) => {
  return (
    <Box {...props}>
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", textTransform: "capitalize" }}
      >
        {title}
      </Typography>
      <Divider sx={{ mt: "0.6rem", mb: "1rem" }} />
      {children}
    </Box>
  );
};

const InsightContainer = ({ title, value }) => {
  return (
    <Grid item xs={1}>
      <Box
        sx={{
          width: "100%",
          padding: "0.4rem 0.8rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textTransform: "capitalize",
          color: "white",
          backgroundImage: "linear-gradient(to right, #A060D0, #C46565)",
          border: "1px solid #EFEFEF",
          borderRadius: "1rem",
          boxShadow: "0px 0px 8px 5px #EFEFEF",
        }}
      >
        <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography
          sx={{ mt: "-0.4rem", fontSize: "1.2rem", textAlign: "center" }}
        >
          {value}
        </Typography>
      </Box>
    </Grid>
  );
};
