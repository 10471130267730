import { useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Input from "@mui/material/Input";
import { icons } from "../../../icons";

export const Search = ({ onSearch, search, setSearch }) => {
  const inputRef = useRef();

  const onKeyDown = ({ key }) => {
    if (key === "Enter") onSearch();
  };

  return (
    <Box sx={{ display: "flex", m: "1rem" }}>
      <Card
        elevation={0}
        sx={{ flex: 1, border: "1px solid #ebebec", borderRadius: "2rem", backgroundColor: "#ebebec" }}
      >
        <Box sx={{ alignItems: "center", display: "flex", p: "0.2rem" }}>
          {icons.search({
            fontSize: "small",
            sx: { ml: "0.4rem", color: "#999a9a" },
            onClick: () => inputRef.current.focus(),
          })}
          <Box sx={{ flexGrow: 1, ml: "0.8rem" }}>
            <Input
              inputRef={inputRef}
              sx={{ fontSize: "1rem", color: "#000", "&::placeholder": { color: "#999a9a" } }}
              onChange={({ target }) => setSearch(target.value)}
              value={search}
              onKeyDown={onKeyDown}
              placeholder="Search..."
              disableUnderline
              fullWidth
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
