import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { AuthenticationFeature } from "../../features/authentication";

export const SignOutButton = (props) => {
  const dispatch = useDispatch();

  const onClick = async () => {
    await AuthenticationFeature.signOut()(dispatch);
  };

  return (
    <Button onClick={onClick} sx={{ borderRadius: "2rem" }} {...props}>
      Sign Out
    </Button>
  );
};
