import { Box, CircularProgress, Pagination, styled, Typography } from "@mui/material";
import { JobCard } from "./cards/job";

export const JobsContainer = ({ displayedJobs = [], isLoading, currentPage, totalPages, handlePageChange }) => {
  return (
    <FlexBox>
      <FlexBox>
        {!isLoading && displayedJobs.length !== 0 ? (
          displayedJobs.map((job) => <JobCard job={job} key={job.id} />)
        ) : (
          <FlexBox sx={{ alignItems: "center", justifyContent: "center" }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#C8C8C8" }}>
                No Jobs Posted
              </Typography>
            )}
          </FlexBox>
        )}
      </FlexBox>
      <Pagination
        sx={{ mt: { xs: "1rem", sm: "2rem" } }}
        page={currentPage}
        count={totalPages}
        onChange={handlePageChange}
      />
    </FlexBox>
  );
};

const FlexBox = styled(Box)({ flex: 1, display: "flex", flexDirection: "column" });
