import { Box, Typography } from "@mui/material";
import moment from "moment";
import { icons } from "../../../../icons";

export const License = ({ candidateId, license, setOpenPdf }) => {
  return (
    <Box
      sx={{
        padding: "0.8rem 1rem",
        display: "flex",
        alignItems: "start",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "stretch",
        borderRadius: "0.6rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
            {license.license_issuer.country} - {license.license_issuer.issuer}
          </Typography>
          <Typography sx={{ fontSize: "1rem" }}>
            {license.license_type ? license.license_type : ""}
          </Typography>
          {license &&
          license.expires_on &&
          license.expires_on !== "null" &&
          license.expires_on !== null ? (
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                ml: "-0.4rem",
              }}
            >
              {moment(license.expires_on).isBefore(moment()) ? (
                <>
                  {icons.hourglassBottom({
                    sx: { color: "red", fontSize: "0.9rem" },
                  })}
                  Expired on {moment(license.expires_on).format("DD MMM, YYYY")}
                  .
                </>
              ) : (
                <>
                  {icons.hourglassTop({
                    sx: { color: "green", fontSize: "0.9rem" },
                  })}
                  Expires on {moment(license.expires_on).format("DD MMM, YYYY")}
                  .
                </>
              )}{" "}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "0.8rem",
              }}
            >
              No Expiry Date
            </Typography>
          )}
          <Typography
            color={"primary.main"}
            sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            View Documents:
            {license.documents.map((document) => {
              return icons.article({
                key: document.id,
                onClick: () =>
                  setOpenPdf(
                    `${process.env.REACT_APP_API_BASE_URL}/candidates/${candidateId}/documents?url=${document.url}`
                  ),
                color: "primary.main",
                sx: {
                  width: "max-content",
                  cursor: "pointer",
                  fontSize: "1.5rem",
                  ml: "0.5rem",
                  position: "relative",
                  top: "0.4rem",
                  ":hover": {
                    color: "primary.dark",
                  },
                },
              });
            })}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "4.5",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          justifyContent: "flex-start",
          alignItems: {
            xs: "start",
            sm: "center",
          },
        }}
      >
        {license.lpc_fields && license.lpc_fields.length > 0 && (
          <Typography
            sx={{
              fontSize: "1.08rem",
              fontWeight: "bold",
              alignSelf: {
                xs: "start",
                sm: "center",
              },
              mr: {
                xs: "0",
                sm: "1rem",
              },
              ml: {
                xs: "0",
                sm: "1rem",
              },
            }}
          >
            Type Rating:
          </Typography>
        )}
        {license.lpc_fields && license.lpc_fields.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              alignItems: {
                xs: "start",
                sm: "center",
              },
              flexWrap: "wrap",
            }}
          >
            {license.lpc_fields.map((field) => {
              return (
                <Box
                  key={field.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    width: "auto",
                    minHeight: "4rem",
                    borderRadius: "0.6rem",
                    backgroundColor: "#F4F4FB",
                    mr: "1rem",
                    p: "1rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
                    {field.model}
                  </Typography>
                  {/* {field.expires_on && (
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        {`${
                          moment(field.expires_on).isBefore(moment())
                            ? "Expired on"
                            : "Expires on"
                        } ${moment(field.expires_on).format("DD MMM, YYYY")}.`}
                      </Typography>
                    )} */}
                  {field.expires_on && (
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        ml: "-0.4rem",
                      }}
                    >
                      {moment(field.expires_on).isBefore(moment()) ? (
                        <>
                          {icons.hourglassBottom({
                            sx: { color: "red", fontSize: "0.9rem" },
                          })}
                          Expired on{" "}
                          {moment(field.expires_on).format("DD MMM, YYYY")}.
                        </>
                      ) : (
                        <>
                          {icons.hourglassTop({
                            sx: { color: "green", fontSize: "0.9rem" },
                          })}
                          Expires on{" "}
                          {moment(field.expires_on).format("DD MMM, YYYY")}.
                        </>
                      )}{" "}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
