import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";

import { actions } from "../features/app-messages/slice";

export const SnackbarMessagesListener = ({ children }) => {
  const dispatch = useDispatch();
  const appMessages = useSelector((state) => state.appMessages);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (appMessages.length) {
      const message = appMessages[0];

      enqueueSnackbar(message.message, {
        variant: message.type,
        preventDuplicate: true,
        anchorOrigin: { horizontal: "center", vertical: "bottom" },

        action: (snackbarId) => {
          return (
            <Button color={message.type} onClick={() => closeSnackbar(snackbarId)}>
              Dismiss
            </Button>
          );
        },

        onClose: () => {
          dispatch(actions.remove({ id: message.id }));
        },
      });
    }
  }, [appMessages, enqueueSnackbar, closeSnackbar, dispatch]);

  return children;
};
