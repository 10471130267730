import { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { ElevatedCard } from "../../../components/elevated-card";
import { Modal } from "../../../components/backdrop-modal";
import { PDFView } from "../../../components/pdf-viewer";
import { JobseekersFeature } from "../../../features/jobseekers";
import { useDispatch } from "react-redux";

export const JobseekerResumePdf = ({ jobseekerId, jobseekerName, resume }) => {
  const [viewResume, setViewResume] = useState(false);
  const [noResumeModalOpen, setNoResumeModalOpen] = useState(false);

  return (
    <ElevatedCard sx={{ width: "100%", p: { xs: "1rem", md: "1.6rem" } }}>
      <Box width="100%">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            Resume
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ borderRadius: "2.6rem", height: "2.2rem" }}
              onClick={() => {
                if (!resume) return setNoResumeModalOpen(true);
                setViewResume((prev) => !prev);
              }}
            >
              View
            </Button>
            <Box width="1rem" />
            <DownloadResumeButton
              jobseekerId={jobseekerId}
              jobseekerName={jobseekerName}
              resume={resume}
              noResumeModalOpen={noResumeModalOpen}
              setNoResumeModalOpen={setNoResumeModalOpen}
            />
          </Box>
        </Box>
        <Modal
          headerTitle="Jobseeker Resume"
          open={viewResume}
          onClose={() => setViewResume((prev) => !prev)}
        >
          <PDFView
            url={`${process.env.REACT_APP_API_BASE_URL}/jobseekers/${jobseekerId}/resumes`}
          />
        </Modal>
      </Box>
      <NoResumeModal
        open={noResumeModalOpen}
        onClose={() => setNoResumeModalOpen(false)}
      />
    </ElevatedCard>
  );
};

const DownloadResumeButton = ({
  jobseekerId,
  jobseekerName,
  resume,
  setNoResumeModalOpen,
}) => {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    if (!resume) return setNoResumeModalOpen(true);
    setDownloading(true);
    await JobseekersFeature.downloadJobseekerResume({
      jobseekerId,
      jobseekerName,
    })(dispatch);
    setDownloading(false);
  };

  return (
    <Button
      sx={{ borderRadius: "2.6rem", height: "2.2rem" }}
      onClick={handleDownload}
      disabled={downloading}
    >
      {downloading ? <CircularProgress size="1.4rem" /> : "Download"}
    </Button>
  );
};

const NoResumeModal = ({ open, onClose }) => {
  return (
    <Modal headerTitle="No Resume" open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "1.4rem", fontWeight: "bold", textAlign: "center" }}
        >
          This jobseeker has not uploaded a resume yet.
        </Typography>
        <Box height="1rem" />
        <Typography sx={{ fontSize: "1.2rem", textAlign: "center" }}>
          Please ask the jobseeker to upload a resume.
        </Typography>
      </Box>
    </Modal>
  );
};
