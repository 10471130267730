import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "../link";

export const QuickLinks = (props) => {
  return (
    <Box>
      <Typography variant="h5">Quick Links</Typography>
      <Box sx={{ mt: "0.6rem", ml: "0.4rem", display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Link href="/" title="Home" sx={{ color: "whitesmoke", my: "0.4rem" }} />
          <Link
            href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us`}
            title="About Us"
            sx={{ color: "whitesmoke", my: "0.4rem" }}
          />
          <Link
            href={`${process.env.REACT_APP_MAIN_SITE_URL}/contact-us`}
            title="Contact Us"
            sx={{ color: "whitesmoke", my: "0.4rem" }}
          />
        </Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Link
            href={`${process.env.REACT_APP_MAIN_SITE_URL}/terms-and-conditions`}
            title="Terms & Conditions"
            sx={{ color: "whitesmoke", my: "0.4rem" }}
          />
          <Link
            href={`${process.env.REACT_APP_MAIN_SITE_URL}/privacy-policy`}
            title="Privacy Policy"
            sx={{ color: "whitesmoke", my: "0.4rem" }}
          />
          <Link
            href={`${process.env.REACT_APP_MAIN_SITE_URL}/faqs`}
            title="FAQs"
            sx={{ color: "whitesmoke", my: "0.4rem" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
