import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { JobsFeature } from "../../features/jobs";
import { PageLayout } from "../../components/page-layout";
import { JobsContainer } from "../../components/jobs-container";
import { JobsInsight } from "./components/jobs-insight";
import { RecruiterSelect } from "./components/recruiter-select";
import { SearchBar } from "../../components/searchbar";
import { SortMenu } from "../../components/sort-menu";
// import { icons } from "../../icons";

export const JobsPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [pageInitialized, setPageInitialized] = useState(false);
  const [status, setStatus] = useState("open");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);
  const [fetchingJobs, setFetchingJobs] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("down");

  const { jobs } = useSelector((state) => state);

  useEffect(() => {
    setStatus(searchParams.get("status") ?? "open");
    setCurrentPage(Number(searchParams.get("page") ?? 1));
    setSearch(searchParams.get("search") ?? "");
    setSortBy(searchParams.get("sortBy") ?? "date");
    setPageInitialized(true);
  }, [searchParams]);

  useEffect(() => {
    const fetch = async () => {
      setFetchingJobs(true);

      const totalNoOfPages = await JobsFeature.fetchPostedJobs({
        status: status,
        page: currentPage,
        recruiterProfileId: selectedRecruiter?.profileId,
        search,
        sortBy,
      })(dispatch);
      setTotalPages(totalNoOfPages);
      setFetchingJobs(false);
    };

    if (pageInitialized) {
      fetch();
    }
  }, [
    pageInitialized,
    currentPage,
    status,
    selectedRecruiter,
    search,
    sortBy,
    dispatch,
  ]);

  const handlePageChange = (_, value) => {
    const page = searchParams.get("page") ?? 1;

    if (page !== value) {
      navigate(
        `/?status=${status}&sortBy=${sortBy ?? "date"}&page=${value}&search=${
          search ?? ""
        }`
      );
    }
  };

  return (
    <PageLayout
      pageName="Posted Jobs"
      showBackButton={false}
      showPostJobButton
      showAppBar
    >
      <Box
        sx={{
          flex: 1,
          margin: { xs: "0rem 1rem 1rem 1rem", sm: "0rem 2rem 2rem 2rem" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <JobsInsight
          status={status}
          navigateUrl={`/?sortBy=${sortBy}&page=1&search=${search}`}
        />
        <Box
          sx={{
            mt: { xs: "1rem", sm: "2rem" },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Divider textAlign="left" sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Posted Jobs
            </Typography>
          </Divider>
          <RecruiterSelect
            value={selectedRecruiter}
            onSelected={setSelectedRecruiter}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <SearchBar
            navigateUrl={`/?status=${status}&search=&sortBy=${sortBy}&page=${currentPage}`}
            navigateSearchUrl={(search) =>
              `/?status=${status}&sortBy=${sortBy}&page=1&search=${search}`
            }
          />
          <SortMenu navigateUrl={`/?status=${status}&search=${search}`} />
        </Box>

        <JobsContainer
          isLoading={fetchingJobs}
          displayedJobs={
            status === "open"
              ? jobs.open[currentPage]
              : jobs.closed[currentPage]
          }
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Box>
    </PageLayout>
  );
};
