import { useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { icons } from "../icons";

export const ImagePicker = ({
  src,
  pickedImage,
  onPickImage,
  error,
  imageAttachment,
  setImageError,
  customMessage,
}) => {
  const inputRef = useRef(null);

  const [preview, setPreview] = useState(src || "");

  useEffect(() => {
    let objectUrl;

    if (pickedImage) {
      console.info("pickedImage", pickedImage);
      if (typeof pickedImage === "string") return setPreview(pickedImage);
      if (pickedImage instanceof File) return setPreview(URL.createObjectURL(pickedImage));
      console.info("preview", preview);
      // objectUrl = URL.createObjectURL(pickedImage);
      // setPreview(objectUrl);
    }

    return () => URL.revokeObjectURL(objectUrl);
  }, [pickedImage, src]);

  const handleChange = (event) => {
    const file = event.target.files[0];
    console.info("file", file);
    if (!file) {
      setImageError(true);
      return;
    }
    onPickImage(file);
    setImageError(false);
  };

  return (
    <Box sx={{ my: "1rem", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImagePickerButton onClick={() => inputRef.current.click()} customMessage={customMessage} />
        <input ref={inputRef} onChange={handleChange} type="file" accept="image/*" style={{ display: "none" }} />
        <Box sx={{ width: "1.6rem" }} />

        {!imageAttachment && <Avatar src={preview} sx={{ height: "4rem", width: "4rem", cursor: "pointer" }} />}
        {imageAttachment && (
          <Box
            sx={{
              height: "4rem",
              width: "4rem",
            }}
          >
            {Object.keys(preview).length !== 0 && (
              <img src={preview} alt="attachment" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
            )}
            {Object.keys(preview).length === 0 && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              >
                {icons.image({
                  sx: {
                    fontSize: "2rem",
                    marginRight: "40%",
                    marginTop: "30%",
                  },
                })}
              </Box>
            )}
          </Box>
        )}
      </Box>
      {error && (
        <Typography
          sx={{
            ml: "0.2rem",
            mt: "0.4rem",
            fontSize: "0.9rem",
            color: "red",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

const ImagePickerButton = ({ onClick, customMessage }) => {
  return (
    <Box
      sx={{
        flex: 1,
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed rgba(0, 0, 0, 0.12)",
        borderRadius: "0.6rem",
        cursor: "pointer",
        "&:hover": { borderColor: "primary.main" },
      }}
      onClick={onClick}
    >
      {icons.image({
        sx: { fontSize: "2rem", color: "primary.main", mr: "1rem" },
      })}
      <Typography>{customMessage ? customMessage : "Tap or Click here to upload image"}</Typography>
    </Box>
  );
};
