import { getAccountSummary } from "./use-cases/get-account-summary";
import { uploadProfileImage } from "./use-cases/upload-profile-image";
import { updateProfile } from "./use-cases/update-profile";
import { deleteProfile } from "./use-cases/delete-profile";
import { removeProfileImage } from "./use-cases/remove-profile-image";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  getAccountSummary = getAccountSummary;

  uploadProfileImage = uploadProfileImage;

  updateProfile = updateProfile;

  deleteProfile = deleteProfile;

  removeProfileImage = removeProfileImage;
}

export const MainProfileFeature = new Feature();
