import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

export const Logo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const toHome = () => {
    if (location.pathname !== "/") navigate("/");
  };

  return (
    <Box
      sx={{ cursor: "pointer", objectFit: "cover" }}
      onClick={toHome}
      component="img"
      src="/images/logo/logo.png"
      width="6rem"
      height="2rem"
      mb="0.3rem"
    />
  );
};
