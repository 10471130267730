import { useEffect, useState } from "react";

export const useScrollToTop = (scrollThreshold = 420) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const shouldShow = () => {
      const { scrollTop } = document.documentElement;
      setShow(scrollTop > scrollThreshold);
    };

    window.addEventListener("scroll", shouldShow);

    return () => {
      window.removeEventListener("scroll", shouldShow);
    };
  }, [scrollThreshold]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return [show, scrollToTop];
};
