import { Box, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import * as yup from "yup";
import { ElevatedCard } from "../../../components/elevated-card";

import { TextInput } from "../../../components/inputs/text";
import { AircraftTypeSelect } from "../../../components/selects/aircraft-type";
import { AirportSelect } from "../../../components/selects/airport";
import { CountrySelect } from "../../../components/selects/country";
import { RegionSelect } from "../../../components/selects/region";
import { useDispatch, useSelector } from "react-redux";
import { PostJobFormFeature } from "../../../features/post-job-form";

export const BasicDetailsStep = ({ onNext }) => {
  const dispatch = useDispatch();
  const { formState } = useSelector((state) => state.postJobForm);
  const { title, aircraftType, airport, region, country, city } = formState;
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: title ?? "",
      aircraftType: aircraftType ?? null,
      airport: airport ?? null,
      region: region ?? null,
      country: country ?? null,
      city: city ?? "",
    },

    validationSchema: yup.object({
      title: yup.string().required("Required"),
      aircraftType: yup.object().required("Required"),
      airport: yup.object().required("Required"),
      region: yup.string().required("Required"),
      country: yup.string().required("Required"),
      city: yup.string().required("Required"),
    }),

    onSubmit: async (basicInfo) => {
      setSaving(true);
      await PostJobFormFeature.saveToDraft({ jobInfo: basicInfo })(dispatch);
      setSaving(false);
      onNext();
    },
  });

  const handleAirportChange = ({ target }) => {
    formik.setFieldValue("airport", target.value);
    formik.setFieldValue("region", target.value.region);
    formik.setFieldValue("country", target.value.country);
    formik.setFieldValue("city", target.value.city);
  };

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          padding: { xs: "0rem 1.2rem", sm: "0.6rem 2rem 2rem 2rem" },
          backgroundColor: "#FFFAFA",
        }}
      >
        <TextInput
          label="Job Title"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.title && formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          placeholder="Please enter the job title"
        />
        <AircraftTypeSelect
          label="Aircraft Type"
          name="aircraftType"
          error={Boolean(formik.touched.aircraftType && formik.errors.aircraftType)}
          helperText={formik.touched.aircraftType && formik.errors.aircraftType}
          onChange={formik.handleChange}
          value={formik.values.aircraftType}
        />
        <AirportSelect
          label="Airport"
          name="airport"
          value={formik.values.airport}
          onChange={handleAirportChange}
          error={Boolean(formik.touched.airport && formik.errors.airport)}
          helperText={formik.touched.airport && formik.errors.airport}
        />
        <TextInput
          label="City"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          placeholder="Please enter the city name"
        />
        <CountrySelect
          label="Country"
          name="country"
          type="text"
          value={formik.values.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.country && formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}
        />
        <RegionSelect
          label="Region"
          name="region"
          type="text"
          value={formik.values.region}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.region && formik.errors.region)}
          helperText={formik.touched.region && formik.errors.region}
        />
      </ElevatedCard>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={formik.submitForm} sx={{ borderRadius: "1rem" }} disabled={saving}>
          {saving ? <CircularProgress size="1.4rem" /> : "Continue"}
        </Button>
      </Box>
    </Fragment>
  );
};
