import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";

export const fetchRecruiterInvitations = () => {
  return async (dispatch) => {
    try {
      const recruiterProfile = getAppStoreCurrentState().mainProfile;

      const response = await axiosInstance.get(`/recruiters/${recruiterProfile.email}/invitations`);

      const { data } = handleAxiosResponse(response);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
