import { useState } from "react";
import { Box, Button, IconButton, Link, Typography } from "@mui/material";

import { SectionCard } from "../../components/section-card";
import { ChangePassword } from "./components/change-password";
import { DeleteAccount } from "./components/delete-account";
import { TextFieldBackground } from "../../components/textfield/background";
import { Modal } from "../../components/backdrop-modal";
import { icons } from "../../icons";

export const Settings = () => {
  const [changePassword, setChangePassword] = useState(false);

  const [deleteAccount, setDeleteAccount] = useState(false);
  const [allDone, setAllDone] = useState(false);

  const toggleShowConfirmation = () => {
    setDeleteAccount((prev) => !prev);
  };

  return (
    <Box>
      <SectionCard
        title="Change password"
        actions={
          <IconButton
            sx={{ padding: "0.6rem" }}
            onClick={() => setChangePassword(true)}
          >
            {icons.editPen({})}
          </IconButton>
        }
      >
        <Box sx={{ mt: "1rem", display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>
            Password
          </Typography>
          <TextFieldBackground>
            <Typography
              sx={{
                height: "32px",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                fontWeight: "bold",
                display: "flex",
                alignItems: "flex-end",
                userSelect: "none",
              }}
            >
              **************
            </Typography>
          </TextFieldBackground>
        </Box>
      </SectionCard>
      <ChangePassword
        open={changePassword}
        onClose={() => setChangePassword(false)}
      />
      <Box height="2rem" />
      <SectionCard title="Delete Account">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" sx={{ mt: "1.6rem" }}>
            Delete your account and all of your source data. This is
            irreversible.
          </Typography>
          <Button
            onClick={() => setAllDone(true)}
            sx={{ mt: "0.6rem", alignSelf: "flex-end" }}
          >
            Delete
          </Button>
        </Box>
      </SectionCard>
      <DeleteAccount open={deleteAccount} onClose={toggleShowConfirmation} />
      {/* warning dialog */}
      <Modal
        open={allDone}
        onClose={() => setAllDone(false)}
        headerTitle="Just a moment..."
        headerActions={
          <IconButton
            sx={{ padding: "0.2rem" }}
            onClick={() => setAllDone(false)}
          >
            {icons.close({})}
          </IconButton>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" sx={{ mt: "0.6rem" }}>
            Your account will be setup for the deletion process. All your data
            will be cleared after 30 days. If you happen to change your mind,
            you can get back in within 30 days of your accound deletion. We're
            sorry to see you go. You can{" "}
            <Link href="mailto:admin@aircrewconnect.com">contact us</Link> if
            you have any questions.
            {"\n"}If you're sure you want to delete your account, click the
            button below.
          </Typography>
          <Button
            onClick={() => {
              setAllDone(false);
              toggleShowConfirmation();
            }}
            sx={{ mt: "0.6rem", alignSelf: "flex-end" }}
          >
            Proceed
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};
