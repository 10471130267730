import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";
import { actions } from "../slice";

export const fetchCurrentSubsription = () => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.get(`/subscriptions/${companyProfile.stripeCustomerId}`, {
        params: { companyId: companyProfile.id },
      });

      const { data: subscription } = handleAxiosResponse(response);

      dispatch(actions.setSubscription({ subscription }));
      dispatch(actions.setInitialized());
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
