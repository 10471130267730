import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { actions as mainProfileActions } from "../../main-profile/slice";
import { handleError } from "../../app-messages";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";
import { getAppStoreCurrentState } from "../../../store";

export const createCompanyProfile = ({ profileDetails }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.post(`/companies/${mainProfile.email}/profile`, {
        email: mainProfile.email,
        profileDetails,
      });

      const { data } = handleAxiosResponse(response);

      const recruiterProfile = { ...mainProfile, company: data.id };

      appLocalStorage.save({ [lskeys.companyProfile]: data });
      appLocalStorage.save({ [lskeys.recruiterProfile]: recruiterProfile });

      dispatch(actions.setProfile({ profile: data }));
      dispatch(mainProfileActions.setCompany({ company: recruiterProfile.company }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
