import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { CustomTextField } from "../../../../components/inputs";
import { CustomCheckBoxInput } from "../../../../components/inputs/checkbox";
import { AuthenticationFeature } from "../../../../features/authentication";
import { getAppStoreCurrentState } from "../../../../store";
import { AppRouteConstants } from "../../../../navigations/routes";
import { CircularLoader } from "../../../../components/loaders/circular-loader";

export const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const profileDetails = {
      name: values.name,
      email: values.email.toLowerCase(),
      phone: values.phone,
      password: values.password,
      agreedTerms: values.agreedTerms,
    };

    const { verified } = await AuthenticationFeature.signUp({ profileDetails })(
      dispatch
    );

    if (getAppStoreCurrentState().authentication.isAuthenticated && !verified) {
      return navigate(AppRouteConstants.verifyAccount, { replace: true });
    }

    if (getAppStoreCurrentState().authentication.isAuthenticated && verified) {
      navigate(AppRouteConstants.jobs, { replace: true });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      email: "",
      password: "",
      agreedTerms: false,
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(6, "Name must be minimum 6 character long")
        .max(255)
        .required("Name is required")
        .matches(
          /^[\w.]+\s+[\w.]+(\s+[\w.]+)*$/,
          "Please enter full name, including first and last name"
        ),
      email: yup
        .string()
        .max(255)
        .email("Must be a valid email")
        .required("Email is required"),
      password: yup
        .string()
        .min(8, "Password must be minimum 8 character long")
        .max(255)
        .required("Password is required"),
      agreedTerms: yup
        .bool()
        .is([true], "You need to accept the terms and conditions"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Box>
      <Box>
        <Typography variant="h4" sx={{ mb: "0.4rem" }}>
          Sign Up
        </Typography>
        <Typography variant="subtitle1">
          Register with a new account to continue
        </Typography>
      </Box>

      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ mt: "1rem" }}>
          <CustomTextField
            label="Name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            placeholder="Please enter your personal name (First, Last)"
          />
          <CustomTextField
            label="Email"
            name="email"
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            placeholder="Please enter your company email"
          />
          <CustomTextField
            label="Password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            placeholder="Please enter your password"
          />
        </Box>
        <CustomCheckBoxInput
          name="agreedTerms"
          value={formik.values.agreedTerms}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.touched.agreedTerms && formik.errors.agreedTerms}
        />
        <Box sx={{ height: "1rem" }} />
        <Button
          disabled={formik.isSubmitting}
          type="submit"
          variant="contained"
          sx={{
            p: "0.6rem",
            cursor: formik.isSubmitting ? "not-allowed" : "pointer",
          }}
          disableElevation
        >
          {formik.isSubmitting ? (
            <CircularLoader size="1.6rem" color="primary" />
          ) : (
            "Sign Up"
          )}
        </Button>
      </Box>

      <Typography variant="subtitle1" textAlign="center" sx={{ my: "1.6rem" }}>
        Already have an account ?
        <SignInLink navigate={navigate} />
      </Typography>
    </Box>
  );
};

const SignInLink = ({ navigate }) => {
  const onClick = () => {
    navigate("/authentication?action=sign-in", { replace: true });
  };

  return (
    <Box
      component="span"
      onClick={onClick}
      sx={{
        ml: "0.4rem",
        fontWeight: "bold",
        cursor: "pointer",
        ":hover": { textDecoration: "underline" },
      }}
    >
      Sign In
    </Box>
  );
};
