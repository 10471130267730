import { Avatar, Box, Typography } from "@mui/material";
import moment from "moment";
import { icons } from "../../../icons";

export const Message = ({ comments, comment, index, scrollRef }) => {
  return (
    <Box
      key={index} //className={classes.comment}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: "1rem",
      }}
      ref={index === comments.length - 1 ? scrollRef : null}
    >
      <Avatar sx={{ mr: "1rem", alignSelf: "flex-start" }}>
        {comment?.message_by
          ? comment?.message_by === "user"
            ? "Y"
            : "S"
          : ""}
      </Avatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mr: "1rem",
          }}
        >
          <Typography variant="subtitle2">
            {comment?.message_by
              ? comment?.message_by === "user"
                ? "You"
                : "Support Agent"
              : ""}
          </Typography>
          {icons.circle({
            sx: {
              height: "0.4rem",
              width: "0.4rem",
              mx: "0.6rem",
            },
          })}
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            {comment?.timestamp &&
            moment(comment?.timestamp).isSame(new Date(), "day")
              ? moment(comment?.timestamp).format("LT")
              : moment(comment?.timestamp).calendar()}
          </Typography>
        </Box>
        {/* render the messages while respecting the new lines */}
        <Typography variant="subtitle1">
          {comment?.message_type && comment?.message_type === "image" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                component="img"
                src={comment?.attachment_image?.url}
                alt={comment?.attachment_image?.name}
                style={{ maxWidth: "600px" }}
              />
              {/* caption */}
              <Typography
                variant="subtitle2"
                sx={{
                  mt: "0.5rem",
                  color: "#777",
                  borderLeft: "2px solid #777",
                  pl: "0.5rem",
                }}
              >
                {comment?.message &&
                  comment?.message.split("\n").map((str, index) => (
                    <span key={index}>
                      {str}
                      <br />
                    </span>
                  ))}
              </Typography>
            </Box>
          ) : (
            comment?.message &&
            comment?.message.split("\n").map((str, index) => (
              <span key={index}>
                {str}
                <br />
              </span>
            ))
          )}
        </Typography>
      </Box>
    </Box>
  );
};
