import {
  Box,
  ButtonBase,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  // TextField,
} from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { icons } from "../../../icons";
import { useEffect } from "react";

export const JobSelect = ({ isLoading, options, value, onChange }) => {
  const anchorRef = useRef(null);
  const mainProfile = useSelector((state) => state.mainProfile);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Fragment>
      <ButtonBase
        onClick={() => setMenuOpen(true)}
        sx={{
          padding: "0.6rem 1rem",
          minHeight: "2.6rem",
          borderRadius: "0.4rem",
          display: "flex",
        }}
        disabled={options.length === 0}
        ref={anchorRef}
      >
        {!isLoading ? (
          <>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              {options.length === 0
                ? "No Jobs Posted"
                : value
                ? value.title
                : "All Jobs"}
            </Typography>
            <ExpandIcon menuOpen={menuOpen} />
          </>
        ) : (
          <CircularProgress size="1.6rem" />
        )}
      </ButtonBase>
      {!isLoading && (
        <JobSelectMenuList
          {...{
            anchorRef,
            menuOpen,
            setMenuOpen,
            options,
            onChange,
            mainProfile,
          }}
        />
      )}
    </Fragment>
  );
};

const JobSelectMenuList = ({
  anchorRef,
  menuOpen,
  setMenuOpen,
  options,
  onChange,
  mainProfile,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const onSelectJob = (job) => {
    onChange(job);
    setMenuOpen(false);
  };

  const filteredOpenOptions = options.open.filter((job) =>
    job.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredClosedOptions = options.closed.filter((job) =>
    job.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    console.info("serach", searchQuery);
  }, [searchQuery]);

  return (
    <Menu
      open={menuOpen}
      anchorEl={anchorRef.current}
      onClose={() => setMenuOpen(false)}
      sx={{ zIndex: 3000 }}
      MenuListProps={{
        sx: { padding: "0rem" },
      }}
      PaperProps={{
        elevation: 6,
        style: {
          borderRadius: "0.4rem",
          maxHeight: "16rem",
          width: anchorRef && anchorRef.current?.offsetWidth,
          minWidth: "300px",
        },
      }}
      // onChangeCapture={(e) => {
      //   e.preventDefault();
      //   e.stopPropagation();
      // }}
      // onChange={(e) => {
      //   e.preventDefault();
      //   e.stopPropagation();
      // }}
    >
      {/* <TextField
        label="Search Jobs"
        value={searchQuery}
        onFocus={(e) => e.preventDefault()}
        // disableAutoFocus={true}
        // onKeyDown={(event) => {
        //   // event.stopPropagation();
        //   event.preventDefault();
        //   setSearchQuery(event.target.value);
        // }}
        onChange={(event) => {
          // event.stopPropagation();

          // event.preventDefault();
          setSearchQuery(event.target.value);
        }}
        // SelectProps={{ autoSelect: false }}
      /> */}
      {
        <MenuItem
          key={"all"}
          onClick={() => {
            onChange((prev) => ({
              ...prev,
              company: mainProfile.company,
              job: null,
            }));
            setMenuOpen(false);
          }}
          sx={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
        >
          All Jobs
        </MenuItem>
      }
      {/* {options.all.map((job) => (
        <MenuItem
          key={job._id}
          onClick={() => onSelectJob(job)}
          sx={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
        >
          {job.title}
        </MenuItem>
      ))} */}
      {filteredOpenOptions.length !== 0 && (
        <Typography sx={{ padding: "0.6rem 1rem", backgroundColor: "#C8C8C8" }}>
          Active Jobs
        </Typography>
      )}
      {filteredOpenOptions.map((job) => (
        <MenuItem
          key={job._id}
          onClick={() => onSelectJob(job)}
          sx={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
        >
          {job.title}
        </MenuItem>
      ))}
      {filteredClosedOptions.length !== 0 && (
        <Typography sx={{ padding: "0.6rem 1rem", backgroundColor: "#C8C8C8" }}>
          Closed Jobs
        </Typography>
      )}
      {filteredClosedOptions.map((job) => (
        <MenuItem
          key={job._id}
          onClick={() => onSelectJob(job)}
          sx={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
        >
          {job.title}
        </MenuItem>
      ))}
    </Menu>
  );
};

const ExpandIcon = ({ menuOpen }) => {
  return (
    <Box sx={{ ml: "0.6rem", display: "flex" }}>
      {menuOpen ? icons.expandLess({}) : icons.expandMore({})}
    </Box>
  );
};
