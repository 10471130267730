import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"; // Load worker code separately with worker-loader

mapboxgl.workerClass = MapboxWorker;

export const MapView = (props) => {
  const [deviceLocation, setDeviceLocation] = useState({
    longitude: 55.1740584,
    latitude: 25.1013886,
  });
  const [locationAvailable, setLocationAvailable] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const geoLocationSuccess = useCallback(({ coords }) => {
    setDeviceLocation({
      longitude: coords.longitude,
      latitude: coords.latitude,
    });
    setInitialized(true);
    setLocationAvailable(true);
  }, []);

  const geoLocationError = useCallback(() => {
    setInitialized(true);
    setLocationAvailable(false);
  }, []);

  useEffect(() => {
    navigator?.geolocation?.getCurrentPosition(
      geoLocationSuccess,
      geoLocationError
    );
  }, [geoLocationError, geoLocationSuccess]);

  if (!initialized) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography>Getting Your Location</Typography>
      </Box>
    );
  }

  if (!locationAvailable) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: { xs: "none", sm: "block" },
          background: "url('/images/background/authentication-page.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "left",
        }}
      />
    );
  }

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_PK}
      initialViewState={{
        longitude: deviceLocation.longitude,
        latitude: deviceLocation.latitude,
        zoom: 12,
      }}
      style={{ width: "100%", height: "100%" }}
      mapStyle="mapbox://styles/mapbox/light-v11"
      projection="globe"
    >
      <Marker
        longitude={deviceLocation.longitude}
        latitude={deviceLocation.latitude}
        anchor="bottom"
        color="red"
      />
    </Map>
  );
};
