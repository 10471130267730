import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { showSuccess, handleError } from "../../app-messages";
import { actions } from "../slice";

// export const leaveFromCompany = ({ memberEmail }) => {
//   return async (dispatch) => {
//     try {
//       const { companyProfile } = getAppStoreCurrentState();

//       const response = await axiosInstance.delete(`/companies/${companyProfile.id}/recruiters/${memberEmail}`);

//       const { message } = handleAxiosResponse(response);

//       dispatch(showSuccess(message));

//       return memberEmail;
//     } catch (error) {
//       (handleError(error?.response?.data?.message ?? error.message)(dispatch));
//     }
//   };
// };

export const removeFromCompany = ({ memberEmail }) => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.delete(`/companies/${companyProfile.id}/recruiters/${memberEmail}`);

      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));

      dispatch(actions.removeMember({ memberEmail }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
