import { fetchSupportTitles } from "./use-cases/fetch-support-titles.js";
import { createNewTicket } from "./use-cases/create-new-ticket.js";
import { fetchSupportTicketDetails } from "./use-cases/fetch-support-ticket-details.js";
import { patchComment } from "./use-cases/patch-comment.js";
import { addFeedback } from "./use-cases/add-feedback.js";
import { markIssueAsResolved } from "./use-cases/mark-issue-as-resolved.js";
import { patchImageComment } from "./use-cases/patch-image-comment.js";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchSupportTitles = fetchSupportTitles;

  fetchSupportTicketDetails = fetchSupportTicketDetails;

  createNewTicket = createNewTicket;

  patchComment = patchComment;

  patchImageComment = patchImageComment;

  addFeedback = addFeedback;

  markIssueAsResolved = markIssueAsResolved;
}

export const SupportFeature = new Feature();
