import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";

import { Container } from "@mui/material";

import { SignInForm } from "./components/sign-in-form";
import { SignUpForm } from "./components/sign-up-form";
import { VerifyAccountForm } from "./components/verify-account-form";
import { ResetPasswordForm } from "./components/reset-password-form";
import { ForgotPasswordForm } from "./components/forgot-password-form";
import { MapView } from "../../components/map-view";
import { CreateOrJoinCompanyForm } from "./components/create-or-join-company-form";

export const AuthenticationPage = (props) => {
  const [page, setPage] = useState("sign-in");

  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");

  useEffect(() => {
    setPage(action || page);
  }, [action, page]);

  return (
    <Box component="main" sx={{ display: "flex", height: "100vh" }}>
      <Box sx={{ flex: 0.8, display: { xs: "none", sm: "block" } }}>
        <MapView />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          mx: { xs: "0rem", md: "2rem" },
          overflowY: "scroll",
        }}
      >
        <Container
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            my: "3rem",
          }}
          maxWidth="sm"
        >
          {page === "sign-up" && <SignUpForm />}
          {page === "verify-account" && <VerifyAccountForm />}
          {page === "create-or-join-company" && <CreateOrJoinCompanyForm />}
          {page === "sign-in" && <SignInForm />}
          {page === "forgot-password" && <ForgotPasswordForm />}
          {page === "reset-password" && <ResetPasswordForm />}
        </Container>
      </Box>
    </Box>
  );
};
