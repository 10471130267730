class AppLocalStorage {
  save = (items) => {
    for (const key in items) {
      const item = typeof items[key] === "string" ? items[key] : JSON.stringify(items[key]);
      window.localStorage.setItem(key, item);
    }
  };

  get = (key) => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      return window.localStorage.getItem(key);
    }
  };

  clearItem = (keys) => {
    for (const key of keys) {
      window.localStorage.removeItem(key);
    }
  };

  clear = () => {
    window.localStorage.clear();
  };
}

export const appLocalStorage = new AppLocalStorage();

export const lskeys = Object.freeze({
  accessToken: "acc-access-token",
  refreshToken: "acc-refresh-token",
  recruiterProfile: "acc-recruiter-profile",
  companyProfile: "acc-company-profile",
  jobFormState: "acc-job-form-state",
  filters: "acc-candidates-filter-options",
  refreshSubscribers: "acc-refresh-subscribers",
});
