import { useDispatch } from "react-redux";
import { Box, Button, IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MainProfileFeature } from "../../../features/main-profile";
import { Modal } from "../../../components/backdrop-modal";
import { CustomTextField } from "../../../components/inputs";
import { icons } from "../../../icons";

export const DeleteAccount = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { password: "" },
    validationSchema: Yup.object({
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async ({ password }, helpers) => {
      await MainProfileFeature.deleteProfile({ password })(dispatch);
      helpers.resetForm();
      onClose();
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      headerTitle="Please confirm"
      headerActions={
        <IconButton sx={{ padding: "0.2rem" }} onClick={handleCancel}>
          {icons.close({})}
        </IconButton>
      }
      modalActions={
        <Button
          variant="outlined"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting}
          fullWidth
        >
          Delete my account
        </Button>
      }
    >
      <Box component="form" noValidate onSubmit={formik.handleSubmit}>
        <CustomTextField
          label="Confirm the password to delete your account"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.password && formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          placeholder="Enter your password"
        />
      </Box>
    </Modal>
  );
};
