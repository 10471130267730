import { Box, CircularProgress } from "@mui/material";
import { useRef, useState, useLayoutEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Modal } from "./modal";

export const PDFDisplayModal = ({ openPdf, onClose }) => {
  const docRef = useRef();
  const [numPages, setNumPages] = useState(null);
  const [docWidth, setDocWidth] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useLayoutEffect(() => {
    setDocWidth(docRef.current?.offsetWidth);
  }, [numPages]);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <Modal open={openPdf !== null} onClose={onClose} disableEscapeKeyDown={false}>
      <Box ref={docRef} width="100%" sx={{ maxHeight: "88vh", overflowY: "scroll" }}>
        <Document
          renderMode="canvas"
          file={{ url: openPdf }}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <Box sx={{ width: "100%", display: "grid", placeItems: "center" }}>
              <CircularProgress />
            </Box>
          }
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={docWidth} />
          ))}
        </Document>
      </Box>
    </Modal>
  );
};
