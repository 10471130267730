import { Fragment, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { ImageUploader } from "./image-uploader";
import { icons } from "../icons";

export const ImageAvatar = ({ image, onUpload, children, recruiter, disabled = false }) => {
  const [upload, setUpload] = useState(false);

  return (
    <Fragment>
      <Badge
        onClick={() => {
          if (!disabled) {
            setUpload(true);
          }
        }}
        badgeContent={<BadgeContent />}
        variant="standard"
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        <Avatar
          src={image}
          sx={{
            height: { xs: "4.6rem", md: "6rem" },
            width: { xs: "4.6rem", md: "6rem" },
          }}
          imgProps={{
            style: {
              objectFit: "cover",
            },
          }}
        >
          <Typography sx={{ textAlign: "center" }}>{children}</Typography>
        </Avatar>
      </Badge>
      <ImageUploader
        currentImage={""}
        open={upload}
        onClose={() => {
          if (!disabled) {
            setUpload(false);
          }
        }}
        onUpload={onUpload}
        recruiter={recruiter}
      />
    </Fragment>
  );
};

const BadgeContent = () => {
  return (
    <Avatar
      sx={{
        width: "1.6rem",
        height: "1.6rem",
        backgroundColor: "primary.main",
      }}
    >
      {icons.editPen({ fontSize: "1.2rem" })}
    </Avatar>
  );
};
