import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { ElevatedCard } from "./elevated-card";

export const SectionCard = ({ title, actions, children }) => {
  return (
    <ElevatedCard>
      <Box sx={{ mt: "-0.6rem", display: "flex", flexDirection: "column", alignItems: "stretch" }}>
        <SectionHeader title={title} actions={actions} />
        <Box sx={{ flex: 1 }}>{children}</Box>
      </Box>
    </ElevatedCard>
  );
};

const SectionHeader = ({ title, actions }) => {
  return (
    <Box>
      <Box sx={{ height: "2.6rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold", textTransform: "capitalize" }}>{title}</Typography>
        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>{actions}</Box>
      </Box>
      <Divider sx={{ mt: "0.6rem" }} />
    </Box>
  );
};
