import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { handleError, showSuccess } from "../../app-messages";

export const resetPassword = ({ token, email, password }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("/authentication/password-reset", { email, token, password });
      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));

      return true;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
