import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError, showSuccess } from "../../app-messages";

export const updateJobDetails = ({ jobId, jobInfo }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`/jobs/${jobId}/details`, {
        jobInfo,
      });
      const { data } = handleAxiosResponse(response);
      dispatch(showSuccess("Job details updated successfully"));
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
