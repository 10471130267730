import { createCompanyProfile } from "./use-cases/create-company";
import { uploadProfileImage } from "./use-cases/upload-profile-image";
import { updateProfile } from "./use-cases/update-profile";
import { updateCurrentPlan } from "./use-cases/update-plan";
import { deleteProfile } from "./use-cases/delete-profile";
import { removeProfileImage } from "./use-cases/remove-profile-image";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  createCompanyProfile = createCompanyProfile;

  uploadProfileImage = uploadProfileImage;

  updateProfile = updateProfile;

  updateCurrentPlan = updateCurrentPlan;

  deleteProfile = deleteProfile;

  removeProfileImage = removeProfileImage;
}

export const CompanyProfileFeature = new Feature();
