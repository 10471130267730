import {
  axiosInstance,
  handleAxiosResponse,
} from "../../../core/sources/network";

import { handleError } from "../../app-messages";
// import { actions } from "../slice";

export const updateJobStatus = ({ jobId, status }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`/jobs/${jobId}/status`, {
        status,
      });

      const { data } = handleAxiosResponse(response);

      //   dispatch(actions.setJobs({ jobs: { active: data.slice(0, 3), closed: data.slice(3) } }));

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
