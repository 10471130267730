import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MuiModal from "@mui/material/Modal";

export const Modal = ({ open, allowClose = true, onClose, headerTitle, headerActions, children, modalActions }) => {
  const handleClose = (_, event) => {
    if (!allowClose) {
      if (event === "backdropClick") {
        return;
      }
    }

    onClose();
  };

  return (
    <MuiModal open={open} onClose={handleClose}>
      <Box
        sx={{
          minWidth: { xs: "90%", sm: "26rem" },
          width: "80%",
          maxWidth: "36rem",
          p: "0.6rem 1.2rem 1.2rem 1.2rem",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "0.8rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ModalHeader title={headerTitle} actions={headerActions} />
        <ModalContent>{children}</ModalContent>
        {modalActions && <ModalActions>{modalActions}</ModalActions>}
      </Box>
    </MuiModal>
  );
};

const ModalHeader = ({ title, actions }) => {
  return (
    <Box>
      <Box
        sx={{
          height: "2.6rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          {title}
        </Typography>
        <Box>{actions}</Box>
      </Box>
      <Divider sx={{ mt: "0.6rem" }} />
    </Box>
  );
};

const ModalContent = ({ children }) => {
  return <Box sx={{ maxHeight: "72vh", overflowY: "scroll" }}>{children}</Box>;
};

const ModalActions = ({ children }) => {
  return <Box sx={{ mt: "1.2rem" }}>{children}</Box>;
};
