import { Box, ButtonBase, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { icons } from "../../icons";

export const DropdownSelect = ({ label, options, name, value, onChange, error, helperText }) => {
  const anchorRef = useRef(null);

  const [selected, setSelected] = useState(value || options[0]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (value !== selected) {
      onChange({ target: { name, value: selected } });
    }
  }, [selected, value, name, onChange]);

  const onSelect = (option) => {
    setSelected(option);
    setMenuOpen(false);
  };

  return (
    <Box sx={{ my: "1.6rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>

      <ButtonBase
        onClick={() => setMenuOpen(true)}
        sx={{
          width: "100%",
          padding: "0.8rem 1.6rem",
          borderRadius: "4px",
          backgroundColor: "#F4F4FB",
          display: "flex",
          justifyContent: "space-between",
          textTransform: "capitalize",
        }}
        ref={anchorRef}
      >
        <Typography>{selected}</Typography>
        <ExpandIcon menuOpen={menuOpen} />
      </ButtonBase>

      <Menu
        open={menuOpen}
        anchorEl={anchorRef.current}
        defaultValue={options[0]}
        onClose={() => setMenuOpen(false)}
        sx={{ zIndex: 3000 }}
        PaperProps={{
          elevation: 6,
          style: { borderRadius: "0.4rem", maxHeight: "16rem", width: anchorRef && anchorRef.current?.offsetWidth },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => onSelect(option)}
            sx={{ textTransform: "capitalize", whiteSpace: "break-spaces", wordBreak: "break-word", py: "0.5rem" }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

const ExpandIcon = ({ menuOpen }) => {
  return <Box sx={{ ml: "0.6rem", display: "flex" }}>{menuOpen ? icons.expandLess({}) : icons.expandMore({})}</Box>;
};
