import { Box, Checkbox, Typography } from "@mui/material";

export const CustomCheckBoxInput = ({ name, value, onChange, onBlur, helperText }) => {
  return (
    <Box>
      <Box sx={{ my: "0.6rem", mr: "0.6rem", display: "flex", alignItems: "center" }}>
        <Checkbox name={name} value={value} onChange={onChange} onBlur={onBlur} />
        <Typography>
          I agree to{" "}
          <Box
            component="a"
            href={`${process.env.REACT_APP_MAIN_SITE_URL}/terms-and-conditions`}
            target="_blank"
            sx={{ color: "black", textDecoration: "underline", cursor: "pointer" }}
          >
            terms and conditions
          </Box>
        </Typography>
      </Box>

      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.4rem", color: "red" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
