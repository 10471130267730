import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SubscriptionsFeature } from "../../../features/subscription";
import { Modal } from "../../../components/backdrop-modal";

const ConfirmLiteSubscription = ({ open, onClose, billingCycle }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    setSubmitting(true);

    await SubscriptionsFeature.subscribeToLite({ billingCycle })(dispatch);

    setSubmitting(false);
    handleClose();

    const returnTo = searchParams.get("return_to");
    if (returnTo) {
      navigate(returnTo, { replace: true });
    }
  };

  return (
    <Modal
      headerTitle="Thinking of downgrading?"
      open={open}
      allowClose={!submitting}
      onClose={handleClose}
      modalActions={
        <Button
          sx={{ padding: "0.2rem 0.8rem", borderRadius: "0.4rem" }}
          onClick={handleConfirm}
          disabled={submitting}
          fullWidth
        >
          Confirm
        </Button>
      }
    >
      <Typography sx={{ mt: "1rem" }}>
        Are you sure you want to lose all of the features of your paid plan? You
        will only be able to post 3 jobs under the 'Lite' plan, and you will
        lose the ability to sponsor any of your jobs to attract the finest
        candidates. There are many good reasons why not to go, but we understand
        if you do. Moving to Lite will still allow you to view the CVs of all
        your candidates, but it might take you a little while to process them
        without the powerful search features and profile views of you paid plan.
        Don't worry you can come back anytime! We hope so see you soon.
      </Typography>
    </Modal>
  );
};

const ConfirmProSubscription = ({
  open,
  onClose,
  billingCycle,
  paymentMethodId,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { subscription } = useSelector((state) => state);
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    setSubmitting(true);

    await SubscriptionsFeature.subscribeToPro({
      paymentMethodId: paymentMethodId,
      billingPeriod: billingCycle,
      changeType: subscription.plan === "enterprise" ? "downgrade" : "upgrade",
    })(dispatch);

    setSubmitting(false);
    handleClose();

    const returnTo = searchParams.get("return_to");
    if (returnTo) {
      navigate(returnTo, { replace: true });
    }
  };

  return (
    <Modal
      headerTitle={
        subscription.plan === "enterprise"
          ? "Downgrading to Pro?"
          : "Get three months pro subscription for free!"
      }
      open={open}
      allowClose={!submitting}
      onClose={handleClose}
      modalActions={
        <Button
          sx={{ padding: "0.2rem 0.8rem", borderRadius: "0.4rem" }}
          onClick={handleConfirm}
          disabled={submitting}
          fullWidth
        >
          Confirm
        </Button>
      }
    >
      <Typography sx={{ mt: "1rem" }}>
        {subscription.plan === "enterprise"
          ? `downgrading to pro`
          : `Get three months pro subscription for free. The #FIRST-FIFTY coupon will be automatically applied to this purchase.
          'Pro' will change your recruiting experience forever. Not only does this plan increase your job posting ability
        to 12 jobs, it also allows you to sponsor 2 jobs per month too. 'Pro' allows you to download candidates
        profiles, search the job seeker databases and check the job analytics of each of your jobs. 'Pro' also will also
        automatically social media market your jobs across all our platforms and continually SEO them across the most
        popular search engines.`}
      </Typography>
    </Modal>
  );
};

const ConfirmEnterpriseSubscription = ({
  open,
  onClose,
  billingCycle,
  paymentMethodId,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    setSubmitting(true);

    await SubscriptionsFeature.subscribeToEnterprise({
      paymentMethodId: paymentMethodId,
      billingPeriod: billingCycle,
    })(dispatch);

    setSubmitting(false);
    handleClose();

    const returnTo = searchParams.get("return_to");
    if (returnTo) {
      navigate(returnTo, { replace: true });
    }
  };

  return (
    <Modal
      headerTitle="Changing to Enterprise!"
      open={open}
      allowClose={!submitting}
      onClose={handleClose}
      modalActions={
        <Button
          sx={{ padding: "0.2rem 0.8rem", borderRadius: "0.4rem" }}
          onClick={handleConfirm}
          disabled={submitting}
          fullWidth
        >
          Confirm
        </Button>
      }
    >
      <Typography sx={{ mt: "1rem" }}>
        'Enterprise' will change your recruiting experience forever. You will
        now have unlimited job posts and 8 sponsored jobs each month.
        'Enterprise' allows you to download candidates' profiles, search the job
        seeker databases, and check the job analytics of each of your jobs.
        'Enterprise' will also automatically market your jobs on social media
        across all our platforms and continually optimize them for SEO on the
        most popular search engines. Let's not forget it's a Team game after
        all! 'Enterprise' allows you to add team members to your plan, making
        the recruitment of crew members much easier! Welcome to Enterprise.
      </Typography>
    </Modal>
  );
};

const MemoizedConfirmLiteSubscription = React.memo(ConfirmLiteSubscription);

const MemoizedConfirmProSubscription = React.memo(ConfirmProSubscription);

const MemoizedConfirmEnterpriseSubscription = React.memo(
  ConfirmEnterpriseSubscription
);

export const Confirmations = {
  ConfirmLiteSubscription: MemoizedConfirmLiteSubscription,

  ConfirmProSubscription: MemoizedConfirmProSubscription,

  ConfirmEnterpriseSubscription: MemoizedConfirmEnterpriseSubscription,
};
