import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { handleError } from "../../app-messages";

export const fetchConversationInfo = ({ conversationId }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations/${conversationId}`
      );

      const { data } = handleAxiosResponse(response);

      return {
        id: data["id"],
        archived: data["archived"],
        recipient: data["recipient"],
      };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
