import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { CompanyMembersFeature } from "../../features/company-members";
import { SectionCard } from "../../components/section-card";
import { TeamMember } from "../../components/cards/team-member";
import { InviteMemberModal } from "./components/invite-member-modal";

const Team = (props) => {
  const dispatch = useDispatch();
  const recruiterProfile = useSelector((state) => state.mainProfile);
  const companyProfile = useSelector((state) => state.companyProfile);
  const companyMembers = useSelector((state) => state.companyMembers);

  const [inviteMember, setInviteMember] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    CompanyMembersFeature.fetchCompanyMembers()(dispatch).then(() =>
      setInitialized(true)
    );
  }, [dispatch, companyProfile.currentPlan]);

  if (!initialized) {
    return (
      <Box sx={{ flex: 1, display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <SectionCard
        title={`Members (${companyMembers.length})`}
        actions={
          <Button
            sx={{ borderRadius: "2.5rem" }}
            onClick={() => setInviteMember(true)}
            disabled={
              recruiterProfile.permission !== "admin" ||
              companyProfile.currentPlan !== "enterprise"
            }
          >
            Invite Member
          </Button>
        }
      >
        {companyMembers.map((member) => (
          <TeamMember key={member.email} member={member} />
        ))}
      </SectionCard>
      {companyProfile.currentPlan !== "enterprise" && (
        <Box sx={{ margin: "2rem 0.2rem" }}>
          <Alert severity="warning" sx={{ borderRadius: "1rem" }}>
            Unlock The Team Feature by Upgrading to Our Enterprise Plan!
          </Alert>
        </Box>
      )}
      <InviteMemberModal
        open={inviteMember}
        onClose={() => setInviteMember(false)}
      />
    </Fragment>
  );
};

const MemoizedTeam = React.memo(Team);

export { MemoizedTeam as Team };
