import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { InsightCard } from "../../../components/cards/insight";
import { useNavigate } from "../../../hooks/use-navigate";

export const JobsInsight = ({ status, navigateUrl }) => {
  const { jobs } = useSelector((state) => state);

  const navigateToClosed = useNavigate(`${navigateUrl}&status=closed`);

  const navigateToOpen = useNavigate(`${navigateUrl}&status=open`);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <InsightCard
        title="Open"
        value={jobs.count.open}
        active={status === "open"}
        onClick={navigateToOpen}
      />
      <Box sx={{ width: { xs: "1rem", sm: "2rem" } }} />
      <InsightCard
        title="Closed"
        value={jobs.count.closed}
        active={status === "closed"}
        onClick={navigateToClosed}
      />
    </Box>
  );
};
