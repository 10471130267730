import { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { CompanyProfileFeature } from "../features/company-profile";
import { MainProfileFeature } from "../features/main-profile";
import { icons } from "../icons";
import { Modal } from "./backdrop-modal";
import { ImagePicker } from "./image-picker";
import { LoadingButton } from "./buttons/loading-button";

export const ImageUploader = ({ currentImage, onUpload, open, onClose, recruiter }) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const handleSubmit = async ({ image }, helpers) => {
    await onUpload(image);
    helpers.resetForm();
    onClose();
  };

  const formik = useFormik({
    initialValues: { image: null },
    validationSchema: yup.object({
      image: yup.mixed().required("Image is required"),
    }),
    onSubmit: handleSubmit,
  });

  const handlePickImage = (image) => {
    formik.setFieldValue("image", image);
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const deleteProfileImage = async () => {
    setDeleting(true);
    if (recruiter) {
      await MainProfileFeature.removeProfileImage()(dispatch);
    } else {
      await CompanyProfileFeature.removeProfileImage()(dispatch);
    }
    setDeleting(false);

    handleClose();
  };

  return (
    <Modal
      headerTitle="Upload Profile Image"
      open={open}
      onClose={handleClose}
      headerActions={
        <Box>
          <IconButton onClick={handleClose} sx={{ padding: "0.6rem" }}>
            {icons.close({})}
          </IconButton>
        </Box>
      }
      modalActions={
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <LoadingButton variant="outlined" onClick={deleteProfileImage} isLoading={deleting} fullWidth>
            {deleting ? "Removing" : "Remove Profile Image"}
          </LoadingButton>
          <LoadingButton variant="contained" onClick={formik.submitForm} isLoading={formik.isSubmitting} fullWidth>
            {formik.isSubmitting ? "Uploading" : "Upload"}
          </LoadingButton>
        </Box>
      }
    >
      <Box sx={{ mt: "1.6rem", mb: "-0.6rem" }}>
        <ImagePicker
          src={currentImage}
          pickedImage={formik.values.image}
          onPickImage={handlePickImage}
          error={formik.errors.image}
        />
      </Box>
    </Modal>
  );
};
