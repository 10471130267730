import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export const PostJobButton = ({ children, ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/jobs/post")}
      sx={{
        padding: { xs: "0.2rem 0.8rem", sm: "0.4rem 1rem" },
        borderRadius: "2rem",
      }}
      {...props}
    >
      Post New Job
    </Button>
  );
};
