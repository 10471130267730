import { useEffect, useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import { icons } from "../icons";

export const SortMenu = ({ navigateUrl }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    if (searchParams.has("sortBy")) {
      setSortBy(searchParams.get("sortBy"));
    }
  }, [searchParams]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortByDate = () => {
    navigate(`${navigateUrl}&sortBy=date&page=1`);
    handleClose();
  };

  const handleSortByAwaitingReview = () => {
    navigate(`${navigateUrl}&sortBy=awaitingReview&page=1`);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{
          alignSelf: "center",
          padding: "0.2rem 0.2rem 0.2rem 0.6rem",
          width: "8rem",
          borderRadius: "0.6rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "flex",
          alignItems: "center",
          height: "2rem",
          mt: { xs: "0", md: "1rem" },
        }}
        onClick={handleClick}
      >
        {sortBy === "awaitingReview" ? "Awaiting Review" : "Date"}
        {icons.arrowUp({ sx: { transform: "rotate(180deg)" } })}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleSortByDate} selected={sortBy === "date"}>
          Sort by Date
        </MenuItem>
        <MenuItem
          onClick={handleSortByAwaitingReview}
          selected={sortBy === "awaitingReview"}
        >
          Sort by Awaiting Review
        </MenuItem>
      </Menu>
    </>
  );
};
