import axios from "axios";
import { handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const fetchAirports = ({ search }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        "https://static-resources.herokuapp.com/airports",
        {
          params: { search, limit: 100 },
        }
      );
      return handleAxiosResponse(response).data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
