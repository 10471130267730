import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";
import { actions } from "../slice";

export const fetchBillingInformation = () => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.get(`/billing/${companyProfile.stripeCustomerId}/address`);

      const { data: billingAddress } = handleAxiosResponse(response);

      dispatch(actions.setBillingAddress({ billingAddress }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
