import { Box } from "@mui/material";
import { Modal } from "../../../components/backdrop-modal";
import { Typography, Button } from "@mui/material";

export const StatusWarningModal = ({ open, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal headerTitle="Please note" open={open} onClose={handleClose}>
      <Box>
        <Typography variant="body1" sx={{ color: "#000000" }}>
          You can't change the status of a candidate to backwards.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{ marginRight: "1rem" }}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
