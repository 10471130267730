import { Divider, Typography } from "@mui/material";
import { ElevatedCard } from "../../../components/elevated-card";
import { TextInput } from "../../../components/inputs/text";
import { useState, useEffect } from "react";
import { CandidatesFeature } from "../../../features/candidates";
import { useDispatch } from "react-redux";
import debounce from "lodash.debounce";

export const CandidateNotes = ({ candidate }) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState(candidate.notes || "");

  const handleSave = debounce(async (newNotes) => {
    await CandidatesFeature.addCandidateNotes({
      candidateId: candidate.id,
      notes: newNotes,
    })(dispatch);
  }, 900); // ms

  const handleInputChange = (e) => {
    setNotes(e.target.value);
  };

  useEffect(() => {
    handleSave(notes);
    return () => {
      handleSave.cancel();
    };
  }, [notes]);

  return (
    <ElevatedCard
      sx={{
        flex: 0.8,
        p: { xs: "1rem", md: "1.6rem" },
      }}
    >
      <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
        Notes
      </Typography>
      <Divider sx={{ mt: { xs: "1rem", md: "1.6rem" }, mb: "1rem" }} />
      <TextInput
        name="notes"
        type="text"
        value={notes}
        onChange={handleInputChange}
        multiline
        rows={10}
        placeholder={
          "These notes are only visible to you. And it is separate for each candidate."
        }
      />
    </ElevatedCard>
  );
};
