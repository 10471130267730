import React, { useCallback, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { AppStoreProvider } from "./store";
import { AuthStateListener } from "./hoc/auth-state-listener";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { AppRoutes } from "./navigations/app-routes";
import { AppThemeProvider } from "./styles/theme";
import { SocketProvider, useSocket } from "./hooks/use-socket";
import { SnackbarMessagesListener } from "./hoc/snackbar-listener";
import { useDispatch } from "react-redux";
import { actions } from "./features/conversations/slice";
import { ConversationsFeature } from "./features/conversations";

function App() {
  return (
    <BrowserRouter>
      <AppStoreProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppThemeProvider>
            <SnackbarProvider maxSnack={2} autoHideDuration={2000}>
              <SnackbarMessagesListener>
                <AuthStateListener>
                  <SocketProvider>
                    <SocketMessagesListener>
                      <AppRoutes />
                    </SocketMessagesListener>
                  </SocketProvider>
                </AuthStateListener>
              </SnackbarMessagesListener>
            </SnackbarProvider>
          </AppThemeProvider>
        </LocalizationProvider>
      </AppStoreProvider>
    </BrowserRouter>
  );
}

const SocketMessagesListener = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      ConversationsFeature.fetchNewMessagesCount()(dispatch);
    }
  }, [socket, dispatch]);

  const handleMessageReceived = useCallback(
    (data) => {
      const newMessage = {
        conversationId: data.conversation,
        message: {
          id: data.id,
          conversation: data.conversation,
          sender: data.sender,
          recipient: data.recipient,
          isRead: data.isRead,
          message: data.message,
          createdAt: data.createdAt,
        },
      };

      dispatch(actions.updateConversationLatestMessage(newMessage));

      if (location.pathname.includes("/conversations/view")) {
        dispatch(actions.addMessage(newMessage));
      } else {
        dispatch(actions.updateNewMessagesCount(1));
      }
    },
    [location, dispatch]
  );

  useEffect(() => {
    if (socket) {
      socket.on("getMessage", handleMessageReceived);
    }
  }, [socket, handleMessageReceived]);

  return children;
};

export default App;
