import React from "react";
import Drawer from "@mui/material/Drawer";
import { useUserActivityHandler } from "../../hooks/use-user-activity-handler";
import { useWindowSize } from "../../hooks/use-window-size";
import { SideBarContent } from "./content";

const AppSideBar = ({ drawerOpen, toggleDrawer }) => {
  useUserActivityHandler();
  const size = useWindowSize();

  return (
    <Drawer
      open={drawerOpen}
      onClose={toggleDrawer}
      variant={size.width > 900 ? "permanent" : "temporary"}
      anchor="left"
      PaperProps={{
        sx: {
          border: "none",
          width: { xs: "80%", md: "30%" },
          maxWidth: "22rem",
          backgroundImage: "linear-gradient(to right, #A060D0, #C46565)",
        },
      }}
      sx={{ height: "100vh", width: { xs: "60%", md: "30%" }, maxWidth: "22rem" }}
    >
      <SideBarContent />
    </Drawer>
  );
};

const MemoizedAppSideBar = React.memo(AppSideBar);

export { MemoizedAppSideBar as AppSideBar };
