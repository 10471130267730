import { startNewConversation } from "./use-cases/start-new-conversation";
import { fetchAllConversations } from "./use-cases/fetch-all-conversations";
import { fetchConversationInfo } from "./use-cases/fetch-conversation-info";
import { archiveConversation } from "./use-cases/archive-conversation";
import { unarchiveConversation } from "./use-cases/unarchive-conversation";
import { fetchNewMessagesCount } from "./use-cases/fetch-new-messages-count";
import { fetchMessages } from "./use-cases/fetch-conversation-messages";
import { sendMessage } from "./use-cases/send-message";
import { markMessageAsRead } from "./use-cases/mark-message-as-read";

class Feature {
  fetchAllConversations = fetchAllConversations;

  fetchConversationInfo = fetchConversationInfo;

  startNewConversation = startNewConversation;

  archiveConversation = archiveConversation;

  unarchiveConversation = unarchiveConversation;

  fetchNewMessagesCount = fetchNewMessagesCount;

  fetchMessages = fetchMessages;

  sendMessage = sendMessage;

  markMessageAsRead = markMessageAsRead;
}

export const ConversationsFeature = new Feature();
