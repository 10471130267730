import { initializeAccount, reInitialize } from "./use-cases/initialize";
import { signIn } from "./use-cases/sign-in";
import { signUp } from "./use-cases/sign-up";
import { signOut } from "./use-cases/sign-out";
import { verifyAccount } from "./use-cases/verify-account";
import { sendOTPVerificationEmail } from "./use-cases/send-verification-email";
import { sendResetPasswordLink } from "./use-cases/send-reset-password-link";
import { verifyResetPasswordToken } from "./use-cases/verify-reset-password-token";
import { resetPassword } from "./use-cases/reset-password";
import { changePassword } from "./use-cases/change-password";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  initializeAccount = initializeAccount;

  reInitialize = reInitialize;

  signIn = signIn;

  signUp = signUp;

  signOut = signOut;

  verifyAccount = verifyAccount;

  sendOTPVerificationEmail = sendOTPVerificationEmail;

  sendResetPasswordLink = sendResetPasswordLink;

  verifyResetPasswordToken = verifyResetPasswordToken;

  resetPassword = resetPassword;

  changePassword = changePassword;
}

export const AuthenticationFeature = new Feature();
