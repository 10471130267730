import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingButton = ({ children, onClick, variant, isLoading = false, fullWidth = false, ...rest }) => {
  return (
    <Button onClick={onClick} variant={variant} disabled={isLoading} fullWidth={fullWidth} {...rest}>
      {children}
      {isLoading && <CircularProgress size="1rem" sx={{ ml: "0.6rem", color: "grey" }} />}
    </Button>
  );
};
