import { useLocation, useNavigate as useRouterNaviagte } from "react-router-dom";

export const useNavigate = (path, options = { replace: false }) => {
  const navigate = useRouterNaviagte();

  const { pathname } = useLocation();

  if (pathname === path) {
    return () => {};
  }

  return () => navigate(path, options);
};
