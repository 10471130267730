import { fetchAircraftTypes } from "./use-cases/fetch-aircraft-types";
import { fetchAirports } from "./use-cases/fetch-airports";
import { fetchLicenseTypes } from "./use-cases/fetch-license-types";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchAircraftTypes = fetchAircraftTypes;

  fetchAirports = fetchAirports;

  fetchLicenseTypes = fetchLicenseTypes;
}

export const AppConfigsFeature = new Feature();
