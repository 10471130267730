export const licenses = [
  "United States - FAA",
  "Afghanistan - MOTCA",
  "Albania - ACAA",
  "Algeria - DACM",
  "American Samoa -",
  "Angola - INAVIC",
  "Anguilla -",
  "Antigua & Barbuda -",
  "Argentina - ANAC",
  "Armenia - GDCA",
  "Australia - CASA",
  "Azerbaijan -",
  "Bahrain - CAA",
  "Belize -",
  "Benin - ANAC",
  "Bolivia - DGAC",
  "Bosnia and Herzegovina - BHDCA",
  "Brazil - ANAC",
  "British Virgin Islands - CAA",
  "Brunei Darussalam - DCAB",
  "Bulgaria - CAA",
  "Burkina Faso -",
  "Burundi -",
  "Cambodia - SSCA",
  "Cayman Islands - CAA",
  "Central African Republic -",
  "Chile - DGAC",
  "China - CAAC",
  "China- Hong Kong - CID",
  "China, Macao SAR -",
  "Christmas Island -",
  "Colombia - AEROCIVIL",
  "Cook Islands - PASO",
  "Costa Rica - DGAC",
  "Cote dIvoire -",
  "Croatia - CCAA - EASA",
  "Cuba - IACC",
  "Czech Republic - CAA CZ",
  "Denmark - Trafikstyrelsen",
  "Djibouti -",
  "Dominica -",
  "Dominican Republic - IDAC",
  "East Timor -",
  "Equatorial Guinea -",
  "Eritrea -",
  "Estonia - ECAA",
  "Ethiopia -",
  "Falkland Islands -",
  "Faroe Islands -",
  "Fiji - CAAFI",
  "Finland - TRAFI",
  "French Guiana -",
  "French Polynesia -",
  "Gambia - GCAA",
  "Georgia - GCAA",
  "Gibraltar -",
  "Guinea -",
  "Guinea-Bissau -",
  "Haiti -",
  "Iceland - ITA",
  "India - DGCA",
  "Indonesia - DGCA",
  "Iran, Islamic Rep. - CAO",
  "Iraq - ICAA",
  "Ireland - IAA",
  "Isle of Man - IMAR",
  "Israel - CAA",
  "Italy - ENAC",
  "Jamaica - JCAA",
  "Kiribati - PASO",
  "Kuwait - DGCA",
  "Latvia - CAA",
  "Liberia -",
  "Libya - LYCAA",
  "Liechtenstein -",
  "Lithuania - CAA",
  "Macedonia, FYR - DGCA",
  "Malawi - DCAM",
  "Malaysia - DCA",
  "Maldives -",
  "Mali -",
  "Marshall Islands - RMIPA",
  "Martinique -",
  "Mauritania -",
  "Mauritius - DCA",
  "Mexico - DGCAM",
  "Micronesia, Fed. States -",
  "Mongolia - CAAM",
  "Mozambique - IACM",
  "Namibia - DCA",
  "Netherlands - ILT",
  "Netherlands Antilles -",
  "New Caledonia -",
  "Nicaragua - INAC",
  "Niger -",
  "Nigeria - NCAA",
  "Niue - PASO",
  "Northern Mariana Islands -",
  "Pakistan - CAA",
  "Papua New Guinea - CASA",
  "Paraguay - DINAC",
  "Philippines - CAAP",
  "Pitcairn -",
  "Portugal - ANAC",
  "Puerto Rico -",
  "Reunion -",
  "Romania - CAA",
  "Russian Federation - RFCAA",
  "Saint Helena -",
  "Saint Kitts & Nevis -",
  "Saint Lucia -",
  "Saint Pierre & Miquelon -",
  "Saint Vincent & Grenadines -",
  "San Marino - CAA",
  "Sao Tome & Principe -",
  "Saudi Arabia - GACA",
  "Serbia - CAD",
  "Sierra Leone -",
  "Singapore - CAAS",
  "Slovakia - CAAS",
  "Slovenia - CAA SI",
  "Solomon Islands - PASO",
  "Somalia - SCAMA",
  "South Africa - SACAA",
  "Spain - AESA",
  "Sri Lanka - CAA",
  "Suriname - CADSUR",
  "Swaziland -",
  "Switzerland - FOCA",
  "Syrian Arab Republic - SCAA",
  "Tajikistan -",
  "Tanzania, United Rep. - TCAA",
  "Thailand - CAAT",
  "United Kingdom - CAA",
  "ALL - ICAO",
  "Austria - EASA",
  "France - DGCA",
  "Belgium - EASA",
  "Canada - TC",
  "United Arab Emirates - GCAA",
  "Cyprus - DCA",
  "Germany - LBA",
  "Greece - HCAA",
  "Hungary - MND-AA",
  "Luxembourg - DAC",
  "Malta - TM CAD",
  "Norway - NCAA",
  "Poland - ULC",
  "Sweden - Transportstyrelsen",
  "Qatar-QCAA",
  "United Kingdom - EASA",
  "Netherlands - EASA",
  "Aruba - CAA",
  "Bangladesh - CAAB",
  "Turkish - CAA",
];
