import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ElevatedCard } from "../../../components/elevated-card";
import { CandidatesFeature } from "../../../features/candidates";
import { icons } from "../../../icons";
import { ReviewCandidateModal } from "./candidate-review-modal";
import moment from "moment";
import { StatusWarningModal } from "./status-warning-modal";

export const CandidateStatusSelect = ({ candidate, changeReviews }) => {
  return (
    <ElevatedCard
      sx={{
        flex: 0.8,
        p: { xs: "1rem", md: "1.6rem" },
      }}
    >
      <UpdateStatusMenu
        candidateId={candidate.id}
        status={candidate.status}
        changeReviews={changeReviews}
      />
      <Divider sx={{ mt: { xs: "1rem", md: "1.6rem" }, mb: "1rem" }} />
      {candidate.reviews?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "16rem",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-thumb": {
              width: "0.4rem",
              borderRadius: "0.2rem",
              backgroundColor: "#ccc",
            },
            backgroundColor: "#f4f4fb",
            p: "0.44rem 0.88rem",
            borderRadius: "0.4rem",
          }}
        >
          {candidate.reviews.map((review, i) => (
            <Box
              key={review._id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                mt: "0.5rem",
              }}
            >
              {i > 0 && <Divider sx={{ width: "100%", mb: "0.3rem" }} />}

              <Typography sx={{ fontWeight: "bold" }}>
                {review.note === "undefined" || !review.note
                  ? "No review note"
                  : review.note}
              </Typography>
              <Typography
                sx={{ fontSize: "0.8rem", textTransform: "capitalize" }}
              >
                {review.from} <span>&nbsp; &nbsp; &rarr; &nbsp; &nbsp;</span>{" "}
                {review.to}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontSize: "0.8rem" }}>
                  Recruiter: {review.recruiter_name}
                </Typography>
                <Typography sx={{ fontSize: "0.8rem" }}>
                  {/* date and time */}
                  {moment(review.date).format("DD MMM YYYY, h:mm a")}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {candidate.reviews?.length === 0 && (
        <Typography sx={{ fontSize: "0.8rem" }}>
          Your review message to candidate
        </Typography>
      )}
    </ElevatedCard>
  );
};

export const UpdateStatusMenu = ({
  candidateId,
  candidateIds,
  status,
  changeReviews,
  multiCandidate,
  fetchCandidates,
  setProcessingCandidates,
  sx,
}) => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const [prevStatus, setPrevStatus] = useState(status);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [submitting, setSubmitting] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const onSelect = async (newStatus) => {
    setOpen(false);

    if (statuses.indexOf(newStatus) < statuses.indexOf(currentStatus)) {
      setOpenWarningModal(true);
      return;
    }

    setSubmitting(true);
    setPrevStatus(currentStatus);
    setCurrentStatus(newStatus);

    setReviewModal(true);
  };

  const handleReviewModalClose = () => {
    setSubmitting(false);
    setCurrentStatus(prevStatus);
    setReviewModal(false);
  };

  const handleComplete = async (reviewNote) => {
    setReviewModal(false);
    if (multiCandidate) {
      setProcessingCandidates(true);
      const res = await CandidatesFeature.reviewMultiCandidates({
        candidateIds,
        status: currentStatus,
        reviewNote,
      })(dispatch);

      if (!res) {
        setCurrentStatus(prevStatus);
      } else {
        setCurrentStatus(prevStatus);
        fetchCandidates(1);
        setProcessingCandidates(false);
      }
    } else {
      const { status: udpatedStatus, reviews } =
        await CandidatesFeature.reviewCandidate({
          candidateId,
          status: currentStatus,
          reviewNote,
        })(dispatch);

      if (!udpatedStatus) {
        setCurrentStatus(prevStatus);
      } else {
        setCurrentStatus(udpatedStatus);
        changeReviews(reviews);
      }
    }

    setSubmitting(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{
          padding: "0.4rem 1rem",
          minHeight: "2.6rem",
          borderRadius: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
          textTransform: "capitalize",
          fontSize: "1rem",
          ...sx,
        }}
        ref={anchorRef}
        fullWidth
        disabled={candidateIds?.length === 0 || candidateId === null}
      >
        {submitting ? <CircularProgress size="1.2rem" /> : currentStatus}
        <Box
          sx={{
            display: "flex",
            ml: "0.6rem",
          }}
        >
          {open ? icons.expandLess({}) : icons.expandMore({})}
        </Box>
      </Button>
      <ReviewCandidateModal
        open={reviewModal}
        onClose={handleReviewModalClose}
        // initialValue={reviews}
        onComplete={handleComplete}
      />
      <StatusWarningModal
        open={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
      />
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 3000 }}
        MenuListProps={{ sx: { padding: "0rem" } }}
        PaperProps={{
          elevation: 6,
          style: {
            borderRadius: "0.4rem",
            maxHeight: "16rem",
            width: anchorRef && anchorRef.current?.offsetWidth,
          },
        }}
      >
        {statuses.map((statusItem) => (
          <MenuItem
            key={statusItem}
            onClick={() => onSelect(statusItem)}
            sx={{
              padding: "0.8rem",
              textTransform: "capitalize",
              whiteSpace: "break-spaces",
              wordBreak: "break-word",
            }}
          >
            {statusItem}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const statuses = [
  "awaiting review",
  "reviewed",
  "shortlisted",
  "interviewing",
  "rejected",
  "hired",
];
