import { actions } from "../slice";
import { handleError } from "../../app-messages";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";

export const updateCurrentPlan = ({ plan }) => {
  return (dispatch) => {
    try {
      const localCompanyProfile = appLocalStorage.get(lskeys.companyProfile);

      const updatedCompanyProfile = {
        ...localCompanyProfile,
        currentPlan: plan,
      };

      appLocalStorage.save({ [lskeys.companyProfile]: updatedCompanyProfile });

      dispatch(
        actions.changeCurrentPlan({
          currentPlan: updatedCompanyProfile.currentPlan,
        })
      );
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
