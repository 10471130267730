import { useState } from "react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Modal } from "../backdrop-modal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export const GeneratePaymentMethodIdModal = ({ modalTitle = "Add New Card", open, onClose, onComplete = null }) => {
  const [submitting, setSubmitting] = useState(false);

  const handleComplete = async (paymentMethodId) => {
    onClose();
    if (onComplete !== null) {
      await onComplete(paymentMethodId);
    }
  };

  return (
    <Modal headerTitle={modalTitle} open={open} onClose={onClose}>
      <Elements stripe={stripePromise}>
        <GeneratePaymentMethodId onSubmit={handleComplete} submitting={submitting} setSubmitting={setSubmitting} />
      </Elements>
    </Modal>
  );
};

const GeneratePaymentMethodId = ({ onSubmit, submitting, setSubmitting }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleClearCard = () => {
    elements.getElement(CardElement).clear();
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    setSubmitting(false);

    if (error) {
      // handle the error if needed
    }

    elements.getElement(CardElement).clear();

    await onSubmit(paymentMethod.id);
  };

  return (
    <Box>
      <Box sx={{ my: "1.2rem" }}>
        <CardElement
          options={{
            style: {
              base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": { color: "#aab7c4" },
              },
              invalid: { color: "#fa755a", iconColor: "#fa755a" },
            },
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          sx={{ padding: "0.2rem 0.8rem", borderRadius: "0.4rem" }}
          onClick={handleClearCard}
          disabled={submitting}
          fullWidth
        >
          Clear Card
        </Button>
        <Box width="2rem" />
        <Button
          sx={{ padding: "0.2rem 0.8rem", borderRadius: "0.4rem" }}
          onClick={handleSubmit}
          disabled={submitting}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
