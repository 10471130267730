import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CompanyProfileFeature } from "../../../features/company-profile";
import { Modal } from "../../../components/backdrop-modal";
import { CustomTextField } from "../../../components/inputs";
import { LoadingButton } from "../../../components/buttons/loading-button";
import { icons } from "../../../icons";

const EditCompanyProfileModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { name, about, website, linkedIn, twitter } = useSelector((state) => state.companyProfile);

  const handleSubmit = async (profileDetails, helpers) => {
    await CompanyProfileFeature.updateProfile({ profileDetails })(dispatch);

    helpers.resetForm();

    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name ?? "",
      about: about ?? "",
      website: website ?? "",
      linkedIn: linkedIn ?? "",
      twitter: twitter ?? "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Required"),
      about: yup.string().required("Required"),
      website: yup.string(),
      linkedIn: yup.string(),
      twitter: yup.string(),
    }),
    onSubmit: handleSubmit,
  });

  const modalHeaderActions = (
    <Box>
      <IconButton onClick={onClose} sx={{ padding: "0.6rem" }}>
        {icons.close({})}
      </IconButton>
    </Box>
  );

  const modalActions = (
    <Box>
      <LoadingButton variant="outlined" onClick={formik.submitForm} isLoading={formik.isSubmitting} fullWidth>
        {formik.isSubmitting ? "Saving" : "Save"}
      </LoadingButton>
    </Box>
  );

  return (
    <Modal
      headerTitle="Edit Company Profile"
      headerActions={modalHeaderActions}
      open={open}
      onClose={onClose}
      modalActions={modalActions}
    >
      <Box component="form" noValidate>
        <CustomTextField
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          placeholder="Please enter your company name"
        />
        <CustomTextField
          label="About"
          name="about"
          value={formik.values.about}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.about && Boolean(formik.errors.about)}
          helperText={formik.touched.about && formik.errors.about}
          placeholder="Please write something about your company"
          multiline
          rows={3}
        />
        <CustomTextField
          label="Website URL"
          name="website"
          value={formik.values.website}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.website && Boolean(formik.errors.website)}
          helperText={formik.touched.website && formik.errors.website}
          placeholder=" eg: 'www.example.com'"
          startAdornment={
            <InputAdornment position="start" sx={{ mr: "0rem" }}>
              https://
            </InputAdornment>
          }
        />
        <CustomTextField
          label="LinkedIn Profile URL"
          name="linkedIn"
          value={formik.values.linkedIn}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.linkedIn && Boolean(formik.errors.linkedIn)}
          helperText={formik.touched.linkedIn && formik.errors.linkedIn}
          placeholder=" Please enter your linkedIn profile id"
          startAdornment={
            <InputAdornment position="start" sx={{ mr: "0rem" }}>
              https://www.linkedin.com/in/
            </InputAdornment>
          }
        />
        <CustomTextField
          label="Twitter Profile URL"
          name="twitter"
          value={formik.values.twitter}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.twitter && Boolean(formik.errors.twitter)}
          helperText={formik.touched.twitter && formik.errors.twitter}
          placeholder=" Please enter your twitter profile name"
          startAdornment={
            <InputAdornment position="start" sx={{ mr: "0rem" }}>
              https://twitter.com/
            </InputAdornment>
          }
        />
      </Box>
    </Modal>
  );
};

const MemoizedEditCompanyProfileModal = React.memo(EditCompanyProfileModal);

export { MemoizedEditCompanyProfileModal as EditCompanyProfileModal };
