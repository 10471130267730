import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { icons } from "../../icons";

export const Socials = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {socials.map((link) => (
        <IconButton key={link.name} onClick={() => window.open(link.href)}>
          {link.icon}
        </IconButton>
      ))}
    </Box>
  );
};

const socials = [
  { name: "LinkedIn", href: "https://www.linkedin.com/company/aircrewconnect", icon: icons.linkedin({}) },
  { name: "Facebook", href: "https://www.facebook.com/aircrewconnect", icon: icons.facebook({}) },
  { name: "Twitter", href: "https://twitter.com/aircrewconnect", icon: icons.twitter({}) },
  { name: "Instagram", href: "https://instagram.com/aircrewconnect", icon: icons.instagram({}) },
];
