import { Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { getAppStoreCurrentState } from "../../../../store";
import { AuthenticationFeature } from "../../../../features/authentication";
import { CustomTextField } from "../../../../components/inputs";
import { AppRouteConstants } from "../../../../navigations/routes";
import { useState } from "react";
import { Modal } from "../../../../components/backdrop-modal";
import { CircularLoader } from "../../../../components/loaders/circular-loader";

export const SignInForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formToSubmit, setFormToSubmit] = useState(null);
  const [unblacklist, setUnblacklist] = useState(false);
  const [openBlacklistModal, setOpenBlacklistModal] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = async ({ email, password }) => {
    const response = await AuthenticationFeature.signIn({
      email,
      password,
      unblacklist,
    })(dispatch);

    if (response && response === "your account is blacklisted") {
      setFormToSubmit({ email, password });
      setOpenBlacklistModal(true);
      return;
    }

    const { verified } = response;

    if (getAppStoreCurrentState().authentication.isAuthenticated && !verified) {
      return navigate(AppRouteConstants.verifyAccount, { replace: true });
    }

    if (getAppStoreCurrentState().authentication.isAuthenticated && verified) {
      navigate(AppRouteConstants.jobs, { replace: true });
    }
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Must be a valid email")
        .max(255)
        .required("email field cannot be empty"),
      password: yup
        .string()
        .min(8, "Password must be minimum 8 character long")
        .max(255)
        .required("password field cannot be empty"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography variant="h4" sx={{ mb: "0.4rem" }}>
          Welcome Back!
        </Typography>
        <Typography variant="subtitle1">Sign in to your account</Typography>
      </Box>

      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box>
          <CustomTextField
            label="Email Address"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            placeholder="Please enter the email address"
          />
          <CustomTextField
            label="Password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            placeholder="Please enter the password"
          />
        </Box>

        <Box
          sx={{
            my: "0.6rem",
            mr: "0.6rem",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <ForgotPasswordLink navigate={navigate} />
        </Box>

        <Box sx={{ height: "1rem" }} />

        <Button
          disabled={formik.isSubmitting}
          type="submit"
          variant="contained"
          sx={{
            p: "0.6rem",
            cursor: formik.isSubmitting ? "not-allowed" : "pointer",
          }}
        >
          {formik.isSubmitting ? (
            <CircularLoader size="1.6rem" color="primary" />
          ) : (
            "Sign In"
          )}
        </Button>
      </Box>

      <Typography variant="subtitle1" textAlign="center" sx={{ my: "1.6rem" }}>
        Don&apos;t have an account ? <SignUpLink navigate={navigate} />
      </Typography>
      <BlacklistModal
        open={openBlacklistModal}
        onClose={() => {
          setOpenBlacklistModal(false);
          setUnblacklist(false);
        }}
        formToSubmit={formToSubmit}
        setWaiting={setWaiting}
        waiting={waiting}
        setUnblacklist={setUnblacklist}
      />
    </Box>
  );
};

const ForgotPasswordLink = ({ navigate }) => {
  const onClick = () => {
    navigate("/authentication?action=forgot-password");
  };

  return (
    <Typography
      onClick={onClick}
      sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
    >
      Forgot Password
    </Typography>
  );
};

const SignUpLink = ({ navigate }) => {
  const onClick = () => {
    navigate("/authentication?action=sign-up", { replace: true });
  };

  return (
    <Box
      component="span"
      onClick={onClick}
      sx={{
        ml: "0.4rem",
        fontWeight: "bold",
        cursor: "pointer",
        ":hover": { textDecoration: "underline" },
      }}
    >
      Sign Up
    </Box>
  );
};

const BlacklistModal = ({
  open,
  onClose,
  formToSubmit,
  setWaiting,
  waiting,
  setUnblacklist,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Modal
      headerTitle={"Welcome Back!"}
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={false}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1">
          We are happy to see you again! Your account was setup for the
          deletion, but you can get back in by clicking the button below.
        </Typography>
        <Box sx={{ height: "1rem" }} />
        <Button
          variant="contained"
          sx={{ p: "0.6rem" }}
          onClick={async () => {
            setWaiting(true);
            setUnblacklist(true);

            // we are making the sign in request again with unblacklist set to true
            await AuthenticationFeature.signIn({
              ...formToSubmit,
              unblacklist: true,
            })(dispatch);

            if (getAppStoreCurrentState().authentication.isAuthenticated) {
              navigate("/", { replace: true });
            }
            setWaiting(false);
          }}
          disabled={waiting}
        >
          Sign In
        </Button>
      </Box>
    </Modal>
  );
};
