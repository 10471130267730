import { Box, Button } from "@mui/material";
import { useState } from "react";
import { Modal } from "../../../components/backdrop-modal";
import { TextInput } from "../../../components/inputs/text";

export const ReviewCandidateModal = ({ open, onClose, onComplete }) => {
  const [review, setReview] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onComplete(review);
    setReview("");
  };

  return (
    <Modal
      headerTitle="Review Your Candidate"
      open={open}
      onClose={handleClose}
      modalActions={
        <Button
          variant="outlined"
          onClick={handleSubmit}
          sx={{
            borderRadius: "0.3rem",
          }}
          fullWidth
        >
          Complete
        </Button>
      }
    >
      <Box sx={{ mb: "-1rem" }}>
        <TextInput
          label="Review Note"
          value={review}
          onChange={(event) => setReview(event.target.value)}
          placeholder="Please leave a review note to your candidate"
          multiline
          rows={3}
        />
      </Box>
    </Modal>
  );
};
