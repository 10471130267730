import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
};

const reducers = {
  getEvents(state, action) {
    state.events = action.payload;
  },
};

export const slice = createSlice({
  name: "calendar",
  initialState,
  reducers,
});

export const { reducer, actions } = slice;
