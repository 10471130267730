import Box from "@mui/material/Box";

export const ElevatedCard = ({ children, sx, ...otherProps }) => {
  return (
    <Box
      sx={{
        padding: { xs: "1.2rem", md: "1.6rem" },
        border: "1px solid #EFEFEF",
        borderRadius: "1rem",
        boxShadow: "0px 0px 8px #EFEFEF",
        overflow: "hidden",
        ...sx,
        transition: "ease-in-out 300ms",
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};
