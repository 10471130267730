import { Box, Typography } from "@mui/material";
import moment from "moment";
import { icons } from "../../../../icons";

export const Visa = ({ visa, setOpenPdf }) => {
  return (
    <Box
      sx={{
        padding: "0.8rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "0.6rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
          {visa.country}
        </Typography>
        {visa && visa.name && (
          <Typography sx={{ fontSize: "0.8rem", color: "#6E7191" }}>
            {visa.name}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: "1.08rem",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            ml: "-0.4rem",
          }}
        >
          {moment(visa.expires_on).isBefore(moment()) ? (
            <>
              {icons.hourglassBottom({
                sx: { color: "red", fontSize: "1.2rem" },
              })}
              Expired on {moment(visa.expires_on).format("DD MMM, YYYY")}.
            </>
          ) : (
            <>
              {icons.hourglassTop({
                sx: { color: "green", fontSize: "1.2rem" },
              })}
              Expires on {moment(visa.expires_on).format("DD MMM, YYYY")}.
            </>
          )}
        </Typography>
        <Typography
          onClick={setOpenPdf}
          color="primary.main"
          sx={{
            width: "max-content",
            cursor: "pointer",
            fontSize: "0.8rem",
            ":hover": {
              textDecoration: "underline",
              textUnderlineOffset: "2px",
            },
          }}
        >
          View Visa
        </Typography>
      </Box>
    </Box>
  );
};
