import { initializeForm } from "./use-cases/initialize-form";
import { fetcExistingJob } from "./use-cases/fetch-and-fill-existing-job";
import { saveToDraft } from "./use-cases/save-to-draft";
import { clearDraftState } from "./use-cases/clear-form-state";
import { discardDraft } from "./use-cases/discard-draft-job";

class Feature {
  initializeForm = initializeForm;

  fetcExistingJob = fetcExistingJob;

  saveToDraft = saveToDraft;

  clearDraftState = clearDraftState;

  discardDraft = discardDraft;
}

export const PostJobFormFeature = new Feature();
