import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const CircularLoader = ({ sx, color, ...otherProps }) => {
  return (
    <Box sx={{ width: "100%", display: "grid", placeItems: "center", ...sx }}>
      <CircularProgress {...otherProps} sx={{ color }} />
    </Box>
  );
};
