import { Box } from "@mui/material";

export const TextFieldBackground = ({ children }) => {
  return (
    <Box
      sx={{
        padding: "0.44rem 0.88rem",
        borderRadius: "4px",
        backgroundColor: "#F4F4FB",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};
