import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { CompanyMembersFeature } from "../../features/company-members";
import { AuthenticationFeature } from "../../features/authentication";
import { ConfirmationModal } from "../modals/confirmation-modal";

export const TeamMember = ({ member }) => {
  const dispatch = useDispatch();

  const recruiterProfile = useSelector((state) => state.mainProfile);

  const [submitting, setSubmitting] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const isSelf = recruiterProfile.email === member.email;

  const handleRemoving = async () => {
    setSubmitting(true);
    await CompanyMembersFeature.removeFromCompany({
      memberEmail: member.email,
    })(dispatch);
    if (isSelf) {
      await AuthenticationFeature.signOut()(dispatch);
    }
    setOpenConfirmation(false);
    setSubmitting(false);
  };

  return (
    <Box
      sx={{
        mt: "1.6rem",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", alignSelf: "stretch" }}>
        <Box
          component="img"
          src={member.image}
          sx={{
            height: "4rem",
            width: "4rem",
            marginRight: "1.6rem",
            borderRadius: "1rem",
            boxShadow: "0px 0px 8px #EFEFEF",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {member.name}
          </Typography>
          <Typography variant="subtitle2">{member.email}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: { xs: "flex-end", sm: "center" },
          justifyContent: "space-between",
        }}
      >
        <Chip label={member.permission} sx={{ textTransform: "capitalize" }} />
        {member.permission !== "admin" && (
          <Button
            sx={{
              padding: "0.2rem",
              ml: "1rem",
              borderRadius: "2.5rem",
            }}
            onClick={() => setOpenConfirmation(true)}
            disabled={
              (recruiterProfile.permission === "member" && !isSelf) ||
              submitting
            }
          >
            {isSelf ? "Leave" : "Remove"}
          </Button>
        )}
      </Box>
      <ConfirmationModal
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirmation={handleRemoving}
        title={isSelf ? "Leave Company" : "Remove Member"}
        description={
          isSelf
            ? "Are you sure you want to leave this company?"
            : "Are you sure you want to remove this member?"
        }
        confirmText={isSelf ? "Leave" : "Remove"}
        isConfirming={submitting}
      />
    </Box>
  );
};
