import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useState } from "react";
import { PostJobFormFeature } from "../../../features/post-job-form";
import { ElevatedCard } from "../../../components/elevated-card";
import { Box, Button, CircularProgress } from "@mui/material";

import { licenses } from "../../../core/constants/licenses";
import { CustomTextField } from "../../../components/inputs";
import { DropdownSelect } from "../../../components/inputs/dropdown";
import { CustomSwitch } from "../../../components/inputs/switch";
import { LicensesSelect } from "../../../components/selects/licenses";
import { LanguagesSelect } from "../../../components/selects/languages";
import { CountrySelect } from "../../../components/selects/country";

export const RequirementsStep = ({ onNext, onBack }) => {
  const { formState } = useSelector((state) => state.postJobForm);

  if (formState.crewType.toLowerCase() === "cabin crew") {
    return <CabinCrewRequirements onNext={onNext} onBack={onBack} />;
  }

  if (formState.crewType.toLowerCase() === "flight crew") {
    return <FlightCrewRequirements onNext={onNext} onBack={onBack} />;
  }
};

export const CabinCrewRequirements = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const {
    formState: { requirements },
  } = useSelector((state) => state.postJobForm);
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      minimumYearsExperience: requirements?.minimumYearsExperience ?? 0,
      minimumCorportateYearsExperience: requirements?.minimumCorportateYearsExperience ?? 0,
      requiredTypeExperience: requirements?.requiredTypeExperience ?? "",
      requiresLicense: requirements?.requiresLicense ?? false,
      requiresVisa: requirements?.requriesVisa ?? false,
      requiredVisas: requirements?.requiredVisas ?? [],
      requiredLicenses: requirements?.requiredLicenses ?? [],
      languages: requirements?.languages ?? [],
    },

    validationSchema: yup.object({
      minimumYearsExperience: yup.number().min(0, "Enter a positive number").required("Required"),
      minimumCorportateYearsExperience: yup
        .number()
        .min(0, "Enter a positive number")
        .max(yup.ref("minimumYearsExperience"), "Must be less than or equal to minimumYearsExperience")
        .required("Required"),
      requiredTypeExperience: yup.string(),
      requiresLicense: yup.boolean(),
      requiredLicenses: yup.array(),
      requiresVisa: yup.boolean(),
      languages: yup.array(),
    }),

    onSubmit: async (cabinCrewRequirementsInfo) => {
      setSaving(true);
      await PostJobFormFeature.saveToDraft({ jobInfo: { requirements: cabinCrewRequirementsInfo } })(dispatch);
      setSaving(false);
      onNext();
    },
  });

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          padding: { xs: "0rem 1.2rem", sm: "0.6rem 2rem 2rem 2rem" },
          backgroundColor: "#FFFAFA",
        }}
      >
        <DropdownSelect
          label="Type Experience Required"
          name="requiredTypeExperience"
          options={["Airline", "Corporate"]}
          value={formik.values.requiredTypeExperience}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.requiredTypeExperience && formik.errors.requiredTypeExperience)}
          helperText={formik.touched.requiredTypeExperience && formik.errors.requiredTypeExperience}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column" },
          }}
        >
          <CustomTextField
            label="Minimum Years Experience"
            name="minimumYearsExperience"
            type="number"
            value={formik.values.minimumYearsExperience}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.minimumYearsExperience && formik.errors.minimumYearsExperience)}
            helperText={formik.touched.minimumYearsExperience && formik.errors.minimumYearsExperience}
            placeholder="Total Airline & Corporate Experience"
          />
          <Box sx={{ width: "2rem" }} />
          <CustomTextField
            label="Minimum Corporate Years Experience"
            name="minimumCorportateYearsExperience"
            type="number"
            value={formik.values.minimumCorportateYearsExperience}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.minimumCorportateYearsExperience && formik.errors.minimumCorportateYearsExperience
            )}
            helperText={
              formik.touched.minimumCorportateYearsExperience && formik.errors.minimumCorportateYearsExperience
            }
            placeholder="Experience Years"
          />
        </Box>
        <CustomSwitch
          label="License Required"
          checked={formik.values.requiresLicense}
          onChange={(checked) => formik.setFieldValue("requiresLicense", checked)}
        />
        {formik.values.requiresLicense && (
          <LicensesSelect
            name="requiredLicenses"
            value={formik.values.requiredLicenses}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.requiredLicenses && formik.errors.requiredLicenses)}
            helperText={formik.touched.requiredLicenses && formik.errors.requiredLicenses}
            multiple
          />
        )}
        <CustomSwitch
          label="VISA Required"
          checked={formik.values.requiresVisa}
          onChange={(checked) => formik.setFieldValue("requiresVisa", checked)}
        />
        {formik.values.requiresVisa && (
          <CountrySelect
            // label="VISA Required"
            name="requiredVisas"
            value={formik.values.requiredVisas}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.requiredVisas && formik.errors.requiredVisas)}
            helperText={formik.touched.requiredVisas && formik.errors.requiredVisas}
            multiple
          />
        )}
        <LanguagesSelect
          label="Languages"
          name="languages"
          value={formik.values.languages}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.languages && formik.errors.languages)}
          helperText={formik.touched.languages && formik.errors.languages}
        />
        <Box sx={{ height: { xs: "2rem", sm: "0rem" } }} />
      </ElevatedCard>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onBack} sx={{ borderRadius: "1rem" }}>
          Back
        </Button>
        <Box sx={{ width: "2rem" }} />
        <Button onClick={formik.submitForm} sx={{ borderRadius: "1rem" }} disabled={saving}>
          {saving ? <CircularProgress size="1.4rem" /> : "Continue"}
        </Button>
      </Box>
    </Fragment>
  );
};

export const FlightCrewRequirements = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const {
    formState: { requirements },
  } = useSelector((state) => state.postJobForm);
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      requiredLicenses: requirements?.requiredLicenses ?? [],
      minimumTotalhours: requirements?.minimumTotalhours ?? 0,
      minimumHoursOnType: requirements?.minimumHoursOnType ?? 0,
      minimumTotalPICHours: requirements?.minimumTotalPICHours ?? 0,
      minimumPICHours: requirements?.minimumPICHours ?? 0,
      requiresTypeRating: requirements?.requiresTypeRating ?? false,
      requiresVisa: requirements?.requiresVisa ?? false,
      requiredVisas: requirements?.requiredVisas ?? [],
      languages: requirements?.languages ?? [],
    },

    validationSchema: yup.object({
      requiredLicenses: yup.array().required("Required"),
      minimumTotalhours: yup.number().min(0, "Enter a positive number").required("Required"),
      minimumHoursOnType: yup
        .number()
        .min(0, "Enter a positive number")
        .max(yup.ref("minimumTotalhours"), "Must be less than or equal to minimumTotalhours")
        .required("Required"),
      minimumTotalPICHours: yup
        .number()
        .min(0, "Enter a positive number")
        .max(yup.ref("minimumTotalhours"), "Must be less than or equal to minimumTotalhours")
        .required("Required"),
      minimumPICHours: yup
        .number()
        .min(0, "Enter a positive number")
        .test({
          name: "max",
          message: "Must be less than or equal to both minimumTotalPICHours and minimumHoursOnType",
          test: function (value) {
            const { minimumTotalPICHours, minimumHoursOnType } = this.parent;
            return value <= Math.min(minimumTotalPICHours, minimumHoursOnType);
          },
        })
        .required("Required"),
      requiresTypeRating: yup.boolean(),
      requiresVisa: yup.boolean(),
      requiresMedicals: yup.boolean(),
    }),

    onSubmit: async (flightCrewRequirementsInfo) => {
      setSaving(true);
      await PostJobFormFeature.saveToDraft({ jobInfo: { requirements: flightCrewRequirementsInfo } })(dispatch);
      setSaving(false);
      onNext();
    },
  });

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          padding: { xs: "0rem 1.2rem", sm: "0.6rem 2rem 2rem 2rem" },
          backgroundColor: "#FFFAFA",
        }}
      >
        <LicensesSelect
          label="License Accepted"
          name="requiredLicenses"
          options={licenses}
          value={formik.values.requiredLicenses}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.requiredLicenses && formik.errors.requiredLicenses)}
          helperText={formik.touched.requiredLicenses && formik.errors.requiredLicenses}
          multiple
        />
        <Box sx={{ width: "100%", display: "flex" }}>
          <CustomTextField
            label="Minimum Total Hours"
            name="minimumTotalhours"
            type="number"
            value={formik.values.minimumTotalhours}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.minimumTotalhours && formik.errors.minimumTotalhours)}
            helperText={formik.touched.minimumTotalhours && formik.errors.minimumTotalhours}
            placeholder="Experience Hours"
          />
          <Box sx={{ width: "2rem" }} />
          <CustomTextField
            label="Minimum Hours On Type"
            name="minimumHoursOnType"
            type="number"
            value={formik.values.minimumHoursOnType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.minimumHoursOnType && formik.errors.minimumHoursOnType)}
            helperText={formik.touched.minimumHoursOnType && formik.errors.minimumHoursOnType}
            placeholder="Experience Hours"
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex" }}>
          <CustomTextField
            label="Minimum Total PIC Hours"
            name="minimumTotalPICHours"
            type="number"
            value={formik.values.minimumTotalPICHours}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.minimumTotalPICHours && formik.errors.minimumTotalPICHours)}
            helperText={formik.touched.minimumTotalPICHours && formik.errors.minimumTotalPICHours}
            placeholder="Experience PIC Hours"
          />
          <Box sx={{ width: "2rem" }} />
          <CustomTextField
            label="Minimum PIC Hours On Type"
            name="minimumPICHours"
            type="number"
            value={formik.values.minimumPICHours}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.minimumPICHours && formik.errors.minimumPICHours)}
            helperText={formik.touched.minimumPICHours && formik.errors.minimumPICHours}
            placeholder="Experience PIC Hours"
          />
        </Box>
        <Box sx={{ my: "-1.6rem" }}>
          <CustomSwitch
            label="Type Rating Required"
            checked={formik.values.requiresTypeRating}
            onChange={(checked) => formik.setFieldValue("requiresTypeRating", checked)}
          />
          <CustomSwitch
            label="VISA Required"
            checked={formik.values.requiresVisa}
            onChange={(checked) => formik.setFieldValue("requiresVisa", checked)}
          />
          {formik.values.requiresVisa && (
            <CountrySelect
              name="requiredVisas"
              value={formik.values.requiredVisas}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.requiredVisas && formik.errors.requiredVisas)}
              helperText={formik.touched.requiredVisas && formik.errors.requiredVisas}
              multiple
            />
          )}
          <LanguagesSelect
            label="Languages"
            name="languages"
            value={formik.values.languages}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.languages && formik.errors.languages)}
            helperText={formik.touched.languages && formik.errors.languages}
          />
        </Box>
        <Box sx={{ height: { xs: "2rem", sm: "0rem" } }} />
      </ElevatedCard>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onBack} sx={{ borderRadius: "1rem" }}>
          Back
        </Button>
        <Box sx={{ width: "2rem" }} />
        <Button onClick={formik.submitForm} sx={{ borderRadius: "1rem" }} disabled={saving}>
          {saving ? <CircularProgress size="1.4rem" /> : "Continue"}
        </Button>
      </Box>
    </Fragment>
  );
};
