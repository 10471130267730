import { acceptInviteAndJoinCompany } from "./use-cases/accept-invitation-join-company";
import { declineInvitation } from "./use-cases/decline-invitation";
import { fetchCompanyMembers } from "./use-cases/fetch-members";
import { fetchRecruiterInvitations } from "./use-cases/fetch-recruiter-invitations";
import { inviteMember } from "./use-cases/invite-member";
import { removeFromCompany } from "./use-cases/remove-member";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchCompanyMembers = fetchCompanyMembers;

  fetchRecruiterInvitations = fetchRecruiterInvitations;

  inviteMember = inviteMember;

  acceptInviteAndJoinCompany = acceptInviteAndJoinCompany;

  declineInvitation = declineInvitation;

  removeFromCompany = removeFromCompany;
}

export const CompanyMembersFeature = new Feature();
