import { Box, Typography } from "@mui/material";
import moment from "moment";

export const Experience = ({ experience }) => {
  return (
    <Box
      sx={{
        padding: "0.8rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "0.6rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "1.08rem",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          {experience.role} - {experience.sector}, {experience.job_type}.
        </Typography>
        <Typography sx={{ fontSize: "1rem", textTransform: "capitalize" }}>
          {experience.title ? experience.title : ""}
        </Typography>
        <Typography sx={{ fontSize: "1rem", textTransform: "capitalize" }}>
          {experience.company_name} - {experience.country}, {experience.city}.
        </Typography>
        <Typography variant="subtitle2">
          {moment(experience.start).format("MMMM YYYY")} -{" "}
          {experience.end !== null ? `${moment(experience.end).format("MMMM YYYY")}` : "Present"}
        </Typography>
      </Box>
    </Box>
  );
};
