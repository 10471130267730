import Box from "@mui/material/Box";

export const HeaderCell = ({ children, sx }) => {
  return <Box sx={{ height: "8rem", paddingX: "1.6rem", ...sx }}>{children}</Box>;
};

export const BodyCell = ({ children, sx }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "4rem",
        paddingX: "1rem",
        borderBottom: (theme) => {
          return `solid 1px ${theme.palette.divider}`;
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
