import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { Modal } from "../backdrop-modal";

const BillingSchemeToggle = ({ value, onChange }) => {
  const [showOfferAlertModal, setShowOfferAlertModal] = useState(false);

  const handleChange = (_, newScheme) => {
    if (newScheme === "monthly") {
      setShowOfferAlertModal(true);
    }

    onChange((prev) => newScheme ?? prev);
  };

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        size="small"
        exclusive
        value={value}
        onChange={handleChange}
        sx={{
          mb: "1.6rem",
          alignSelf: "flex-end",
          borderRadius: "1rem",
          boxShadow: "0px 0px 8px #EFEFEF",
          transition: "ease-in-out 300ms",
        }}
      >
        <ToggleButton
          value="monthly"
          sx={{
            p: "0.4rem 1rem",
            fontSize: "0.8rem",
            textTransform: "capitalize",
            border: "none",
            borderRadius: "1rem",
          }}
        >
          Monthly
        </ToggleButton>
        <ToggleButton
          value="yearly"
          sx={{
            p: "0.4rem 1rem",
            fontSize: "0.8rem",
            textTransform: "capitalize",
            border: "none",
            borderRadius: "1rem",
          }}
        >
          Yearly
        </ToggleButton>
      </ToggleButtonGroup>
      <OfferAlertModal open={showOfferAlertModal} onClose={() => setShowOfferAlertModal(false)} />
    </>
  );
};

const OfferAlertModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} headerTitle="Switching to monthly?">
      <Typography sx={{ mt: "1rem", mb: "0.2rem" }}>
        Did you know that signing up for an annual plan saves you 23% a year? <br /> A 23% saving off your annual
        subscription is a fantastic deal with more and more features added monthly! Pro is the way to go!
      </Typography>
    </Modal>
  );
};

const MemoizedBillingSchemeToggle = React.memo(BillingSchemeToggle);

export { MemoizedBillingSchemeToggle as BillingSchemeToggle };
