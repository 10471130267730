import { Box, IconButton, Button, CircularProgress, TextField } from "@mui/material";
import { icons } from "../../../icons";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { SupportFeature } from "../../../features/support";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const MessageInput = ({ setImageUploadModal, issueResolved, status, comments, setComments }) => {
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const messageRef = useRef(null);

  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const onChange = (event) => {
    // if the user has presed shift + enter, then add \n to the message
    if (event.keyCode === 13 && event.shiftKey) {
      setMessage(`${message}\n`);
      return;
    }
    setMessage(event.target.value);
  };

  const handleSend = async () => {
    const ticketId = query.get("ticketId");
    if (!message || sending || !ticketId) {
      return;
    }
    try {
      setSending(true);
      const comment = await SupportFeature.patchComment({
        comment: { message },
        supportId: ticketId,
      })(dispatch);
      if (comment) {
        setComments([...comments, comment]);
        setMessage("");
      }
      messageRef.current.focus();
    } catch (error) {}
    setSending(false);
  };

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.focus();
    }
  }, [message]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box />
        <IconButton
          sx={{
            alignSelf: "center",
            borderRadius: "2rem",
            height: "3rem",
            width: "3rem",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
            },
            position: "relative",
            right: "6rem",
            top: "5.4rem",
            zIndex: "1",
          }}
          disabled={issueResolved || status === "resolved"}
          onClick={() => {
            setImageUploadModal(true);
          }}
        >
          {icons.image({ sx: { mr: "-0.2rem" } })}
        </IconButton>
      </Box>
      <Box
        sx={{
          // justifySelf: "flex-end",
          // width: "100%",
          p: "1rem",
          display: "flex",
          flexDirection: "row",
          zIndex: "1",
          backgroundColor: "#fff",
        }}
      >
        {/* <Avatar sx={{ mr: "1rem", alignSelf: "center" }}>U</Avatar> */}
        <TextField
          id="outlined-basic"
          label="Send message"
          variant="outlined"
          fullWidth
          multiline
          rows={2}
          disabled={sending || issueResolved || status === "resolved"}
          onChange={onChange}
          value={message}
          ref={messageRef}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) return;

            if (e.key === "Enter" && !sending) {
              handleSend();
            }
          }}
        />
        <style>
          {`
        #outlined-basic {
          padding-right: 3rem;
        }
        `}
        </style>
        <Button
          onClick={handleSend}
          sx={{
            marginLeft: "0.6rem",
            borderRadius: "2rem",
            alignSelf: "center",
            height: "3rem",
          }}
          disabled={issueResolved || status === "resolved"}
        >
          {sending ? <CircularProgress size="1rem" color="grey" /> : icons.send({ sx: { mr: "-0.2rem" } })}
        </Button>
      </Box>
    </Box>
  );
};
