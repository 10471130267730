import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { ElevatedCard } from "../elevated-card";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
import AirplanemodeActiveRoundedIcon from "@mui/icons-material/AirplanemodeActiveRounded";

export const JobCard = ({ job }) => {
  const {
    id,
    title,
    contractType,
    sector,
    crewType,
    role,
    airport,
    aircraftType,
    city,
    country,
    region,
    createdAt,
    candidatesAwaitingReview,
  } = job;

  const navigate = useNavigate();

  return (
    <ElevatedCard
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: { xs: "1rem", md: "1.2rem" },
        mt: { xs: "1rem", sm: "2rem" },
        cursor: "pointer",
        ":hover": { boxShadow: "0px 0px 8px 4px #EFEFEF" },
      }}
      onClick={() => navigate(`/jobs/view?jobId=${id}`)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: "grey",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          {crewType} - {role}
        </Typography>

        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/candidates?tab=awaiting review&job=${id}`);
          }}
          sx={{
            padding: "0.2rem 0.6rem",
            width: "fit-content",
            color: "white",
            backgroundColor: "primary.main",
            borderRadius: "2.5rem",
            // boxShadow: "0px 0px 8px 4px #FFE2E2",
          }}
        >
          Awaiting Review - {candidatesAwaitingReview}
        </Button>
      </Box>
      <Typography
        variant="h6"
        sx={{
          my: "0.4rem",
          fontWeight: "bold",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        }}
      >
        {title}
      </Typography>
      <Box sx={{ display: "flex", flexFlow: "wrap", color: "grey" }}>
        <Box sx={{ display: "flex", alignItems: "center", mr: "0.4rem" }}>
          <WorkRoundedIcon fontSize="1.4rem" sx={{ mb: "0.2rem", color: "black" }} />
          <Typography variant="body1" sx={{ ml: "0.4rem", textTransform: "capitalize" }}>
            {contractType}, {sector}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexFlow: "wrap", mr: "0.4rem" }}>
          <AirplanemodeActiveRoundedIcon
            sx={{ width: "1.2rem", mr: "0.4rem", transform: "rotate(45deg)", color: "black" }}
          />
          <Typography variant="body1">
            {aircraftType.manufacturer} - {aircraftType.model}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexFlow: "wrap" }}>
          <PinDropRoundedIcon sx={{ width: "1.2rem", mr: "0.4rem", color: "black" }} />
          <Typography variant="body1" mr="0.4rem">
            {airport.name},
          </Typography>
        </Box>
        <Typography variant="body1" mr="0.4rem">
          {city},
        </Typography>
        <Typography variant="body1" mr="0.4rem">
          {country},
        </Typography>
        <Typography variant="body1">{region}</Typography>
      </Box>

      <Typography variant="subtitle2" sx={{ mt: "0.2rem" }}>
        Posted {moment(createdAt).fromNow()}.
      </Typography>
    </ElevatedCard>
  );
};
