import { Autocomplete, Box, InputBase, Typography } from "@mui/material";
import { languages } from "../../core/constants/languages";

export const LanguagesSelect = ({ label, name, value, onChange, error, helperText, multiple = true }) => {
  const handleChange = (_, value) => {
    if (value) {
      onChange({ target: { name, value: value } });
    }
  };

  const renderOption = (props, option) => (
    <Box value={option} {...props}>
      {option}
    </Box>
  );

  const renderInput = (params) => {
    const { InputLabelProps, InputProps, ...rest } = params;
    return (
      <InputBase
        {...InputProps}
        {...rest}
        sx={{ padding: "0.44rem 0.88rem" }}
        placeholder="Please select the languages"
      />
    );
  };

  return (
    <Box sx={{ my: "0.8rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>
      <Autocomplete
        onChange={handleChange}
        value={value}
        options={languages}
        renderOption={renderOption}
        renderInput={renderInput}
        multiple
        defaultValue={multiple ? [] : null}
        sx={{ borderRadius: "4px", backgroundColor: "#F4F4FB", paddingRight: "1rem" }}
      />
      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
