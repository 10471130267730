import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppRouteConstants } from "../navigations/routes";
import { PageTopLoader } from "../components/loaders/page-top-loader";

export const ProtectedRoute = ({ children }) => {
  const naviagte = useNavigate();
  const authentication = useSelector((state) => state.authentication);
  const mainProfile = useSelector((state) => state.mainProfile);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (authentication.initialized) {
      if (!authentication.isAuthenticated) {
        naviagte(AppRouteConstants.signIn, { replace: true });
      } else if (!mainProfile.verified) {
        naviagte(AppRouteConstants.verifyAccount, {
          replace: true,
        });
      } else if (!mainProfile.company) {
        naviagte(AppRouteConstants.createOrJoinCompany, {
          replace: true,
        });
      }

      setInitialized(true);
    }
  }, [authentication, mainProfile, naviagte]);

  if (!initialized) {
    return <PageTopLoader />;
  }

  return children;
};
