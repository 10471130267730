import {
  axiosInstance,
  handleAxiosResponse,
} from "../../../core/sources/network";

import { handleError, showSuccess } from "../../app-messages";

export const addCandidateNotes = ({ candidateId, notes }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`/candidates/${candidateId}`, {
        notes,
      });

      const { data } = handleAxiosResponse(response);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
