import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store/index";

import { handleError, showSuccess } from "../../app-messages";
import { actions } from "../slice";

export const attachCustomerCard = ({ paymentMethodId }) => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.post(`/billing/${companyProfile.stripeCustomerId}/cards`, {
        companyId: companyProfile.id,
        paymentMethodId,
      });

      const { data, message } = handleAxiosResponse(response);

      dispatch(actions.setCard({ card: data }));
      dispatch(showSuccess(message));
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
