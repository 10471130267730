import { fetchCustomerCard } from "./use-cases/fetch-customer-card";
import { fetchBillingInformation } from "./use-cases/fetch-billing-information";
import { fetchReceipts } from "./use-cases/fetch-receipts";
import { updateBillingAddress } from "./use-cases/update-billing-address";
import { attachCustomerCard } from "./use-cases/attach-customer-card";
import { downloadReceipt } from "./use-cases/download-receipt";
import { detachCustomerCard } from "./use-cases/detach-customer-card";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchCustomerCard = fetchCustomerCard;

  fetchBillingInformation = fetchBillingInformation;

  fetchReceipts = fetchReceipts;

  attachCustomerCard = attachCustomerCard;

  detachCustomerCard = detachCustomerCard;

  updateBillingAddress = updateBillingAddress;

  downloadReceipt = downloadReceipt;
}

export const BillingFeature = new Feature();
