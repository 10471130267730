import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { showSuccess, handleError } from "../../app-messages";

export const inviteMember = ({ email }) => {
  return async (dispatch) => {
    try {
      const recruiterProfile = getAppStoreCurrentState().mainProfile;

      const response = await axiosInstance.post(`/recruiters/${recruiterProfile.email}/invitations`, {
        inviteeEmail: email,
      });

      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
