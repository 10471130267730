import { useState } from "react";
import { appLocalStorage, lskeys } from "../core/sources/local-storage";

const initialFilters = {
  role: "",
  homebase: [],
  country: [],
  licenses: [],
  passports: [],
  visas: [],
  minPicHours: 0,
  maxPicHours: 0,
  minSicHours: 0,
  maxSicHours: 0,
  minCorporateYears: 0,
  maxCorporateYears: 0,
  minTotalYears: 0,
  maxTotalYears: 0,
};

export const useFilterOptions = () => {
  const [state, setState] = useState(appLocalStorage.get(lskeys.filters) ?? initialFilters);

  // useEffect(() => {
  //   const savedState = appLocalStorage.get(lskeys.filters);

  //   if (!savedState) {
  //     appLocalStorage.save({ [lskeys.filters]: initialFilters });
  //     setState(initialFilters);
  //   } else {
  //     appLocalStorage.save({ [lskeys.filters]: savedState });
  //     setState(savedState);
  //   }
  // }, []);

  const saveFilterOptions = (filters) => {
    appLocalStorage.save({ [lskeys.filters]: filters });
    setState(filters);
  };

  return [state, saveFilterOptions];
};
