import { fetchPostedJobs } from "./use-cases/fetch-posted-jobs";
import { fetchPostedJobsTitle } from "./use-cases/fetch-jobs-title";
import { fetchJobDetails } from "./use-cases/fetch-job-details";
import { postNewJob } from "./use-cases/post-job";
import { updateJobStatus } from "./use-cases/update-job-status";
import { updateJobDetails } from "./use-cases/update-job-details";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchPostedJobs = fetchPostedJobs;

  fetchPostedJobsTitle = fetchPostedJobsTitle;

  fetchJobDetails = fetchJobDetails;

  postNewJob = postNewJob;

  updateJobStatus = updateJobStatus;

  updateJobDetails = updateJobDetails;
}

export const JobsFeature = new Feature();
