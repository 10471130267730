import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  verified: false,
  profileId: "",
  supportPin: "",
  user: "",
  company: "",
  permission: "",
  name: "",
  email: "",
  phone: "",
  linkedIn: "",
};

export const slice = createSlice({
  name: "mainProfile",

  initialState: { ...initialState },

  reducers: {
    setProfile: (state, { payload }) => {
      state.verified = payload.profile.verified;
      state.profileId = payload.profile.id;
      state.supportPin = payload.profile.supportPin;
      state.user = payload.profile.user;
      state.company = payload.profile.company;
      state.permission = payload.profile.permission;
      state.name = payload.profile.name;
      state.image = payload.profile.image;
      state.email = payload.profile.email;
      state.phone = payload.profile.phone;
      state.linkedIn = payload.profile.linkedInProfile;
    },

    setProfileVerified: (state, { payload }) => {
      state.verified = payload.verified;
    },

    setProfileImage: (state, { payload }) => {
      state.image = payload.image;
    },

    setCompany: (state, { payload }) => {
      state.company = payload.company;
    },

    updatePremium: (state, { payload }) => {
      state.currentPlan = payload.currentPlan;
    },

    clearProfile: () => ({ ...initialState }),
  },
});

export const { reducer, actions } = slice;
