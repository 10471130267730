import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Pagination from "@mui/material/Pagination";
import { SectionCard } from "../../../components/section-card";
import { BillingFeature } from "../../../features/billing";
import { ReceiptGenerator } from "./generate-receipt";

export const PaymentReceipts = (props) => {
  const dispatch = useDispatch();
  const [payments, setPayments] = useState([]);
  const [pagination, setPagination] = useState({ totalPages: 0, pageNo: 1 });

  useEffect(() => {
    BillingFeature.fetchReceipts({ pageNo: pagination.pageNo })(dispatch).then((data) => {
      setPayments(data.payments);
      setPagination(data.pagination);
    });
  }, [dispatch, pagination.pageNo]);

  return (
    <SectionCard title="Payment Receipts">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ py: "0.6rem" }} align="left">
              Date
            </TableCell>
            <TableCell sx={{ py: "0.6rem" }} align="center">
              Amount
            </TableCell>
            <TableCell sx={{ py: "0.6rem" }} align="right">
              Invoice
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} sx={{ textAlign: "center", borderBottom: "none", pt: "3rem" }}>
                No Invoices Generated Yet
              </TableCell>
            </TableRow>
          )}
          {payments.map(({ date, amount, id }, index) => (
            <TableRow
              key={date}
              sx={{
                "& td": {
                  ...(index + 1 === payments.length && {
                    borderBottom: "none",
                  }),
                },
              }}
            >
              <TableCell align="left">{moment(date).format("DD MMM, YYYY")}</TableCell>
              <TableCell align="center">&pound;{amount}</TableCell>
              <TableCell align="right">
                <Box sx={{ textDecoration: "underline", textDecorationColor: "red" }}>
                  <ReceiptGenerator id={id} date={date} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          mt: "-0.2rem",
          mb: "-0.6rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          count={pagination.totalPages}
          page={pagination.pageNo}
          onChange={(_, page) => {
            setPagination((prev) => ({ ...prev, pageNo: page }));
          }}
          size="small"
        />
      </Box>
    </SectionCard>
  );
};
