import { Autocomplete, Box, InputBase, Typography } from "@mui/material";

export const RegionSelect = ({ label, name, value, onChange, error, helperText }) => {
  const handleChange = (_, value) => {
    if (value) {
      onChange({ target: { name, value: value } });
    }
  };

  const renderOption = (props, option) => (
    <Box value={option} {...props}>
      {option}
    </Box>
  );

  const renderInput = (params) => {
    const { InputLabelProps, InputProps, ...rest } = params;
    return (
      <InputBase {...InputProps} {...rest} placeholder="Please enter the region" sx={{ padding: "0.44rem 0.88rem" }} />
    );
  };

  return (
    <Box sx={{ my: "0.8rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>
      <Autocomplete
        defaultValue={null}
        sx={{ borderRadius: "4px", backgroundColor: "#F4F4FB", paddingRight: "1rem" }}
        renderOption={renderOption}
        renderInput={renderInput}
        options={options}
        onChange={handleChange}
        value={value}
      />
      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

const options = ["North America", "South America", "Europe", "Middle East", "Africa", "Asia", "Australasia"];
