import { fetchCurrentSubsription } from "./use-cases/fetch-current-subscription";
import { subscribeToLite } from "./use-cases/subscribe-to-lite";
import { subscribeToPro } from "./use-cases/subscribe-to-pro";
import { subscribeToEnterprise } from "./use-cases/subscribe-to-enterprise";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchCurrentSubsription = fetchCurrentSubsription;

  subscribeToLite = subscribeToLite;

  subscribeToPro = subscribeToPro;

  subscribeToEnterprise = subscribeToEnterprise;
}

export const SubscriptionsFeature = new Feature();
