import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  card: null,

  billingAddress: {
    email: null,
    addressLine: null,
    city: null,
    zipCode: null,
    state: null,
    country: null,
  },

  receipts: {},
};

export const slice = createSlice({
  name: "billing",

  initialState: { ...initialState },

  reducers: {
    setCard: (state, { payload }) => {
      if (payload.card) {
        state.card = {
          paymentMethodId: payload.card.paymentMethodId,
          brand: payload.card.brand,
          last4Digits: payload.card.last4Digits,
          expiryMonth: payload.card.expiryMonth,
          expiryYear: payload.card.expiryYear,
        };
      }
    },

    removeCard: (state) => {
      state.card = null;
    },

    setBillingAddress: (state, { payload }) => {
      state.billingAddress = {
        email: payload.billingAddress.email,
        addressLine: payload.billingAddress.addressLine,
        city: payload.billingAddress.city,
        zipCode: payload.billingAddress.zipCode,
        state: payload.billingAddress.state,
        country: payload.billingAddress.country,
      };
    },

    setReceipts: (state, { payload }) => {
      state[payload.currentPage] = payload.receipts;
    },

    clear: () => {
      return { ...initialState };
    },
  },
});

export const { reducer, actions } = slice;
