import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { icons } from "../../icons";
import { PostJobButton } from "../buttons/post-job";
import { PageBackButton } from "../buttons/page-back";

const PageHeader = ({ pageName, toggleDrawer, showBackButton, showPostJobButton }) => (
  <Box
    sx={{
      margin: { xs: "1rem", sm: "2rem" },
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {showBackButton && <PageBackButton />}
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        {pageName}
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {showPostJobButton && (
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <PostJobButton />
        </Box>
      )}
      <IconButton
        onClick={() => toggleDrawer(true)}
        sx={{
          ml: "1rem",
          padding: "0.6rem",
          display: { xs: "flex", md: "none" },
        }}
      >
        {icons.menu({})}
      </IconButton>
    </Box>
  </Box>
);

const MemoizedPageHeader = React.memo(PageHeader);

export { MemoizedPageHeader as PageHeader };
