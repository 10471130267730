import { createSlice } from "@reduxjs/toolkit";

const initialState = { initialized: false, isAuthenticated: false };

export const slice = createSlice({
  name: "authentication",

  initialState: { ...initialState },

  reducers: {
    initialize: (state, { payload }) => {
      state.initialized = payload?.initialize ?? true;
    },

    login: (state) => {
      state.isAuthenticated = true;
    },

    logout: () => ({ ...initialState }),
  },
});

export const { reducer, actions } = slice;
