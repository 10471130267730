import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { showSuccess, handleError } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";
import { appLocalStorage } from "../../../core/sources/local-storage";
import { actions } from "../slice";
import { actions as authenticationActions } from "../../authentication/slice";

export const deleteProfile = ({ password }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.delete(`/recruiters/${mainProfile.email}`, {
        params: { password },
      });

      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));
      dispatch(authenticationActions.logout());
      dispatch(actions.clearProfile());
      appLocalStorage.clear();
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
