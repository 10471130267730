import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Modal } from ".";
import { CircularLoader } from "../loaders/circular-loader";

export const ConfirmationModal = ({
  open,
  onClose,
  onConfirmation,
  isConfirming,
  title,
  description,
  confirmText,
}) => (
  <Modal open={open} onClose={onClose}>
    <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
      {title}
    </Typography>
    <Typography>{description}</Typography>
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button variant="outlined" color="primary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onConfirmation}
        sx={{ ml: "1rem" }}
      >
        {isConfirming ? (
          <CircularLoader color={"white"} size={20} />
        ) : (
          confirmText
        )}
      </Button>
    </Box>
  </Modal>
);
