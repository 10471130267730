import { Fragment, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { QuillEditor } from "../../../components/quill-editor";
import { ElevatedCard } from "../../../components/elevated-card";
import { useDispatch, useSelector } from "react-redux";
import { PostJobFormFeature } from "../../../features/post-job-form";

export const JobDescriptionStep = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { formState } = useSelector((state) => state.postJobForm);
  const { description, benefits } = formState;
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: { description: description || "", benefits: benefits || "" },

    validationSchema: yup.object({
      description: yup.string().required("Required"),
      benefits: yup.string().required("Required"),
    }),

    onSubmit: async (descriptionInfo) => {
      setSaving(true);
      await PostJobFormFeature.saveToDraft({ jobInfo: descriptionInfo })(dispatch);
      setSaving(false);
      onNext();
    },
  });

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          padding: { xs: "0rem 1.2rem", sm: "0.6rem 2rem 2rem 2rem" },
          backgroundColor: "#FFFAFA",
        }}
      >
        <Typography sx={{ fontWeight: "bold", mt: "1rem", mb: "0.6rem" }}>Job Description</Typography>
        <QuillEditor
          onChange={(content) => formik.setFieldValue("description", content)}
          placeholder="Give a detailed describtion of the job"
          sx={{ fontFamily: "Torek", height: "18rem", mb: "1.6rem", backgroundColor: "white", borderRadius: "0.6rem" }}
          value={formik.values.description}
        />
        {Boolean(formik.touched.description && formik.errors.description) && (
          <Typography variant="subtitle2" sx={{ m: "0.6rem", color: "red" }}>
            {formik.touched.description && formik.errors.description}
          </Typography>
        )}
        <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>Benefits</Typography>
        <QuillEditor
          onChange={(content) => formik.setFieldValue("benefits", content)}
          placeholder="Please detail the benefits for this position"
          sx={{ height: "18rem", mb: { xs: "1.6rem", sm: "0rem" }, backgroundColor: "white", borderRadius: "0.6rem" }}
          value={formik.values.benefits}
        />
        {Boolean(formik.touched.benefits && formik.errors.benefits) && (
          <Typography variant="subtitle2" sx={{ m: "0.6rem", color: "red" }}>
            {formik.touched.benefits && formik.errors.benefits}
          </Typography>
        )}
      </ElevatedCard>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onBack} sx={{ borderRadius: "1rem" }}>
          Back
        </Button>
        <Box sx={{ width: "2rem" }} />
        <Button onClick={formik.submitForm} sx={{ borderRadius: "1rem" }} disabled={saving}>
          {saving ? <CircularProgress size="1.4rem" /> : "Continue"}
        </Button>
      </Box>
    </Fragment>
  );
};
