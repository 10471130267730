import { Box, Modal as MuiModal } from "@mui/material";

export const Modal = ({
  open,
  onClose,
  closeOnBackdropClick = false,
  maxWidth = "38rem",
  children,
  right,
  top,
  disableEscapeKeyDown,
  maxHeight,
}) => {
  const handleClose = (_, event) => {
    if (closeOnBackdropClick) {
      if (event === "backdropClick") {
        return;
      }
    }

    onClose();
  };

  return (
    <MuiModal
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          backgroundColor: "white",
          width: "80%",
          maxWidth,
          maxHeight,
          minWidth: { xs: "90%", sm: "26rem" },
          padding: "0.7rem 1.4rem",
          borderRadius: "0.8rem",
          position: "absolute",
          top: top !== undefined ? top : "50%",
          right: right !== undefined ? right : "",
          left: right !== undefined ? "" : "50%",
          transform: right !== undefined ? "" : "translate(-50%, -50%)",
        }}
      >
        {children}
      </Box>
    </MuiModal>
  );
};
