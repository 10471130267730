import { Box, Chip, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ElevatedCard } from "../elevated-card";

export const TicketCard = ({ ticket }) => {
  const { id, token, title, category, priority, createdAt, updatedAt } = ticket;

  const navigate = useNavigate();

  return (
    <ElevatedCard
      sx={{
        padding: "1rem",
        mt: { xs: "1rem", sm: "1.2rem" },
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "1rem",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/support/view?ticketId=${id}`)}
    >
      <Box sx={{ flex: 1, ml: "1rem" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Chip
            sx={{
              ml: "1rem",
              padding: "0px",
              height: "1.6rem",
              textTransform: "uppercase",
            }}
            label={priority}
          />
          {/* <Typography variant="h6">{token}</Typography> */}
        </Box>
        <Typography variant="subtitle2">Category: {category}</Typography>
        <Typography variant="caption" sx={{ color: "grey" }}>
          Created on {moment(createdAt).format("MMM DD, YYYY.")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "#000000",
              mb: "0.5rem",
            }}
          >
            Last updated {moment(updatedAt).format("DD MMM YYYY")}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "#000000",
              mb: "0.5rem",
              ml: "1rem",
            }}
          >
            Token: &nbsp; {token}
          </Typography>
        </Box>
      </Box>
    </ElevatedCard>
  );
};
