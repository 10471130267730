import { Box, ButtonBase, Menu, MenuItem, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { icons } from "../../icons";

export const DropDown = ({ label, options, name, value, onChange, error, helperText, placeholder }) => {
  const anchorRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleChange = (option) => {
    onChange({ target: { name, value: option } });
    setMenuOpen(false);
  };

  return (
    <Box sx={{ my: "1.6rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>
      <ButtonBase
        onClick={() => setMenuOpen(true)}
        sx={{
          width: "100%",
          padding: "0.66rem 0.88rem",
          borderRadius: "4px",
          backgroundColor: "#F4F4FB",
          display: "flex",
          justifyContent: "space-between",
          textTransform: "capitalize",
        }}
        ref={anchorRef}
      >
        {value.length !== 0 ? (
          <Typography>{value}</Typography>
        ) : (
          <Typography sx={{ color: "#9B9B9F" }}>{placeholder}</Typography>
        )}
        <ExpandIcon menuOpen={menuOpen} />
      </ButtonBase>
      <Menu
        open={menuOpen}
        anchorEl={anchorRef.current}
        defaultValue={options[0]}
        onClose={() => setMenuOpen(false)}
        sx={{ zIndex: 3000 }}
        PaperProps={{
          elevation: 6,
          style: { borderRadius: "0.4rem", maxHeight: "16rem", width: anchorRef && anchorRef.current?.offsetWidth },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleChange(option)}
            sx={{ textTransform: "capitalize", whiteSpace: "break-spaces", wordBreak: "break-word", py: "0.5rem" }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

const ExpandIcon = ({ menuOpen }) => {
  return <Box sx={{ ml: "0.6rem", display: "flex" }}>{menuOpen ? icons.expandLess({}) : icons.expandMore({})}</Box>;
};
