import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";
import { getAppStoreCurrentState } from "../../../store";

export const updateProfile = ({ profileDetails }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.put(`/companies/${mainProfile.email}/profile`, {
        email: mainProfile.email,
        profileDetails,
      });

      const { data } = handleAxiosResponse(response);

      appLocalStorage.save({ [lskeys.companyProfile]: data });

      dispatch(actions.setProfile({ profile: data }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
