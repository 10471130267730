import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { ConversationsFeature } from "../../../../features/conversations";
import { icons } from "../../../../icons";

export const Message = ({ conversation, message }) => {
  const dispatch = useDispatch();

  const mainProfile = useSelector((state) => state.mainProfile);

  useEffect(() => {
    if (
      mainProfile.profileId !== message.sender.profileId &&
      message.isRead === false
    ) {
      ConversationsFeature.markMessageAsRead({
        conversationId: message.conversation,
        messageId: message.id,
        message,
      })(dispatch);
    }
  }, [dispatch, message]);

  const author = {
    name:
      mainProfile.profileId === message.sender.profileId
        ? "You"
        : conversation.recipient.name,
    image:
      mainProfile.profileId === message.sender.profileId
        ? mainProfile.image
        : conversation.recipient.image,
  };

  // console.log(message);

  return (
    <Box sx={{ display: "flex", margin: "1rem" }}>
      <Avatar
        sx={{ height: "2.4rem", width: "2.4rem", mr: "1rem" }}
        src={author?.image}
      />

      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "bold", textTransform: "capitalize" }}>
            {author.name}
          </Typography>
          {icons.circle({
            sx: { height: "0.4rem", width: "0.4rem", mx: "0.6rem" },
          })}
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            {message?.createdAt &&
            moment(message?.createdAt).isSame(new Date(), "day")
              ? moment(message?.createdAt).format("LT")
              : moment(message?.createdAt).calendar()}
          </Typography>
        </Box>
        <Typography variant="body1">
          {message?.message?.split("\n").map((item, i) => (
            <span key={message}>
              {item}
              <br />
            </span>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};
