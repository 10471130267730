import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import { BodyCell, HeaderCell } from "./table-cells";
import { icons } from "../../icons";

export const PlanFeatureLimitsColumn = ({ planDetails, billingCycle, planInMobileView, active, children }) => {
  const makeLimit = (limit) => (limit !== undefined ? <Typography>{limit}</Typography> : icons.checked({}));

  return (
    <Hidden smDown={planInMobileView}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingY: "1rem",
          ...(active && {
            backgroundColor: "#FDE8E7",
            boxShadow: "0px 0px 8px #EFEFEF",
            transition: "ease-in-out 300ms",
          }),
        }}
      >
        <HeaderCell
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
            {planDetails.name}
          </Typography>
          {planDetails.schemes[billingCycle].offer && <OfferChip offer={planDetails.schemes[billingCycle].offer} />}
          <PlanAmount amount={planDetails.schemes[billingCycle].amount} />
        </HeaderCell>
        {planDetails.features.map(({ name, available, limit }, index) => (
          <BodyCell key={name} sx={{ justifyContent: "center" }}>
            {available ? makeLimit(limit) : "-"}
          </BodyCell>
        ))}
        {children}
      </Box>
    </Hidden>
  );
};

const PlanAmount = ({ amount }) => {
  return (
    <Typography sx={{ fontSize: "1.6rem", mt: "1rem" }}>
      {amount !== "FREE" && <>&pound;</>}
      {amount}
      {amount !== "FREE" && <Typography component="span"> / m</Typography>}
    </Typography>
  );
};

const OfferChip = ({ offer }) => {
  return (
    <Box sx={{ position: "absolute", top: "0px", right: "-0.2rem", mt: "-1.2rem" }}>
      <Chip
        label={`save ${offer}`}
        sx={{
          textTransform: "capitalize",
          color: "white",
          backgroundImage: "linear-gradient(to right, #E53834, #e43a15)",
          border: "1px solid #EFEFEF",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          boxShadow: "0px 0px 8px 5px #EFEFEF",
          cursor: "inherit",
          padding: "0rem",
        }}
      />
    </Box>
  );
};
