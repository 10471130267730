import { combineReducers } from "@reduxjs/toolkit";
import { slice as appMessages } from "../features/app-messages/slice";
import { slice as authentication } from "../features/authentication/slice";
import { slice as mainProfile } from "../features/main-profile/slice";
import { slice as companyProfile } from "../features/company-profile/slice";
import { slice as companyMembers } from "../features/company-members/slice";
import { slice as billing } from "../features/billing/slice";
import { slice as subscription } from "../features/subscription/slice";
import { slice as jobs } from "../features/jobs/slice";
import { slice as postJobForm } from "../features/post-job-form/slice";
import { slice as candidates } from "../features/candidates/slice";
import { slice as conversations } from "../features/conversations/slice";
import { slice as jobseekers } from "../features/jobseekers/slice";
import { slice as calendar } from "../features/calendar/slice";

export const rootReducer = combineReducers({
  [appMessages.name]: appMessages.reducer,
  [authentication.name]: authentication.reducer,
  [mainProfile.name]: mainProfile.reducer,
  [companyProfile.name]: companyProfile.reducer,
  [companyMembers.name]: companyMembers.reducer,
  [billing.name]: billing.reducer,
  [subscription.name]: subscription.reducer,
  [jobs.name]: jobs.reducer,
  [calendar.name]: calendar.reducer,
  [postJobForm.name]: postJobForm.reducer,
  [candidates.name]: candidates.reducer,
  [conversations.name]: conversations.reducer,
  [jobseekers.name]: jobseekers.reducer,
});
