import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const fetchAllConversations = ({ searchTerm, page }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations?page=${page}&searchTerm=${searchTerm}`
      );
      const { data } = handleAxiosResponse(response);

      dispatch(actions.setConversations(data.conversations));

      return {
        conversations: data.conversations,
        hasMore: data.hasMore,
        page,
      };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
