import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSocket } from "../../../../hooks/use-socket";
import { ConversationsFeature } from "../../../../features/conversations";
import { CircularLoader } from "../../../../components/loaders/circular-loader";
import { icons } from "../../../../icons";
import { useSearchParams } from "react-router-dom";
import { TextareaAutosize } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const MessageInput = ({ recipient }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [searchParams] = useSearchParams();
  const socket = useSocket();
  const mainProfile = useSelector((state) => state.mainProfile);
  const theme = useTheme();

  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", (ev) => {
      if (ev.key === "/") {
        if (document.activeElement === inputRef?.current) {
          return;
        }

        if (inputRef?.current) {
          inputRef?.current.focus();
          ev.preventDefault();
        }
      }
    });

    return () => {
      window.removeEventListener("keydown", () => {});
    };
  }, []);

  const onChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSend = async () => {
    if (
      !message ||
      !searchParams.get("conversationId") ||
      !mainProfile?.profileId ||
      sending
    ) {
      return;
    }
    setSending(true);
    const result = await ConversationsFeature.sendMessage({
      conversationId: searchParams.get("conversationId"),
      messageBody: {
        senderId: mainProfile.profileId,
        recipient,
        message: message,
      },
    })(dispatch);
    socket.emit("sendMessage", {
      data: result.data,
      senderName: mainProfile.name,
      recipient,
    });
    setMessage("");
    setSending(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <Box
      sx={{ borderTop: "1px solid #e0e0e0", padding: "1rem", display: "flex" }}
    >
      <TextareaAutosize
        ref={inputRef}
        minRows={1}
        maxRows={2}
        value={message}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        className="custom-textarea"
        placeholder="Enter your message"
        disabled={!recipient || sending}
      />
      <style>
        {`
          .custom-textarea{
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.fontSize};
            outline: none;
            margin: 0;
            border-radius: 2rem;
            resize: none;
            width: 100%;
            padding: 0.7rem;
            border: #ccc 1px solid;
          }
          .custom-textarea:hover{
            border: #61605f 1.5px solid !important;
          }
          .custom-textarea:focus {
            border: ${theme.palette.primary.main} 1.5px solid !important;
          }
          .custom-textarea:active {
            border: ${theme.palette.primary.main} 1.5px solid !important;
          }
        `}
      </style>
      <Button
        onClick={handleSend}
        sx={{ marginLeft: "0.6rem", borderRadius: "2rem" }}
        disabled={!recipient || sending}
      >
        {sending ? (
          <CircularLoader size="1rem" color="white" />
        ) : (
          icons.send({ sx: { mr: "-0.2rem" } })
        )}
      </Button>
    </Box>
  );
};
