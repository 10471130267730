import { Box, Typography } from "@mui/material";
import moment from "moment/moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRouteConstants } from "../../../../navigations/routes";
import { useSelector } from "react-redux";

export const ConversationListItem = ({ id, conversation }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mainProfile = useSelector((state) => state.mainProfile);

  const selected = searchParams.get("conversationId") === conversation.id;

  const onClick = () => {
    if (selected) return;
    navigate(
      `${AppRouteConstants.viewConversation}?conversationId=${conversation.id}`
    );
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        padding: "0.6rem 1rem",
        display: "flex",
        flexDirection: "column",
        backgroundColor: selected ? "conversation.selected" : "white",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: "1.08rem", fontWeight: "bold" }}
        >
          {conversation.recipient.name ?? "(Deleted User)"}
        </Typography>
        <Typography variant="caption">
          {moment(conversation.updatedAt).format("D-M-YYYY")}
        </Typography>
      </Box>

      <Typography variant="subtitle2" sx={{ color: "grey" }}>
        {conversation.latestMessage ? (
          <span
            style={{
              color:
                conversation.latestMessage.sender.type ===
                  conversation.recipient.type &&
                conversation.latestMessage.isRead === false &&
                "black",
            }}
          >
            {mainProfile.profileId ===
            conversation.latestMessage.sender.profileId
              ? "You"
              : conversation.recipient.name.split(" ")[0]}
            :&nbsp;
            {conversation.latestMessage.message.length > 50
              ? conversation.latestMessage.message.substring(0, 50) + "..."
              : conversation.latestMessage.message}
          </span>
        ) : (
          <span>Start sending message</span>
        )}
      </Typography>
    </Box>
  );
};
