import { useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { icons } from "../../../../icons";
import { ConversationsFeature } from "../../../../features/conversations";
import { useNavigate } from "react-router-dom";
import { AppRouteConstants } from "../../../../navigations/routes";

export const ConversationMenuOptions = ({ conversationId, cb = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOnClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnClickArchive = async () => {
    if (conversationId) {
      await ConversationsFeature.archiveConversation(conversationId)(dispatch);
      cb();

      handleOnCloseMenu();
      navigate(AppRouteConstants.viewConversation, { replace: true });
    }
  };

  return (
    <Box>
      <IconButton
        onClick={handleOnClickMenu}
        disabled={!conversationId}
        sx={{ mr: "-0.6rem" }}
      >
        {icons.more({})}
      </IconButton>
      <Menu
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={handleOnCloseMenu}
        anchorEl={anchorEl}
      >
        <MenuItem onClick={handleOnClickArchive}>Archive Conversation</MenuItem>
      </Menu>
    </Box>
  );
};
