import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { format } from "date-fns";

const formatDate = (date) => {
  // checking if date is in correct format
  if (date instanceof Date && !isNaN(date)) {
    return format(date, "yyyy-MM");
  }
};

function CustomDatePicker({ label, name, value, views, onChange, inputFormat }) {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleClick = () => {
    setCalendarOpen(true);
  };

  const handleClose = () => {
    setCalendarOpen(false);
  };

  return (
    <DatePicker
      open={calendarOpen}
      onOpen={handleClick}
      onClose={handleClose}
      label={label}
      name={name}
      format={inputFormat}
      value={value}
      views={views}
      onChange={onChange}
      renderInput={(props) => <TextField {...props} value={value ? formatDate(value) : ""} onClick={handleClick} />}
    />
  );
}

export default CustomDatePicker;
