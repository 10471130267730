import {
  axiosInstance,
  handleAxiosResponse,
} from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const fetchSupportTitles = ({
  page,
  pageSize,
  filters: { status, category, priority, createdAt },
}) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `/support/titles?page=${page}&pageSize=${pageSize}&status=${status}&category=${category}&priority=${priority}&createdAt=${createdAt}`
      );

      return handleAxiosResponse(response).data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
