import { Box, CircularProgress } from "@mui/material";
import { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFView = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const docRef = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Box ref={docRef}>
      <Document
        renderMode="canvas"
        file={{ url }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <Box sx={{ width: "100%", display: "grid", placeItems: "center" }}>
            <CircularProgress />
          </Box>
        }
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={docRef.current?.offsetWidth} />
        ))}
      </Document>
    </Box>
  );
};
