import { appLocalStorage } from "../../../core/sources/local-storage";

import { actions as mainProfileActions } from "../../main-profile/slice";
import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const signOut = () => {
  return async (dispatch) => {
    try {
      dispatch(actions.logout());
      dispatch(actions.initialize());
      dispatch(mainProfileActions.clearProfile());
      appLocalStorage.clear();
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
