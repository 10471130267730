import { useEffect, useState, useRef } from "react";
import { Box, Button, IconButton, Input } from "@mui/material";

import { useSearchParams, useNavigate } from "react-router-dom";
import { ElevatedCard } from "./elevated-card";
import { icons } from "../icons";

export const SearchBar = ({ navigateUrl, navigateSearchUrl, sx }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const searchRef = useRef(null);

  useEffect(() => {
    if (searchParams.has("search")) {
      setSearch(searchParams.get("search"));
    }
  }, [searchParams]);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key === "/") {
        event.preventDefault();
        searchRef.current.focus();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  const onSearch = () => {
    if (!search.length) {
      return navigate(navigateUrl);
    }

    navigate(navigateSearchUrl(search));
  };

  const onKeyDown = ({ key }) => {
    if (key === "Enter") onSearch();
  };

  return (
    <ElevatedCard
      sx={{
        flex: 1,
        padding: "0rem",
        borderRadius: "0.6rem",
        mt: "1rem",
        height: "55px",
        maxHeight: "55px",
        width: "100%",
        ...sx,
      }}
      elevation={0}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          px: { xs: "0.4rem", md: "0.6rem" },
          pt: { xs: "0.1rem", md: "0.2rem" },
        }}
      >
        {icons.search({ fontSize: "small", sx: { ml: "0.4rem" } })}

        <Box sx={{ flexGrow: 1, ml: "0.8rem" }}>
          <Input
            value={search}
            onKeyDown={onKeyDown}
            onChange={({ target }) => setSearch(target.value)}
            placeholder="Enter a keyword"
            disableUnderline
            fullWidth
            inputRef={searchRef}
          />
        </Box>

        <IconButton
          sx={{
            position: "relative",
          }}
          onClick={() => {
            setSearch("");
            navigate(navigateUrl);
          }}
        >
          {icons.close({})}
        </IconButton>
        <Button onClick={onSearch}>Search</Button>
      </Box>
    </ElevatedCard>
  );
};
