import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  id: "",
  profileId: "",
  stripeCustomerId: "",
  stripeSubscriptionId: "",
  status: "",
  plan: null,
  inTrial: "",
  canUseTrial: {
    lite: true,
    pro: true,
    enterprise: true,
  },
  billingCycle: "",
  subscribedOn: "",
  validTill: "",
};

export const slice = createSlice({
  name: "subscription",

  initialState: { ...initialState },

  reducers: {
    setInitialized: (state) => {
      state.initialized = true;
    },

    setSubscription: (state, { payload }) => {
      if (payload.subscription) {
        state.id = payload.subscription.id;
        state.profileId = payload.subscription.profileId;
        state.stripeSubscriptionId = payload.subscription.stripeSubscriptionId;
        state.stripeCustomerId = payload.subscription.stripeCustomerId;
        state.plan = payload.subscription.plan;
        state.billingCycle = payload.subscription.billingCycle;
        state.status = payload.subscription.status;
        state.inTrial = payload.subscription.inTrial;
        state.canUseTrial.lite = payload.subscription.canUseTrial.lite;
        state.canUseTrial.pro = payload.subscription.canUseTrial.pro;
        state.canUseTrial.enterprise = payload.subscription.canUseTrial.enterprise;
        state.subscribedOn = payload.subscription.subscribedOn;
        state.validTill = payload.subscription.validTill;
      }
    },

    clear: () => {
      return { ...initialState };
    },
  },
});

export const { reducer, actions } = slice;
