import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Badge from "@mui/material/Badge";

export const StatusFiltersTab = ({ status, job, isLoading }) => {
  const navigate = useNavigate();
  const candidatesCount = useSelector((state) => state.candidates.count);

  const handleOnChangeTab = useCallback(
    (_, value) => {
      let navigateTo = `/candidates?tab=${value}`;

      if (job) {
        navigateTo = `/candidates?job=${job?._id}&tab=${value}`;
      }

      navigate(navigateTo);
    },
    [job, navigate]
  );

  return (
    <Box sx={{ mt: "0.6rem", mb: "1.2rem", width: { xs: "calc(100vw - 2rem)", md: "100%" } }}>
      <Tabs
        variant="scrollable"
        allowScrollButtonsMobile
        value={status}
        onChange={handleOnChangeTab}
        TabIndicatorProps={{ sx: { backgroundColor: "primary.main", height: "3px", borderRadius: "2px" } }}
      >
        {statuses.map((tab) => (
          <Tab
            icon={<Badge badgeContent={candidatesCount?.[tab] ?? 0} sx={{ width: "100%" }} color="error" />}
            sx={{ minWidth: "0rem", paddingX: "0.8rem", textTransform: "capitalize", overflow: "visible" }}
            {...{ key: tab, label: tab, value: tab }}
          />
        ))}
      </Tabs>
      <Divider sx={{ mt: "-2px", borderColor: "grey" }} />
    </Box>
  );
};

export const statuses = ["awaiting review", "reviewed", "shortlisted", "interviewing", "rejected", "hired"];
