import { fetchJobseekerDetails } from "./use-cases/fetch-jobseeker-details.js";
import { fetchJobseekers } from "./use-cases/fetch-jobseekers.js";
import { downloadJobseekerResume } from "./use-cases/download-jobseeker-resume.js";
import { downloadJobseekerCoverLetter } from "./use-cases/download-jobseeker-cover-letter.js";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchJobseekerDetails = fetchJobseekerDetails;

  fetchJobseekers = fetchJobseekers;

  downloadJobseekerResume = downloadJobseekerResume;

  downloadJobseekerCoverLetter = downloadJobseekerCoverLetter;
}

export const JobseekersFeature = new Feature();
