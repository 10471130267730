import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError, showSuccess } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";

export const updateBillingAddress = ({ billingAddress }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.put(`/companies/${mainProfile.email}/billing-address`, {
        billingAddress,
      });

      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));
      dispatch(actions.setBillingAddress({ billingAddress }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
