import {
  axiosInstance,
  handleAxiosResponse,
} from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const reviewMultiCandidates = ({ candidateIds, status, reviewNote }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch("/candidates/multi", {
        candidateIds,
        status,
        reviewNote,
      });

      const { data } = handleAxiosResponse(response);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
