import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PageTopLoader } from "../../components/loaders/page-top-loader";

import { PageLayout } from "../../components/page-layout";
import { CandidatesFeature } from "../../features/candidates";
import { CandidateBasicCard } from "./components/candidate-basic-card";
import { CandidateDocuments } from "./components/candidate-documents";
import { CandidateResumePdf } from "./components/candidate-resume";
import { CandidateStatusSelect } from "./components/candidate-status-select";
import { CandidateNotes } from "./components/candidate-notes";
import { CandidateProfessionalGallery } from "./components/candidate-professional-gallery";
import { CandidateCoverLetterPdf } from "./components/candidate-cover-letter";
import CalendarComponent from "./components/candidate-availability-calendar";
import { useTheme } from "@mui/material/styles";

export const CandidateDetailsPage = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [candidate, setCandidate] = useState(null);
  const [canViewCompleteProfile, setCanViewCompleteProfile] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [searchParams] = useSearchParams();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isXs, setIsXs] = useState(false);
  const [isSm, setIsSm] = useState(false);
  const [isMd, setIsMd] = useState(false);

  const candidateId = searchParams.get("candidateId");

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleBreakpointChange = () => {
      const breakpoints = Object.keys(theme.breakpoints.values).reverse();
      const currentBreakpoint = breakpoints.find(
        (breakpoint) => windowSize.width >= theme.breakpoints.values[breakpoint]
      );
      setIsXs(currentBreakpoint === "xs");

      setIsSm(currentBreakpoint === "sm");
      setIsMd(currentBreakpoint === "md");
    };

    handleBreakpointChange();

    window.addEventListener("resize", handleBreakpointChange);

    return () => {
      window.removeEventListener("resize", handleBreakpointChange);
    };
  }, [windowSize, theme.breakpoints.values]);

  useEffect(() => {
    const fetch = async () => {
      if (candidateId) {
        setInitialized(false);
        const fetchedApplication =
          await CandidatesFeature.fetchCandidateDetails({
            candidateId,
          })(dispatch);
        setCanViewCompleteProfile(fetchedApplication.canViewCompleteProfile);
        setCandidate(fetchedApplication.candidate);
      }
    };

    fetch().finally(() => setInitialized(true));
  }, [dispatch, candidateId]);

  return (
    <PageLayout
      pageName="Candidate"
      showBackButton
      showPostJobButton
      showAppBar
    >
      {!initialized && <PageTopLoader />}
      {initialized && (
        <Box
          sx={{
            margin: { xs: "1rem 1rem 1rem 1rem", sm: "0rem 2rem 2rem 2rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", lg: "row" },
              alignItems: { xs: "stretch", lg: "flex-start" },
            }}
          >
            <Box sx={{ flex: 1.6 }}>
              <CandidateBasicCard
                candidate={candidate}
                canViewCompleteProfile={canViewCompleteProfile}
              />
              <Box
                sx={{
                  width: { xs: "1rem", md: "2rem" },
                  height: { xs: "1rem", md: "2rem" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  alignItems: { xs: "stretch", lg: "flex-start" },
                  gap: "1rem",
                }}
              >
                <CandidateResumePdf
                  candidateId={candidate.id}
                  candidateName={candidate.name}
                  resume={candidate.resume}
                />
                <CandidateCoverLetterPdf
                  candidateId={candidate.id}
                  candidateName={candidate.name}
                  coverLetter={candidate.coverLetter}
                />
              </Box>
              <Box
                sx={{
                  width: { xs: "1rem", md: "2rem" },
                  height: { xs: "1rem", md: "2rem" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  alignItems: { xs: "stretch", lg: "flex-start" },
                  gap: "1rem",
                }}
              >
                <CandidateProfessionalGallery candidate={candidate} />
              </Box>
              <Box
                sx={{
                  width: { xs: "1rem", md: "2rem" },
                  height: { xs: "1rem", md: "2rem" },
                }}
              />
              <CandidateDocuments
                candidate={candidate}
                canViewCompleteProfile={canViewCompleteProfile}
              />
              {(isXs || isSm || isMd) && (
                <>
                  <Box
                    sx={{
                      width: { xs: "1rem", md: "2rem" },
                      height: { xs: "1rem", md: "2rem" },
                    }}
                  />
                  <CandidateNotes candidate={candidate} />
                  <Box
                    sx={{
                      width: { xs: "1rem", md: "2rem" },
                      height: { xs: "1rem", md: "2rem" },
                    }}
                  />
                  <CalendarComponent jobseekerId={candidate.profileId} />
                </>
              )}
            </Box>
            <Box
              sx={{
                width: { xs: "1rem", md: "2rem" },
                height: { xs: "1rem", md: "2rem" },
              }}
            />
            <Box
              sx={{
                flex: 0.8,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <CandidateStatusSelect
                candidate={candidate}
                changeReviews={(reviews) =>
                  setCandidate((prev) => ({ ...prev, reviews }))
                }
              />

              {!isXs && !isSm && !isMd && (
                <>
                  <Box
                    sx={{
                      width: { xs: "1rem", md: "2rem" },
                      height: { xs: "1rem", md: "2rem" },
                    }}
                  />
                  <CandidateNotes candidate={candidate} />
                  <Box
                    sx={{
                      width: { xs: "1rem", md: "2rem" },
                      height: { xs: "1rem", md: "2rem" },
                    }}
                  />
                  <CalendarComponent jobseekerId={candidate.profileId} />
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};
