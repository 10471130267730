import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const patchImageComment = ({ image, comment, supportId }) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();

      formData.append("comment", comment.message);
      formData.append("image", image);

      const response = await axiosInstance.patch(`/support/image-comments/${supportId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return handleAxiosResponse(response).data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
