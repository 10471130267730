import {
  Box,
  Button,
  Divider,
  CircularProgress,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import { ElevatedCard } from "../../../../components/elevated-card";
import { useDispatch } from "react-redux";
import { Experience } from "./experience";
import { Education } from "./education";
import { License } from "./license";
import { Medical } from "./medical";
import { Passport } from "./passport";
import { Visa } from "./visa";
import { AdditionalDocument } from "./additional-document";
import { useState } from "react";
import { PDFDisplayModal } from "../../../../components/pdf-modal";

import { CandidatesFeature } from "../../../../features/candidates";
import { icons } from "../../../../icons";
import { FlightExperience } from "./flight-experience";

export const CandidateDocuments = ({ candidate, canViewCompleteProfile }) => {
  const [openPdf, setOpenPdf] = useState(null);
  const [employmentHistoryExpanded, setEmploymentHistoryExpanded] =
    useState(true);
  const [educationExpanded, setEducationExpanded] = useState(true);
  const [flightExperienceExpanded, setFlightExperienceExpanded] =
    useState(true);
  const [licensesExpanded, setLicensesExpanded] = useState(true);
  const [medicalsExpanded, setMedicalsExpanded] = useState(true);
  const [passportsExpanded, setPassportsExpanded] = useState(true);
  const [visasExpanded, setVisasExpanded] = useState(true);
  const [additionalDocumentsExpanded, setAdditionalDocumentsExpanded] =
    useState(true);

  return (
    <ElevatedCard sx={{ p: { xs: "1rem", md: "1.6rem" } }}>
      <PDFDisplayModal openPdf={openPdf} onClose={() => setOpenPdf(null)} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          Candidate Profile
        </Typography>
        {/* <DownloadDocumentsButton candidateId={candidate.id} candidateName={candidate.name} /> */}
      </Box>
      <Divider sx={{ my: { xs: "1rem", md: "1.2rem" } }} />
      <Box>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Employment History
        </Typography>

        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.professionalHistory} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={employmentHistoryExpanded}
            onChange={() =>
              setEmploymentHistoryExpanded((prevExpanded) => !prevExpanded)
            }
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {employmentHistoryExpanded ? "Hide" : "Show"} employment history
                details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer documentsList={candidate.professionalHistory}>
                {candidate.professionalHistory.map((experience) => {
                  return (
                    <Experience key={experience._id} experience={experience} />
                  );
                })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>

      <Box sx={{ mt: "0.6rem" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Education
        </Typography>

        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.education} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={educationExpanded}
            onChange={() =>
              setEducationExpanded((prevExpanded) => !prevExpanded)
            }
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {educationExpanded ? "Hide" : "Show"} education details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer documentsList={candidate.education}>
                {candidate.education.map((education) => {
                  return (
                    <Education key={education._id} education={education} />
                  );
                })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Box sx={{ mt: "0.6rem" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Flight Experience
        </Typography>
        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.professionalHistory} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={flightExperienceExpanded}
            onChange={() =>
              setFlightExperienceExpanded((prevExpanded) => !prevExpanded)
            }
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {flightExperienceExpanded ? "Hide" : "Show"} flight experience
                details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer
                documentsList={
                  candidate?.crewType === "flight crew"
                    ? candidate.flightCrewExperience
                    : candidate.cabinCrewExperience
                }
              >
                {candidate?.crewType === "flight crew" && (
                  <FlightExperience
                    experience={candidate?.flightCrewExperience}
                    crewType={candidate?.crewType}
                  />
                )}
                {candidate?.crewType === "cabin crew" &&
                  candidate?.cabinCrewExperience &&
                  candidate?.cabinCrewExperience.length &&
                  candidate?.cabinCrewExperience?.map((experience) => {
                    return (
                      <FlightExperience
                        key={experience._id}
                        experience={experience}
                        crewType={candidate?.crewType}
                      />
                    );
                  })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Box sx={{ mt: "0.6rem" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Licenses
        </Typography>

        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.licenses} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={licensesExpanded}
            onChange={() =>
              setLicensesExpanded((prevExpanded) => !prevExpanded)
            }
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {licensesExpanded ? "Hide" : "Show"} licenses details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer documentsList={candidate.licenses}>
                {candidate.licenses.map((license) => {
                  return (
                    <License
                      key={license._id}
                      license={license}
                      setOpenPdf={setOpenPdf}
                    />
                  );
                })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Box sx={{ mt: "0.6rem" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Medicals
        </Typography>

        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.medicals} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={medicalsExpanded}
            onChange={() =>
              setMedicalsExpanded((prevExpanded) => !prevExpanded)
            }
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {medicalsExpanded ? "Hide" : "Show"} medicals details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer documentsList={candidate.medicals}>
                {candidate.medicals.map((medical) => {
                  return (
                    <Medical
                      key={medical._id}
                      medical={medical}
                      setOpenPdf={() =>
                        setOpenPdf(
                          `${process.env.REACT_APP_API_BASE_URL}/candidates/${candidate.id}/documents?url=${medical.document.url}`
                        )
                      }
                    />
                  );
                })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Box sx={{ mt: "0.6rem" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Passports
        </Typography>

        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.passports} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={passportsExpanded}
            onChange={() =>
              setPassportsExpanded((prevExpanded) => !prevExpanded)
            }
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {passportsExpanded ? "Hide" : "Show"} passports details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer documentsList={candidate.passports}>
                {candidate.passports.map((passport) => {
                  return (
                    <Passport
                      key={passport._id}
                      passport={passport}
                      setOpenPdf={() =>
                        setOpenPdf(
                          `${process.env.REACT_APP_API_BASE_URL}/candidates/${candidate.id}/documents?url=${passport.document.url}`
                        )
                      }
                    />
                  );
                })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Box sx={{ mt: "0.6rem" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Visas
        </Typography>

        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.visas} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={visasExpanded}
            onChange={() => setVisasExpanded((prevExpanded) => !prevExpanded)}
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {visasExpanded ? "Hide" : "Show"} visas details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer documentsList={candidate.visas}>
                {candidate.visas.map((visa) => {
                  return (
                    <Visa
                      key={visa._id}
                      visa={visa}
                      setOpenPdf={() =>
                        setOpenPdf(
                          `${process.env.REACT_APP_API_BASE_URL}/account/documents?url=${visa.id}/${visa.document.url}`
                        )
                      }
                    />
                  );
                })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>

      <Box sx={{ mt: "0.6rem" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mt: "1rem",
            mb: "0.6rem",
          }}
        >
          Additional Documents
        </Typography>

        {!canViewCompleteProfile ? (
          <FilledContainer>
            <Typography>{candidate.additionalDocuments} - Available</Typography>
          </FilledContainer>
        ) : (
          <Accordion
            sx={{ boxShadow: "none", p: "none" }}
            expanded={additionalDocumentsExpanded}
            onChange={() =>
              setAdditionalDocumentsExpanded((prevExpanded) => !prevExpanded)
            }
          >
            <AccordionSummary
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              expandIcon={icons.expandMore({})}
            >
              <Typography
                // center align
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {additionalDocumentsExpanded ? "Hide" : "Show"} additional
                documents details{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsContainer documentsList={candidate.additionalDocuments}>
                {candidate.additionalDocuments.map((additionalDocument) => {
                  return (
                    <AdditionalDocument
                      key={additionalDocument._id}
                      additionalDocument={additionalDocument}
                      setOpenPdf={setOpenPdf}
                    />
                  );
                })}
              </DocumentsContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </ElevatedCard>
  );
};

const FilledContainer = ({ children }) => {
  return (
    <Box
      sx={{
        padding: "1rem",
        width: "100%",
        borderRadius: "1rem",
        backgroundColor: "#FFFAFA",
        textAlign: "center",
      }}
    >
      {children}
    </Box>
  );
};

const DocumentsContainer = ({ documentsList, children }) => {
  return documentsList.length === 0 ? (
    <FilledContainer>
      <Typography>Not Available</Typography>
    </FilledContainer>
  ) : (
    children
  );
};

const DownloadDocumentsButton = ({ candidateId, candidateName }) => {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    return;
    setDownloading(true);
    await CandidatesFeature.downloadCandidateDocuments({
      candidateId,
      candidateName,
    })(dispatch);
    setDownloading(false);
  };

  return (
    <Button
      sx={{ borderRadius: "2.6rem", height: "2.2rem" }}
      onClick={handleDownload}
      disabled={downloading}
    >
      {downloading ? <CircularProgress size="1.4rem" /> : "Download"}
    </Button>
  );
};
