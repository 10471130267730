import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { icons } from "../../icons";

export const PageBackButton = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(-1)}
      variant="outlined"
      sx={{
        mr: "1rem",
        pl: "0.6rem",
        pr: "1rem",
        py: "0.16rem",
        borderRadius: "2.6rem",
        borderWidth: "0.2rem",
        ":hover": {
          borderWidth: "0.2rem",
        },
      }}
    >
      <Box sx={{ height: "0.8rem", width: "0.8rem", mr: "0.9rem", mb: "0.6rem" }}>
        {icons.arrowLeft({ color: "red" })}
      </Box>
      Back
    </Button>
  );
};
