import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { BillingFeature } from "../../../features/billing";
import { Modal } from "../../../components/backdrop-modal";
import { SectionCard } from "../../../components/section-card";
import { CountrySelect } from "../../../components/selects/country";
import { CustomTextField } from "../../../components/inputs";
import { LoadingButton } from "../../../components/buttons/loading-button";
import { icons } from "../../../icons";

export const BillingAddress = () => {
  const billing = useSelector((state) => state.billing);
  const recruiterProfile = useSelector((state) => state.mainProfile);

  const [editBillingAddress, setEditBillingAddress] = useState(false);

  return (
    <>
      <SectionCard
        title="Billing Address"
        actions={
          <Button
            sx={{ borderRadius: "2rem", padding: "0.2rem 0.4rem" }}
            onClick={() => setEditBillingAddress(true)}
            disabled={recruiterProfile.permission !== "admin"}
          >
            update
          </Button>
        }
      >
        <Box sx={{ mt: "1rem", mb: "-0.4rem" }}>
          <CardRow title="Recipient Email" value={billing.billingAddress.email} />
          <CardRow title="Address Line" value={billing.billingAddress.addressLine ?? "Please Update"} />
          <CardRow title="City" value={billing.billingAddress.city ?? "Please Update"} />
          <CardRow title="State" value={billing.billingAddress.state ?? "Please Update"} />
          <CardRow title="Country" value={billing.billingAddress.country ?? "Please Update"} />
          <CardRow title="ZIP / Postal Code" value={billing.billingAddress.zipCode ?? "Please Update"} />
        </Box>
      </SectionCard>
      <EditBillingAddress open={editBillingAddress} onClose={() => setEditBillingAddress(false)} />
    </>
  );
};

const CardRow = ({ title, value }) => {
  return (
    <Box sx={{ mt: "0.4rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography sx={{ flex: 1, fontSize: "1.08rem", textTransform: "capitalize" }}>{title}</Typography>
      <Typography sx={{ flex: 1, fontSize: "0.96rem" }}>{value}</Typography>
    </Box>
  );
};

const EditBillingAddress = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { billingAddress: oldBillingAddress } = useSelector((state) => state.billing);

  const handleSubmit = async (billingAddress, helpers) => {
    await BillingFeature.updateBillingAddress({ billingAddress })(dispatch);

    helpers.resetForm();

    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: oldBillingAddress.email ?? "",
      addressLine: oldBillingAddress.addressLine ?? "",
      city: oldBillingAddress.city ?? "",
      zipCode: oldBillingAddress.zipCode ?? "",
      state: oldBillingAddress.state ?? "",
      country: oldBillingAddress.country ?? "",
    },
    validationSchema: yup.object({
      email: yup.string().required("email required"),
      addressLine: yup.string().required("address line required"),
      city: yup.string().required("city required"),
      zipCode: yup.string(),
      state: yup.string().matches(/^[a-zA-Z\s]+$/, "Please enter valid name"),
      country: yup.string().required("country required"),
    }),
    onSubmit: handleSubmit,
  });

  const modalHeaderActions = (
    <Box>
      <IconButton onClick={onClose} sx={{ padding: "0.6rem" }}>
        {icons.close({})}
      </IconButton>
    </Box>
  );

  const modalActions = (
    <Box>
      <LoadingButton variant="outlined" onClick={formik.submitForm} isLoading={formik.isSubmitting} fullWidth>
        {formik.isSubmitting ? "Saving" : "Save"}
      </LoadingButton>
    </Box>
  );

  return (
    <Modal
      headerTitle="Edit Billing Information"
      headerActions={modalHeaderActions}
      open={open}
      onClose={onClose}
      modalActions={modalActions}
    >
      <Box component="form" noValidate>
        <CustomTextField
          label="Accounts Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          placeholder="Please enter invoice accounts email"
        />
        <CustomTextField
          label="Address Line"
          name="addressLine"
          value={formik.values.addressLine}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressLine && Boolean(formik.errors.addressLine)}
          helperText={formik.touched.addressLine && formik.errors.addressLine}
          placeholder="Please enter address line"
        />
        <CustomTextField
          label="City"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          placeholder="Please enter city name"
        />
        <CustomTextField
          label="State"
          name="state"
          value={formik.values.state}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
          placeholder="Please enter state"
        />
        <CountrySelect
          label="Country"
          name="country"
          value={formik.values.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.country && Boolean(formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}
          placeholder="Please enter country"
        />
        <CustomTextField
          label="Zip Code"
          name="zipCode"
          value={formik.values.zipCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
          helperText={formik.touched.zipCode && formik.errors.zipCode}
          placeholder="Please enter zip code"
        />
      </Box>
    </Modal>
  );
};
