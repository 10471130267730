import { Box, CircularProgress, Pagination, styled, Typography } from "@mui/material";
import { JobseekerCard } from "./cards/jobseeker.js";
import { ElevatedCard } from "./elevated-card.js";
import { svgIcons } from "../icons/svgs.js";

export const JobseekersContainer = ({ jobseekers, isLoading, currentPage, totalPages, handlePageChange }) => {
  return (
    <ElevatedCard
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        paddingTop: "0.6rem",
        width: "100%",
        borderRadius: "1rem",
        backgroundColor: "#FFFAFA",
        height: "100%",
      }}
    >
      <FlexBox sx={{ alignItems: "stretch", justifyContent: "center" }}>
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: "center" }} />
        ) : (
          <FlexBox sx={{ justifyContent: "center" }}>
            {jobseekers.length !== 0 ? (
              <FlexBox>
                {jobseekers.map((jobseeker) => (
                  <JobseekerCard jobseeker={jobseeker} key={jobseeker.id} />
                ))}
              </FlexBox>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box component="img" src={svgIcons.notFound} sx={{ height: "4rem", width: "4rem" }} />
                <Typography variant="h6" sx={{ mt: "0.6rem", fontWeight: "bold", color: "#C8C8C8" }}>
                  No Jobseekers Found
                </Typography>
              </Box>
            )}
          </FlexBox>
        )}
      </FlexBox>
      <Pagination
        sx={{ mt: { xs: "1rem", sm: "2rem" } }}
        page={currentPage}
        count={totalPages || 1}
        onChange={handlePageChange}
        showFirstButton
        showLastButton
      />

      <style>
        {/* hiding the page numbers */}
        {`
          .MuiPaginationItem-page {
            display: none;
          }
          .MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-ellipsis.css-ci1qpu-MuiPaginationItem-root{
            display: none;
          }
        `}
      </style>
    </ElevatedCard>
  );
};

const FlexBox = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});
