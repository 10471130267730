import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { BillingFeature } from "../../../features/billing";
import { Button } from "@mui/material";
import { Buffer } from "buffer";

export const ReceiptGenerator = ({ id, date }) => {
  const dispatch = useDispatch();

  const handlePdfDownload = async () => {
    const data = await BillingFeature.downloadReceipt({ paymentId: id })(dispatch);

    const base46Pdf = data.pdf;
    const name = data.name;
    const bytes = Buffer.from(base46Pdf, "base64");
    const pdfBlob = new Blob([bytes], { type: "application/pdf" });

    downloadBlob({
      name: `${name} - ${moment(date).format("DD-MM-YYYY")}.pdf`,
      blob: pdfBlob,
    });
  };

  return (
    <Button
      sx={{
        textDecoration: "underline",
        color: "red",
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "white",
          textDecoration: "underline",
        },
      }}
      onClick={handlePdfDownload}
    >
      View
    </Button>
  );
};

function downloadBlob({ name, blob }) {
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = name;
  downloadLink.click();
}
