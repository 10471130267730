import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: {},
  jobseekers: [],
};

export const slice = createSlice({
  name: "jobseekers",

  initialState: { ...initialState },

  reducers: {
    setJobseekers: (state, { payload }) => {
      state.count = payload.count;
      state.jobseekers = payload.jobseekers;
    },
  },
});

export const { reducer, actions } = slice;
