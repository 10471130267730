import { Country } from "country-state-city";
import { Autocomplete, Box, InputBase, Typography } from "@mui/material";

export const CountrySelect = ({ label, name, value, onChange, error, helperText, multiple = false }) => {
  const onSelect = (_, value) => {
    if (value) {
      onChange({ target: { name, value: value } });
    }
  };

  function renderOption(props, option) {
    return (
      <Box value={option} {...props}>
        {option}
      </Box>
    );
  }

  function renderInput(params) {
    const { InputLabelProps, InputProps, ...rest } = params;
    return (
      <InputBase {...InputProps} {...rest} placeholder="Please enter the Country" sx={{ padding: "0.44rem 0.88rem" }} />
    );
  }

  return (
    <Box sx={{ my: "0.8rem" }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>

      <Autocomplete
        value={value}
        options={Country.getAllCountries().map(({ name }) => name)}
        onChange={onSelect}
        sx={{ borderRadius: "4px", backgroundColor: "#F4F4FB", paddingRight: "1rem" }}
        renderOption={renderOption}
        renderInput={renderInput}
        multiple={multiple}
        defaultValue={multiple ? [] : null}
      />

      {helperText && (
        <Typography variant="subtitle2" sx={{ m: "0.6rem", color: error ? "red" : "black" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
