import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const fetcExistingJob = ({ jobId }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/jobs/${jobId}`);
      const { data } = handleAxiosResponse(response);

      dispatch(actions.setDraftId({ draftId: null }));
      dispatch(actions.setFormState({ formState: data }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
