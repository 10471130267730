import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";

export const fetchPostedJobsTitle = () => {
  return async (dispatch) => {
    try {
      const companyProfile = getAppStoreCurrentState().companyProfile;

      const response = await axiosInstance.get("/jobs/titles", {
        params: { companyId: companyProfile.id },
      });

      return handleAxiosResponse(response).data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
