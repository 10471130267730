export const AppRouteConstants = Object.freeze({
  signUp: "/authentication?action=sign-up",

  verifyAccount: "/authentication?action=verify-account",

  createOrJoinCompany: "/authentication?action=create-or-join-company",

  signIn: "/authentication?action=sign-in",

  forgotPassword: "/authentication?action=forgot-password",

  resetPassword: "/authentication?action=reset-password",

  jobs: "/",

  viewJob: "/jobs/view",

  postJob: "/jobs/post",

  editJob: "/jobs/edit",

  support: "/support",

  viewSupport: "/support/view",

  candidates: "/candidates",

  viewCandidate: "/candidates/view",

  jobseekers: "/jobseekers",

  viewJobseeker: "/jobseekers/view",

  conversations: "/conversations",

  viewConversation: "/conversations/view",

  account: "/account",

  team: "/company-account?tab=team",

  mySubscription: "/company-account?tab=my%20subscription",

  billingSettings: "/company-account?tab=billing%20settings",

  schedules: "/shedules",

  resumes: "/resumes",

  about: "/about-us",

  contact: "/contact-us",

  terms: "/terms-and-conditions",

  privacy: "/privacy-policy",

  faqs: "/faqs",
});
