import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: {},
  "awaiting review": {},
  reviewed: {},
  shortlisted: {},
  interviewing: {},
  rejected: {},
  hired: {},
};

export const slice = createSlice({
  name: "candidates",

  initialState: { ...initialState },

  reducers: {
    setCandidates: (state, { payload }) => {
      state.count = payload.count;
      state[payload.status][payload.currentPage] = payload.candidates;
    },
  },
});

export const { reducer, actions } = slice;
