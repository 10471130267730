import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const saveToDraft = ({ jobInfo }) => {
  return async (dispatch) => {
    try {
      const { mainProfile, postJobForm } = getAppStoreCurrentState();

      let formData = new FormData();

      formData.append("image", jobInfo?.logo ?? postJobForm?.formState?.logo ?? null);

      delete jobInfo?.logo;

      formData.append(
        "jobInfo",
        JSON.stringify(postJobForm?.draftId === null ? { ...jobInfo, ...postJobForm.formState } : jobInfo)
      );

      formData.append("draftId", postJobForm?.draftId ?? null);

      const response = await axiosInstance.post(`/jobs/${mainProfile.email}/drafts`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const { data } = handleAxiosResponse(response);

      dispatch(actions.setDraftId({ draftId: data.id }));
      dispatch(actions.setFormState({ formState: data.jobInfo }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
