import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { SignOutButton } from "../buttons/sign-out";
import { icons } from "../../icons";
import { Logo } from "../logo";
import { SideBarLinkTile } from "./link";

const SideBarContent = (props) => {
  const newMessagesCount = useSelector((state) => state.conversations.newMessagesCount);

  return (
    <Box
      sx={{
        height: "100%",
        margin: { xs: "1rem", md: "2rem" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Logo height="3rem" />
        <Divider color="white" sx={{ my: "1rem" }} />
        <SideBarLinkTile href="/jobs/post" title="Post New Job" icon={icons.work} backgroundColor="primary.main" />
        <SideBarLinkTile href="/" title="Jobs" icon={icons.work} pathIndicator="jobspage" />
        <SideBarLinkTile
          href="/candidates?tab=awaiting review"
          title="Candidates"
          icon={icons.people}
          pathIndicator="/candidates"
        />
        <SideBarLinkTile
          href="/jobseekers"
          title="Jobseekers Directory"
          icon={icons.storage}
          pathIndicator="/jobseekers"
        />
        <Badge
          sx={{ position: "absolute", right: { xs: "1.2rem", md: "2rem" } }}
          badgeContent={newMessagesCount > 10 ? "10+" : newMessagesCount}
          color="primary"
        ></Badge>
        <SideBarLinkTile
          href="/conversations"
          title="Conversations"
          icon={icons.forum}
          pathIndicator="/conversations"
        />
        <SideBarLinkTile href="/account" title="Account" icon={icons.account} pathIndicator="/account" />
        <SideBarLinkTile
          href="/company-account"
          title="Company"
          icon={icons.apartment}
          pathIndicator="/company-account"
        />
        <SideBarLinkTile href="/support" title="Support" icon={icons.helpCenter} pathIndicator="/support" />
      </Box>
      <SignOutButton variant="contained" />
    </Box>
  );
};

const MemoizedSideBarContent = React.memo(SideBarContent);

export { MemoizedSideBarContent as SideBarContent };
