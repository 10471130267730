import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const fetchNewMessagesCount = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations/count/new-messages`
      );
      const { data } = handleAxiosResponse(response);

      dispatch(actions.setNewMessagesCount(data));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
