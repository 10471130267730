import { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { ElevatedCard } from "../../../components/elevated-card";
import { Modal } from "../../../components/backdrop-modal";
import { PDFView } from "../../../components/pdf-viewer";
import { CandidatesFeature } from "../../../features/candidates";
import { useDispatch } from "react-redux";

export const CandidateCoverLetterPdf = ({
  candidateId,
  candidateName,
  coverLetter,
}) => {
  const [viewCoverLetter, setViewCoverLetter] = useState(false);
  const [noCoverLetterModalOpen, setNoCoverLetterModalOpen] = useState(false);
  return (
    <ElevatedCard
      sx={{ width: "100%", p: { xs: "1rem", md: "1.6rem" }, flex: "0.5" }}
    >
      <Box width="100%">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            Cover Letter
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ borderRadius: "2.6rem", height: "2.2rem" }}
              onClick={() => {
                if (typeof coverLetter === "boolean" && coverLetter)
                  return setViewCoverLetter((prev) => !prev);
                setNoCoverLetterModalOpen(true);
              }}
            >
              View
            </Button>
          </Box>
        </Box>
        <Modal
          headerTitle="Candidate Cover Letter"
          open={viewCoverLetter}
          onClose={() => setViewCoverLetter((prev) => !prev)}
        >
          <PDFView
            url={`${process.env.REACT_APP_API_BASE_URL}/candidates/${candidateId}/cover-letters`}
          />
        </Modal>
      </Box>
      <NoCoverLetterModalOrText
        open={noCoverLetterModalOpen}
        onClose={() => setNoCoverLetterModalOpen(false)}
        coverLetter={coverLetter}
      />
    </ElevatedCard>
  );
};

const NoCoverLetterModalOrText = ({ open, onClose, coverLetter }) => {
  return (
    <Modal
      headerTitle={
        coverLetter?.cover_letter_type === "text" && coverLetter?.text
          ? "Cover Letter"
          : "No Cover Letter"
      }
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        {coverLetter?.cover_letter_type === "text" && coverLetter?.text ? (
          coverLetter?.text.split("\n").map((line, index) => (
            <Typography key={index} sx={{ fontSize: "1rem" }}>
              {line}
            </Typography>
          ))
        ) : (
          <Typography
            sx={{ fontSize: "1.2rem", fontWeight: "bold", textAlign: "center" }}
          >
            This candidate has not added the cover letter.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};
