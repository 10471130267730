import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const clearDraftState = () => {
  return async (dispatch) => {
    try {
      dispatch(actions.clearDraftAndForm());
      dispatch(actions.clearLimits());
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
