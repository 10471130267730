import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostJobFormFeature } from "../../../features/post-job-form";
import { ElevatedCard } from "../../../components/elevated-card";
import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { CustomTextField } from "../../../components/inputs";
import { CustomSwitch } from "../../../components/inputs/switch";
import moment from "moment";
import CustomDatePicker from "../../../components/date-picker";
import { ImagePicker } from "../../../components/image-picker";
import { icons } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { AppRouteConstants } from "../../../navigations/routes";
import { Modal } from "../../../components/backdrop-modal";

export const JobInsightsStep = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { formState } = useSelector((state) => state.postJobForm);
  const companyProfile = useSelector((state) => state.companyProfile);
  const { openFor, expectedStartDate, sponsor, isRecruitingForOthers, logo } = formState;
  const [saving, setSaving] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      openFor: openFor ?? "",
      sponsor: sponsor ?? false,
      isRecruitingForOthers: isRecruitingForOthers ?? false,
      logo: logo ?? null,
      expectedStartDate: expectedStartDate !== null ? moment(expectedStartDate).toDate() : null,
    },
    validationSchema: yup.object({
      openFor: yup.string(),
      expectedStartDate: yup.date().nullable(),
      sponsor: yup.bool(),
      isRecruitingForOthers: yup.bool(),
      logo: yup.mixed(),
    }),
    onSubmit: async (insightInfo) => {
      if (formik.values.isRecruitingForOthers && !formik.values.logo) {
        setLogoError(true);
        return;
      }
      setSaving(true);
      await PostJobFormFeature.saveToDraft({ jobInfo: insightInfo })(dispatch);
      setSaving(false);
      onNext();
    },
  });

  const handleStartDateChange = (date) => {
    formik.setFieldValue("expectedStartDate", date ?? null);
  };

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          padding: { xs: "0rem 1.2rem", sm: "0.6rem 2rem 2rem 2rem" },
          backgroundColor: "#FFFAFA",
        }}
      >
        <CustomTextField
          label="How many are you looking to hire?"
          name="openFor"
          type="number"
          value={formik.values.openFor}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.openFor && formik.errors.openFor)}
          helperText={formik.touched.openFor && formik.errors.openFor}
          placeholder="Open For"
        />
        <CustomDatePicker
          label="Expected Start date"
          name="expectedStartDate"
          value={formik.values.expectedStartDate}
          views={["year", "month", "day"]}
          onChange={handleStartDateChange}
          inputFormat="dd-MM-yyyy"
        />
        <CustomSwitch
          label="Would you like to sponsor this job?"
          checked={formik.values.sponsor}
          onChange={(checked) => formik.setFieldValue("sponsor", checked)}
        />
        <CustomSwitch
          label="Are you recruiting for some other company?"
          checked={formik.values.isRecruitingForOthers}
          onChange={(checked) => {
            if (checked && companyProfile?.currentPlan === "lite") {
              setUpgradeModalOpen(true);
              formik.setFieldValue("isRecruitingForOthers", false);
              return;
            }
            formik.setFieldValue("isRecruitingForOthers", checked);
          }}
        />
        {formik.values.isRecruitingForOthers && (
          <>
            <ImagePicker
              imageAttachment={true}
              src={formik.values.logo?.download_path ? `${formik.values.logo?.download_path}` : formik.values.logo}
              pickedImage={
                formik.values.logo?.download_path ? `${formik.values.logo?.download_path}` : formik.values.logo
              }
              onPickImage={(logo) => formik.setFieldValue("logo", logo)}
              setImageError={setLogoError}
              customMessage="Upload the logo of the company you are recruiting for"
            />
            {logoError && <Typography sx={{ color: "red" }}>Please select an image</Typography>}
          </>
        )}
      </ElevatedCard>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onBack} sx={{ borderRadius: "1rem" }}>
          Back
        </Button>
        <Box sx={{ width: "2rem" }} />
        <Button onClick={formik.submitForm} sx={{ borderRadius: "1rem" }} disabled={saving}>
          {saving ? <CircularProgress size="1.4rem" /> : "Continue"}
        </Button>
      </Box>
      <UpgradeToPremiumModal
        open={upgradeModalOpen}
        onClose={() => {
          setUpgradeModalOpen(false);
        }}
      />
    </Fragment>
  );
};

const UpgradeToPremiumModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerTitle="Upgrade to the enterprise plan"
      headerActions={
        <IconButton onClick={onClose} sx={{ padding: "0.6rem" }}>
          {icons.close({ fontSize: "1.2rem" })}
        </IconButton>
      }
    >
      <Box
        sx={{
          pt: "1rem",
          flex: 0.7,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
          Unlock the power of team collaboration with our Enterprise plan - post jobs for others today!
        </Typography>
        <Box sx={{ height: "1rem" }} />
        <Button
          variant="contained"
          sx={{
            borderRadius: "2rem",
          }}
          onClick={() => {
            navigate(AppRouteConstants.mySubscription);
            onClose();
          }}
        >
          Upgrade
        </Button>
      </Box>
    </Modal>
  );
};
