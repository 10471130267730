import { Box, Typography } from "@mui/material";
import moment from "moment";

export const Visa = ({ visa, setOpenPdf }) => {
  return (
    <Box
      sx={{
        padding: "0.8rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "0.6rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
          {visa.country}
        </Typography>
        <Typography variant="subtitle2">
          {moment(visa.expires_on).isBefore(moment())
            ? "Expired on"
            : "Expires on"}{" "}
          {moment(visa.expires_on).format("MMM DD, YYYY")}.
        </Typography>
        <Typography
          onClick={setOpenPdf}
          color="primary.main"
          sx={{
            width: "max-content",
            cursor: "pointer",
            fontSize: "0.8rem",
            ":hover": {
              textDecoration: "underline",
              textUnderlineOffset: "2px",
            },
          }}
        >
          View Visa
        </Typography>
      </Box>
    </Box>
  );
};
