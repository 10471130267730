import FileDownload from "js-file-download";
import { axiosInstance } from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";

export const downloadCandidateResume = ({ candidateId, candidateName }) => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      if (companyProfile.currentPlan === "lite") {
        return handleError("Cannot download resume, please upgrade your plan to download resumes.")(dispatch);
      }

      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/candidates/${candidateId}/resumes`,
        {
          responseType: "blob",
        }
      );

      FileDownload(response.data, `${candidateName}-resume.pdf`);
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
