import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const addFeedback = ({ feedbackInfo, supportId }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`/support/feedback/${supportId}`, {
        feedbackInfo,
      });

      const { data } = handleAxiosResponse(response);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
