import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const sendMessage = ({ conversationId, messageBody }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations/${conversationId}/messages`,
        messageBody
      );
      const { data, message } = handleAxiosResponse(response);
      dispatch(actions.updateConversationLatestMessage({ conversationId, message: data }));
      dispatch(actions.addMessage({ conversationId, message: data }));

      return { data, message };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
