import { Box, Divider, IconButton, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { useState } from "react";
import { ImagePicker } from "../../../components/image-picker";
import { icons } from "../../../icons";
import { Modal } from "../../../components/modals";
import { LoadingButton } from "../../../components/buttons/loading-button";
import { SupportFeature } from "../../../features/support";

export const UploadAttachmentImage = ({ open, onClose, ticketId }) => {
  const dispatch = useDispatch();
  const [imageError, setImageError] = useState(false);

  const formik = useFormik({
    initialValues: { image: null, caption: "" },

    onSubmit: async ({ image, caption }, helpers) => {
      if (image === null) {
        setImageError(true);
        return;
      }
      if (imageError) return;
      const resp = await SupportFeature.patchImageComment({
        image,
        comment: { message: caption ?? "" },
        supportId: ticketId,
      })(dispatch);

      setImageError(false);
      helpers.resetForm();

      onClose(resp);
    },
  });

  const selectImage = (image) => {
    formik.setFieldValue("image", image);
  };

  return (
    <Modal open={open} onClose={onClose} disableEscapeKeyDown={false}>
      <Box
        sx={{
          flex: 0.3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textTransform: "capitalize" }}
        >
          Upload Attachment Image
        </Typography>
        <IconButton onClick={onClose} sx={{ padding: "0.6rem" }}>
          {icons.close({ fontSize: "1.2rem" })}
        </IconButton>
      </Box>
      <Divider sx={{ mt: "0.6rem" }} />
      <ImagePicker
        imageAttachment={true}
        src={formik.values.image}
        pickedImage={formik.values.image}
        onPickImage={selectImage}
        setImageError={setImageError}
      />
      {imageError && (
        <Typography
          variant="body2"
          sx={{ color: "error.main", textAlign: "start", mb: "0.6rem" }}
        >
          Please select an image
        </Typography>
      )}
      <TextField
        id="caption"
        label="Caption"
        variant="outlined"
        fullWidth
        multiline
        rows={2}
        disabled={formik.isSubmitting}
        onChange={formik.handleChange}
        value={formik.values.caption}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box></Box>
        <LoadingButton
          variant="contained"
          onClick={formik.submitForm}
          isLoading={formik.isSubmitting}
          fullWidth
        >
          {formik.isSubmitting ? (
            "Sending"
          ) : (
            <>Send Message{icons.send({ sx: { ml: "1rem" } })}</>
          )}
        </LoadingButton>
      </Box>
    </Modal>
  );
};
