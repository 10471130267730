export const svgIcons = Object.freeze({
  comingSoon: "/svgs/coming-soon.svg",
  conversation: "/svgs/conversation.svg",
  notFound: "/svgs/not-found.svg",
  search: "/svgs/search.svg",
  website: "/svgs/website.svg",
  linkedin: "/svgs/linkedin.svg",
  twitter: "/svgs/twitter.svg",
  resumeDone: "/svgs/resume-done.svg",
  resume: "/svgs/resume.svg",
  searchJobs: "/svgs/search-jobs.svg",
});
