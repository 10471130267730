import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ImageAvatar } from "../../../components/image-avatar";
import { MainProfileFeature } from "../../../features/main-profile";
import { Fragment, useState } from "react";
import { EditRecruiterProfile } from "./edit-recruiter-profile";
import { SupportPin } from "../../../components/reference-pin";
import { ElevatedCard } from "../../../components/elevated-card";
import { icons } from "../../../icons";

export const RecruiterProfileCard = (props) => {
  const dispatch = useDispatch();

  const { mainProfile } = useSelector((state) => state);

  const [edit, setEdit] = useState(false);

  const onImageUpload = async (image) => {
    await MainProfileFeature.uploadProfileImage({ image })(dispatch);
  };

  return (
    <Fragment>
      <ElevatedCard
        sx={{
          mx: { xs: "1rem", md: "1.6rem" },
          padding: { xs: "1.2rem", md: "1.6rem" },
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          color: "white",
          backgroundImage: "linear-gradient(to right, #A060D0, #C46565)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "start",
          }}
        >
          <ImageAvatar image={mainProfile.image} onUpload={onImageUpload} recruiter={true}>
            Your <br /> Picture
          </ImageAvatar>
          <Box sx={{ width: "2rem", height: "0.4rem" }} />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: "bold",
                }}
              >
                {mainProfile.name}
              </Typography>
              <PermissionChip permission={mainProfile.permission} />
            </Box>
            <Typography
              sx={{
                fontSize: "1rem",
                color: "rgba(255,255,255,0.8)",
              }}
            >
              {mainProfile.email}
            </Typography>
            <Box sx={{ my: "0.3rem" }}>
              <SupportPin supportPin={mainProfile.supportPin} />
            </Box>
          </Box>
        </Box>
        <IconButton
          sx={{
            padding: "0.6rem",
            margin: "-0.6rem",
            color: "white",
          }}
          onClick={() => setEdit(true)}
        >
          {icons.editPen({})}
        </IconButton>
      </ElevatedCard>
      <EditRecruiterProfile
        recruiterProfile={mainProfile}
        open={edit}
        onClose={() => {
          setEdit(false);
        }}
      />
    </Fragment>
  );
};

const PermissionChip = ({ permission }) => {
  return (
    <Typography
      sx={{
        fontSize: "0.88rem",
        ml: "0.6rem",
        padding: "0rem 0.6rem",
        backgroundColor: "primary.main",
        color: "white",
        borderRadius: "0.4rem",
        textTransform: "uppercase",
      }}
    >
      {permission}
    </Typography>
  );
};
