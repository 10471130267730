import {
  Box,
  CircularProgress,
  Pagination,
  styled,
  Typography,
} from "@mui/material";
import { ElevatedCard } from "../../../components/elevated-card";
import { TicketCard } from "../../../components/cards/ticket";
import { svgIcons } from "../../../icons/svgs";

export const TicketsContainer = ({
  tickets,
  isLoading,
  currentPage,
  totalPages,
  handlePageChange,
}) => {
  return (
    <ElevatedCard
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        paddingTop: "0.6rem",
        width: "100%",
        borderRadius: "1rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <FlexBox sx={{ alignItems: "stretch", justifyContent: "center" }}>
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: "center" }} />
        ) : (
          <FlexBox sx={{ justifyContent: "center" }}>
            {tickets.length !== 0 ? (
              <FlexBox>
                {tickets.map((ticket) => (
                  <TicketCard ticket={ticket} key={ticket.id} />
                ))}
              </FlexBox>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src={svgIcons.notFound}
                  sx={{ height: "4rem", width: "4rem" }}
                />
                <Typography
                  variant="h6"
                  sx={{ mt: "0.6rem", fontWeight: "bold", color: "#C8C8C8" }}
                >
                  No Tickets Found
                </Typography>
              </Box>
            )}
          </FlexBox>
        )}
      </FlexBox>
      <Pagination
        sx={{ mt: { xs: "1rem", sm: "2rem" } }}
        page={currentPage}
        count={totalPages}
        onChange={handlePageChange}
      />
    </ElevatedCard>
  );
};

const FlexBox = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});
