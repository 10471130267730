import { format } from "date-fns";
import ChevronLeftIcon from "@untitled-ui/icons-react/build/esm/ChevronLeft";
import ChevronRightIcon from "@untitled-ui/icons-react/build/esm/ChevronRight";
import { Box, IconButton, SvgIcon, Typography } from "@mui/material";

export const CalendarToolbar = (props) => {
  const { date, onDateNext, onDatePrev, onDateToday, ...other } = props;

  return (
    <Box
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={onDatePrev} sx={{ padding: "0.6rem" }}>
          <SvgIcon>
            <ChevronLeftIcon />
          </SvgIcon>
        </IconButton>
        <Typography sx={{ fontWeight: "bold", mx: "0.6rem" }}>
          {format(date, "MMMM, y")}
        </Typography>
        <IconButton onClick={onDateNext} sx={{ padding: "0.6rem" }}>
          <SvgIcon>
            <ChevronRightIcon />
          </SvgIcon>
        </IconButton>
      </Box>
    </Box>
  );
};
