import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";

import { handleError } from "../../app-messages";
import { actions as mainProfileActions } from "../../main-profile/slice";

import { actions } from "../slice";

export const signUp = ({ profileDetails, plan = null }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("/authentication/sign-up", {
        profileDetails,
        plan,
      });

      const { data } = handleAxiosResponse(response);

      const { companyProfile, recruiterProfile } = data;

      appLocalStorage.save({
        ...(companyProfile && { [lskeys.companyProfile]: companyProfile }),
        [lskeys.recruiterProfile]: recruiterProfile,
      });

      dispatch(mainProfileActions.setProfile({ profile: recruiterProfile }));
      dispatch(actions.login());

      return { verified: recruiterProfile.verified };
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
