import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import "@fullcalendar/timeline/main.css";
import Calendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, Typography } from "@mui/material";
import { CalendarToolbar } from "./calendar-components/calendar-toolbar";
import { CalendarContainer } from "./calendar-components/calendar-container";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ElevatedCard } from "../../../components/elevated-card";
import { icons } from "../../../icons";
import { CalendarFeature } from "../../../features/calendar";

const useEvents = ({ jobseekerId }) => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.calendar?.events);

  const getEvents = useCallback(async () => {
    await CalendarFeature.fetchEvents({ jobseekerId })(dispatch);
  }, [dispatch, jobseekerId]);

  useEffect(() => {
    (async () => {
      await getEvents();
    })();
  }, []);
  return events.map((event) => {
    let ev = {
      start: moment(event.start).format("YYYY-MM-DD"),
      end: moment(event.end).format("YYYY-MM-DD"),
      available: event.available,
      id: event.id,
    };

    return ev;
  });
};

const CalendarComponent = ({ jobseekerId }) => {
  const calendarRef = useRef(null);
  // const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const events = useEvents({ jobseekerId });

  const handleDateToday = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  }, []);
  const handleDatePrev = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDateNext = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, []);

  const customDayCellContent = (arg) => {
    let ev;
    let bool;

    events.find((event) => {
      bool = event.start === moment(arg.date).format("YYYY-MM-DD");
      if (bool) {
        ev = {
          start: event.start,
          end: event.end,
          available: event.available,
          id: event.id,
        };
      }
      return bool;
    });

    if (bool) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={{ alignSelf: "flex-end" }}>
            {arg.dayNumberText}
          </Typography>

          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mt: "-1rem",
            }}
          >
            {ev?.available === false
              ? icons.flight({
                  color: "error",
                  sx: {
                    fontSize: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
                    transform: "rotate(45deg)",
                    mt: "1rem",
                  },
                })
              : icons.flight({
                  color: "disabled",
                  sx: {
                    fontSize: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
                    transform: "rotate(45deg)",
                    mt: "1rem",
                  },
                })}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={{ alignSelf: "flex-end" }}>
            {arg.dayNumberText}
          </Typography>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // mt: "-1rem",
            }}
          >
            {icons.flight({
              color: "success",
              sx: {
                fontSize: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
                transform: "rotate(45deg)",
              },
            })}
          </Box>
        </Box>
      );
    }
  };

  return (
    <Fragment>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Jobseeker Availability Calendar</Typography>
          <CalendarToolbar
            date={date}
            onDateNext={handleDateNext}
            onDatePrev={handleDatePrev}
            onDateToday={handleDateToday}
          />
        </Box>
        <ElevatedCard sx={{ padding: "0rem" }}>
          <CalendarContainer>
            <Calendar
              ref={calendarRef}
              eventDisplay="block"
              events={events}
              displayEventTime={false}
              headerToolbar={false}
              height="600px"
              initialDate={date}
              initialView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              rerenderDelay={10}
              longPressDelay={0}
              editable={true}
              touchScroll={true}
              selectable={true}
              dayCellContent={customDayCellContent}
              eventDidMount={(arg) => {
                arg.el.style.backgroundColor = "transparent";
                arg.el.style.border = "none";
                arg.el.style.color = "black";
              }}
            />
          </CalendarContainer>
        </ElevatedCard>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            mt: "0.8rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {icons.flight({
              color: "success",
              sx: { transform: "rotate(45deg)" },
            })}
            <Typography sx={{ fontWeight: "bold", mx: "0.6rem" }}>
              Available
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {icons.flight({
              color: "error",
              sx: { transform: "rotate(45deg)" },
            })}
            <Typography sx={{ fontWeight: "bold", mx: "0.6rem" }}>
              Booked
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {icons.flight({
              color: "disabled",
              sx: { transform: "rotate(45deg)" },
            })}
            <Typography sx={{ fontWeight: "bold", mx: "0.6rem" }}>
              Not Available
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CalendarComponent;
