import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { handleError } from "../../app-messages";

export const patchComment = ({ comment, supportId }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`/support/comments/${supportId}`, { comment });

      return handleAxiosResponse(response).data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
