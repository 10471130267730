import { Box, Modal as MuiModal } from "@mui/material";

export const Modal = ({
  open,
  onClose,
  closeOnBackdropClick = false,
  maxWidth = "38rem",
  minWidth = { xs: "90%", sm: "26rem" },
  width = "80%",
  maxHeight = "80vh",
  height = "auto",
  children,
  right,
  top,
  disableEscapeKeyDown,
  padding = "0.7rem 1.4rem 1.2rem 1.4rem",
  backgroundColor = "white",
  sx,
}) => {
  const handleClose = (_, event) => {
    if (closeOnBackdropClick) {
      if (event === "backdropClick") {
        return;
      }
    }

    onClose();
  };

  return (
    <MuiModal
      sx={{ ...sx }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          backgroundColor,
          width: width,
          maxWidth,
          // maxHeight,
          minWidth: minWidth,
          height: height,
          padding: padding,
          borderRadius: "0.8rem",
          position: "absolute",
          top: top !== undefined ? top : "50%",
          right: right !== undefined ? right : "",
          left: right !== undefined ? "" : "50%",
          transform: right !== undefined ? "" : "translate(-50%, -50%)",
          outline: "none",
        }}
      >
        {children}
      </Box>
    </MuiModal>
  );
};
