import { fetchCandidateDetails } from "./use-cases/fetch-candidate-details";
import { downloadCandidateResume } from "./use-cases/download-candidate-resume";
import { downloadCandidateCoverLetter } from "./use-cases/download-candidate-cover-letter";
import { downloadCandidateDocuments } from "./use-cases/download-candidate-documents";
import { fetchCandidates } from "./use-cases/fetch-candidates";
import { fetchCandidatesByCompany } from "./use-cases/fetch-candidates-company";
import { reviewCandidate } from "./use-cases/update-candidate-status";
import { addCandidateNotes } from "./use-cases/add-candidate-notes";
import { reviewMultiCandidates } from "./use-cases/review-multi-candidates";

class Feature {
  constructor() {
    Object.freeze(this);
  }

  fetchCandidateDetails = fetchCandidateDetails;

  downloadCandidateResume = downloadCandidateResume;

  downloadCandidateCoverLetter = downloadCandidateCoverLetter;

  downloadCandidateDocuments = downloadCandidateDocuments;

  fetchCandidates = fetchCandidates;

  fetchCandidatesByCompany = fetchCandidatesByCompany;

  reviewCandidate = reviewCandidate;

  addCandidateNotes = addCandidateNotes;

  reviewMultiCandidates = reviewMultiCandidates;
}

export const CandidatesFeature = new Feature();
