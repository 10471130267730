import { Avatar, Box, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ElevatedCard } from "../elevated-card";

export const JobseekerCard = ({ jobseeker }) => {
  const { id, isPremium, image, name, role, crewType } = jobseeker;

  const navigate = useNavigate();

  return (
    <ElevatedCard
      sx={{
        padding: "1rem",
        mt: { xs: "1rem", sm: "1.2rem" },
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "1rem",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/jobseekers/view?jobseekerId=${id}`)}
    >
      <Avatar src={image} sx={{ height: "3.6rem", width: "3.6rem" }} />
      <Box sx={{ flex: 1, ml: "1rem" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{name}</Typography>
          {isPremium && (
            <Chip
              sx={{
                ml: "1rem",
                padding: "0px",
                height: "1.6rem",
                textTransform: "uppercase",
              }}
              label="featured"
            />
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2">{role}</Typography>
          <Typography
            variant="subtitle2"
            sx={{ textTransform: "capitalize", mr: "0.5rem", mt: "0.2rem" }}
          >
            {crewType}
          </Typography>
        </Box>
      </Box>
    </ElevatedCard>
  );
};
