import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store/index";

import { handleError } from "../../app-messages";

export const fetchReceipts = ({ pageNo }) => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.get(`/billing/${companyProfile.stripeCustomerId}/receipts`, {
        params: { pageNo, pageSize: 5 },
      });

      const { data } = handleAxiosResponse(response);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
