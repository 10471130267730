import { Typography } from "@mui/material";
import { ElevatedCard } from "../elevated-card";

export const InsightCard = ({ title, value, active, ...props }) => {
  return (
    <ElevatedCard
      sx={{
        flex: 1,
        padding: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "1rem",
        cursor: "pointer",
        backgroundImage: active ? "linear-gradient(to right, #A060D0, #C46565)" : "none",
        boxShadow: active && "0px 0px 8px 4px #EFEFEF",
        color: active ? "white" : "black",
        ":hover": {
          boxShadow: "0px 0px 8px 4px #EFEFEF",
        },
      }}
      {...props}
    >
      <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
    </ElevatedCard>
  );
};
