import { PlanActionButton } from "../../../components/pricing-table/plan-action-button";

const LiteSubscription = ({ onClick, isAdmin, currentPlan, submitting }) => {
  return (
    <PlanActionButton onClick={onClick} disabled={!isAdmin || currentPlan === "lite" || submitting}>
      {currentPlan === "lite" ? "Active" : "Downgrade"}
    </PlanActionButton>
  );
};

const ProSubscription = ({ onClick, isAdmin, currentPlan, canUseTrial, submitting }) => {
  let buttonText;

  if (canUseTrial) {
    buttonText = "Start Free Trial";
  } else if (currentPlan === "pro") {
    buttonText = "Active";
  } else if (currentPlan === "enterprise") {
    buttonText = "Downgrade";
  } else {
    buttonText = "Upgrade";
  }

  const isDisabled = !isAdmin || currentPlan === "pro" || submitting;

  return (
    <PlanActionButton onClick={onClick} disabled={isDisabled}>
      {buttonText}
    </PlanActionButton>
  );
};

const EnterpriseSubscription = ({ onClick, isAdmin, currentPlan, canUseTrial, submitting }) => {
  let buttonText;

  if (canUseTrial) {
    buttonText = "Start Free Trial";
  } else if (currentPlan === "enterprise") {
    buttonText = "Active";
  } else {
    buttonText = "Upgrade";
  }

  const isDisabled = !isAdmin || currentPlan === "enterprise" || submitting;

  return (
    <PlanActionButton onClick={onClick} disabled={isDisabled}>
      {buttonText}
    </PlanActionButton>
  );
};

export const SubscribeToButtons = { LiteSubscription, ProSubscription, EnterpriseSubscription };
