import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const markMessageAsRead = ({ conversationId, messageId }) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch(
        `${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations/${conversationId}/messages/${messageId}`
      );

      const { data } = handleAxiosResponse(response);

      dispatch(actions.updateConversationLatestMessage({ conversationId, message: data }));
      dispatch(actions.updateNewMessagesCount(-1));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
