import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  supportPin: "",
  stripeCustomerId: "",
  currentPlan: "",
  name: "",
  email: "",
  image: "",
  about: "",
  website: "",
  linkedIn: "",
  twitter: "",
  profileCompleted: false,
};

export const slice = createSlice({
  name: "companyProfile",

  initialState: { ...initialState },

  reducers: {
    setProfile: (state, { payload }) => {
      state.id = payload.profile.id;
      state.supportPin = payload.profile.supportPin;
      state.stripeCustomerId = payload.profile.stripeCustomerId;
      state.currentPlan = payload.profile.currentPlan;
      state.image = payload.profile.image;
      state.name = payload.profile.name;
      state.email = payload.profile.email;
      state.about = payload.profile.about;
      state.website = payload.profile.website;
      state.linkedIn = payload.profile.socials.linkedIn;
      state.twitter = payload.profile.socials.twitter;
      state.profileCompleted = payload.profile.profileCompleted;
    },

    setProfileImage: (state, { payload }) => {
      state.image = payload.image;
    },

    changeCurrentPlan: (state, { payload }) => {
      state.currentPlan = payload.currentPlan;
    },

    clearProfile: () => ({ ...initialState }),
  },
});

export const { reducer, actions } = slice;
