import jwtDecode from "jwt-decode";

export const checkIfTokenExpired = (token) => {
  try {
    return jwtDecode(token).exp * 1000 < Date.now();
  } catch (error) {
    return true;
  }
};

export const containsInExceptionList = (str) => {
  for (const elem of exceptions) {
    if (str.includes(elem)) return true;
  }
};

const exceptions = [
  "/authentication/sign-in",
  "/authentication/sign-up",
  "/authentication/password-reset/token",
  "/authentication/verifications/email",
];
