import { axiosInstance } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const discardDraft = () => {
  return async (dispatch) => {
    try {
      const { mainProfile, postJobForm } = getAppStoreCurrentState();

      await axiosInstance.delete(`/jobs/${mainProfile.email}/drafts`, {
        params: { draftId: postJobForm.draftId },
      });

      dispatch(actions.clearDraftAndForm());
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
