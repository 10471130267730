import FileDownload from "js-file-download";
import { axiosInstance } from "../../../core/sources/network";

import { handleError } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";

export const downloadJobseekerCoverLetter = ({ jobseekerId, jobseekerName }) => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      if (companyProfile.currentPlan === "lite") {
        return dispatch(
          handleError("Cannot download cover letter, please upgrade your plan to download cover letters.")
        );
      }

      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/jobseekers/${jobseekerId}/cover-letters`,
        {
          responseType: "blob",
        }
      );

      FileDownload(response.data, `${jobseekerName}-cover-letter.pdf`);
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
