import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";
import { getAppStoreCurrentState } from "../../../store";
import { appLocalStorage, lskeys } from "../../../core/sources/local-storage";

export const uploadProfileImage = ({ image }) => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      let formData = new FormData();

      formData.append("image", image);

      const response = await axiosInstance.patch(`/recruiters/${mainProfile.email}/image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const { data: updatedRecruiterProfile } = handleAxiosResponse(response);

      const recruiterProfile = { ...mainProfile, ...updatedRecruiterProfile };

      appLocalStorage.save({ [lskeys.recruiterProfile]: recruiterProfile });

      dispatch(actions.setProfileImage({ image: updatedRecruiterProfile.image }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
