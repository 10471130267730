import { Button, Typography } from "@mui/material";
import { ElevatedCard } from "../../../components/elevated-card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "../../../components/modal";
import { useState } from "react";
import { handleError } from "../../../features/app-messages";
import { useDispatch } from "react-redux";

export const CandidateProfessionalGallery = ({ candidate }) => {
  const [viewGallery, setViewGallery] = useState(false);
  const dispatch = useDispatch();

  return (
    <ElevatedCard
      sx={{
        flex: "1",
        p: { xs: "1rem", md: "1.6rem" },
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
        Professional Gallery
      </Typography>
      <Button
        sx={{ borderRadius: "2.6rem", height: "2.2rem" }}
        onClick={() => {
          if (typeof candidate?.professionalGallery === "number") {
            console.info("Im ere", typeof candidate?.professionalGallery);
            dispatch(
              handleError(
                "Please upgrade your plan to view this candidate's professional gallery."
              )
            );
            return;
          }

          setViewGallery(true);
        }}
      >
        View
      </Button>
      <SliderViewModal
        images={candidate?.professionalGallery}
        open={viewGallery}
        onClose={() => setViewGallery(false)}
      />
      <Modal
        open={viewGallery && candidate?.professionalGallery?.length === 0}
        onClose={() => setViewGallery(false)}
      >
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          No images found in this candidate's professional gallery.
        </Typography>
      </Modal>
    </ElevatedCard>
  );
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ImagesSlider = ({ images }) => {
  return (
    <Slider {...settings}>
      {images.map((image, i) => (
        <div
          key={i}
          sx={{
            height: "85vh",
            maxHeight: "85vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}/candidates/professional-gallery/${image?.name}`}
            style={{
              objectFit: "contain",
              maxHeight: "85vh",
              maxWidth: "100%",
              margin: "auto", // Center horizontally
              alignSelf: "center", // Center vertically
              borderRadius: "0.5rem",
              marginTop: "0.5rem",
            }}
            alt={image}
          />
        </div>
      ))}
    </Slider>
  );
};

const SliderViewModal = ({ images, open, onClose }) => {
  return (
    <>
      {images?.length > 0 && (
        <Modal open={open} onClose={onClose} maxWidth="90vw" maxHeight="90vh">
          <ImagesSlider images={images} />
          <style>
            {`
              .slick-prev:before,
              .slick-next:before {
                color: black;
              }
              .slick-dots {
                position: absolute;
                bottom: -0.8rem;
                left: 50%;
                transform: translateX(-50%);
              }
              .slick-slide, .slick-active, .slick-current {
                height: 85vh;
                max-height: 85vh;
              }
              .slick-initialized .slick-slide {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            `}
          </style>
        </Modal>
      )}
    </>
  );
};
