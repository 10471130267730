import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { actions } from "../slice";
import { handleError } from "../../app-messages";

export const fetchMessages = ({ conversationId, pageNo }) => {
  return async (dispatch) => {
    try {
      if (pageNo === 1) {
        dispatch(actions.clearMessages());
      }

      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CONVERSATIONS_API_BASE_URL}/conversations/${conversationId}/messages?page=${pageNo}`
      );
      const { data } = handleAxiosResponse(response);

      dispatch(actions.setMessages({ conversationId, messages: data.messages }));

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
