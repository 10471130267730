import { createSlice } from "@reduxjs/toolkit";

const initialState = { count: {}, open: {}, closed: {}, selectedJobId: null };

export const slice = createSlice({
  name: "jobs",

  initialState: { ...initialState },

  reducers: {
    setJobs: (state, { payload }) => {
      state.count = payload.count;
      state[payload.status][payload.currentPage] = payload.jobs;
    },

    jobPosted: (state, { payload }) => {
      state.count++;
      state.open[1]?.unshift(payload.job);
    },

    setSelectedJobId: (state, { payload }) => {
      state.selectedJobId = payload;
    },
  },
});

export const { reducer, actions } = slice;

export const selectSelectedJobId = (state) => state.jobs.selectedJobId;
