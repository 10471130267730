import { Box, Typography } from "@mui/material";
import moment from "moment";
import { icons } from "../../../../icons";

export const AdditionalDocument = ({
  candidateId,
  additionalDocument,
  setOpenPdf,
}) => {
  return (
    <Box
      sx={{
        padding: "0.8rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "0.6rem",
        backgroundColor: "#FFFAFA",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
          {additionalDocument.title}
        </Typography>
        <Typography sx={{ fontSize: "1.08rem", fontWeight: "bold" }}>
          {additionalDocument.license_issuer?.country &&
            additionalDocument.license_issuer?.country +
              " - " +
              additionalDocument.license_issuer?.issuer}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            display: "flex",
            alignItems: "center",
            ml: "-0.4rem",
          }}
        >
          {moment(additionalDocument.expires_on).isBefore(moment()) ? (
            <>
              {icons.hourglassBottom({
                sx: { color: "red", fontSize: "1.2rem" },
              })}
              Expired on{" "}
              {moment(additionalDocument.expires_on).format("DD MMM, YYYY")}.
            </>
          ) : (
            <>
              {icons.hourglassTop({
                sx: { color: "green", fontSize: "1.2rem" },
              })}
              Expires on{" "}
              {moment(additionalDocument.expires_on).format("DD MMM, YYYY")}.
            </>
          )}{" "}
        </Typography>
        <Typography
          color={"primary.main"}
          sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
        >
          View Documents:
          {additionalDocument.documents.map((document) => {
            return icons.article({
              key: document.id,
              onClick: () =>
                setOpenPdf(
                  `${process.env.REACT_APP_API_BASE_URL}/candidates/${candidateId}/documents?url=${document.url}`
                ),
              color: "primary.main",
              sx: {
                cursor: "pointer",
                fontSize: "1.5rem",
                ml: "0.5rem",
                position: "relative",
                top: "0.4rem",
                ":hover": {
                  color: "primary.dark",
                },
              },
            });
          })}
        </Typography>
      </Box>
    </Box>
  );
};
