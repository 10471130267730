import { Avatar, Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ElevatedCard } from "../../../components/elevated-card";
import { ConversationsFeature } from "../../../features/conversations";
import { AppRouteConstants } from "../../../navigations/routes";
import { icons } from "../../../icons";
import { useState } from "react";

export const CandidateBasicCard = ({ candidate, canViewCompleteProfile }) => {
  return (
    <ElevatedCard
      sx={{
        p: { xs: "1rem", md: "1.6rem" },
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "flex-start",
          mr: "1rem",
        }}
      >
        <Avatar
          src={candidate.image}
          sx={{
            width: "4.4rem",
            height: "4.4rem",
            mb: { xs: "0.8rem", md: "0rem" },
          }}
        >
          {candidate.name[0]}
        </Avatar>
        <Box width="2rem" />
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography variant="h5" fontWeight="bold">
            {candidate.name}
          </Typography>
          <Typography variant="body1">{candidate.role}</Typography>
          <Typography variant="caption">
            Applied {moment(candidate.createdAt).fromNow()}.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: "0.6rem" }}>
            {icons.email({
              sx: { width: "1.2rem", height: "1.2rem", mr: "0.4rem" },
            })}
            <Typography variant="body2">
              {canViewCompleteProfile
                ? candidate.email
                : "Cannot view email, please upgrade your plan."}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: "0.6rem" }}>
            {icons.phone({
              sx: { width: "1.2rem", height: "1.2rem", mr: "0.4rem" },
            })}
            <Typography variant="body2">
              {canViewCompleteProfile
                ? candidate.phone
                : "Cannot view phone, please upgrade your plan."}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: "0.6rem" }}>
            {icons.map({
              sx: { width: "1.2rem", height: "1.2rem", mr: "0.4rem" },
            })}
            <Typography variant="body2">
              {canViewCompleteProfile
                ? candidate.homebase
                : "Cannot view home base, please upgrade your plan."}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: "0.6rem" }}>
            {icons.location({
              sx: { width: "1.2rem", height: "1.2rem", mr: "0.4rem" },
            })}
            <Typography variant="caption" sx={{ color: "grey" }}>
              {canViewCompleteProfile
                ? `${candidate.city}, ${candidate.country}`
                : "Cannot view location, please upgrade your plan."}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: "0.6rem",
            }}
          >
            {icons.person({
              sx: {
                width: "1.2rem",
                height: "1.2rem",
                mr: "0.4rem",
                alignSelf: "flex-start",
                mt: "0.4rem",
              },
            })}
            <About candidate={candidate} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: { xs: "1rem", md: "1.6rem" },
          right: { xs: "1rem", md: "1.6rem" },
        }}
      >
        <StartConversationButton candidateId={candidate.profileId} />
      </Box>
    </ElevatedCard>
  );
};

const StartConversationButton = ({ candidateId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      const { id } = await ConversationsFeature.startNewConversation({
        recipientId: candidateId,
      })(dispatch);

      navigate(`${AppRouteConstants.viewConversation}?conversationId=${id}`);
    } catch (error) {}
  };

  return (
    <Button sx={{ borderRadius: "2.5rem" }} onClick={handleClick}>
      Message
    </Button>
  );
};

const About = ({ candidate }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const maxLength = 100;
  const text = showFullText
    ? candidate?.about
    : candidate?.about?.slice(0, maxLength);

  return (
    <Typography
      variant="body2"
      sx={{ mt: "0.4rem", maxWidth: "80rem" }}
      whiteSpace={"pre-line"}
      onClick={toggleShowFullText}
    >
      {text}
      {text?.length >= 100 && !showFullText ? "..." : ""}

      {text?.length >= 100 && (
        <Typography
          variant="body2"
          component={"span"}
          sx={{ textDecoration: "underline", cursor: "pointer", ml: "0.4rem" }}
        >
          {showFullText ? "Read Less" : "Read More"}
        </Typography>
      )}
    </Typography>
  );
};
