import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PLANS_AND_PRICINGS } from "../../../core/constants/plans-and-pricings";
import { BillingFeature } from "../../../features/billing";
import { GeneratePaymentMethodIdModal } from "../../../components/payments/generate-payment-method-id";
import { PlansAndPricingTable } from "../../../components/pricing-table";
import { SubscribeToButtons } from "./subscribe-buttons";
import { Confirmations } from "./subscribe-confirmations";

const AllPlansAndPricings = (props) => {
  const dispatch = useDispatch();
  const recruiterProfile = useSelector((state) => state.mainProfile);
  const subscription = useSelector((state) => state.subscription);
  const billing = useSelector((state) => state.billing);

  const [billingCycle, setBillingScheme] = useState("yearly");
  const [subscribeTo, setSubscribeTo] = useState("lite");
  const [showGeneratePMIdModal, setShowGeneratePMIdModal] = useState(false);
  const [showConfirmModalLite, setShowConfirmModalLite] = useState(false);
  const [showConfirmModalPro, setShowConfirmModalPro] = useState(false);
  const [showConfirmModalEnterprise, setShowConfirmModalEnterprise] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleOnClickSubscribeButton = (subscribeToPlan) => {
    setSubmitting(true);

    switch (subscribeToPlan) {
      case "enterprise":
        if (billing.card === null) {
          setSubscribeTo(subscribeToPlan);
          setShowGeneratePMIdModal(true);
          return;
        }

        setShowConfirmModalEnterprise(true);
        break;

      case "pro":
        if (billing.card === null) {
          setSubscribeTo(subscribeToPlan);
          setShowGeneratePMIdModal(true);
          return;
        }

        setShowConfirmModalPro(true);
        break;

      default:
        setShowConfirmModalLite(true);
        break;
    }
  };

  const handleOnCloseConfirmModal = () => {
    setShowConfirmModalEnterprise(false);
    setShowConfirmModalPro(false);
    setShowConfirmModalLite(false);
    setSubmitting(false);
  };

  const handleOnCompleteGeneratePMIdModal = async (paymentMethodId) => {
    setSubmitting(true);

    await BillingFeature.attachCustomerCard({ paymentMethodId })(dispatch);

    switch (subscribeTo) {
      case "enterprise":
        setShowConfirmModalEnterprise(true);
        break;

      case "pro":
        setShowConfirmModalPro(true);
        break;

      default:
        setShowConfirmModalLite(true);
        break;
    }
  };

  const handleOnCloseGeneratePMIdModal = () => {
    setShowGeneratePMIdModal(false);
    setSubmitting(false);
  };

  return (
    <>
      <PlansAndPricingTable
        plansAndPricings={PLANS_AND_PRICINGS}
        billingCycle={billingCycle}
        onBillingSchemeChange={setBillingScheme}
        actions={{
          lite: (
            <SubscribeToButtons.LiteSubscription
              onClick={() => handleOnClickSubscribeButton("lite")}
              isAdmin={recruiterProfile.permission === "admin"}
              currentPlan={subscription.plan}
              submitting={submitting}
            />
          ),
          pro: (
            <SubscribeToButtons.ProSubscription
              onClick={() => handleOnClickSubscribeButton("pro")}
              isAdmin={recruiterProfile.permission === "admin"}
              currentPlan={subscription.plan}
              canUseTrial={subscription.canUseTrial.pro}
              submitting={submitting}
            />
          ),
          enterprise: (
            <SubscribeToButtons.EnterpriseSubscription
              onClick={() => handleOnClickSubscribeButton("enterprise")}
              isAdmin={recruiterProfile.permission === "admin"}
              currentPlan={subscription.plan}
              canUseTrial={subscription.canUseTrial.enterprise}
              submitting={submitting}
            />
          ),
        }}
      />
      <Confirmations.ConfirmLiteSubscription
        onClose={handleOnCloseConfirmModal}
        open={showConfirmModalLite}
        currentPlan={subscription.plan}
        billingCycle={billingCycle}
      />
      <Confirmations.ConfirmProSubscription
        onClose={handleOnCloseConfirmModal}
        open={showConfirmModalPro}
        currentPlan={subscription.plan}
        paymentMethodId={billing.card?.paymentMethodId}
        billingCycle={billingCycle}
      />
      <Confirmations.ConfirmEnterpriseSubscription
        onClose={handleOnCloseConfirmModal}
        open={showConfirmModalEnterprise}
        currentPlan={subscription.plan}
        paymentMethodId={billing.card?.paymentMethodId}
        billingCycle={billingCycle}
      />
      <GeneratePaymentMethodIdModal
        onComplete={handleOnCompleteGeneratePMIdModal}
        onClose={handleOnCloseGeneratePMIdModal}
        open={showGeneratePMIdModal}
      />
    </>
  );
};

const MemoizedAllPlanAndPricings = React.memo(AllPlansAndPricings);

export { MemoizedAllPlanAndPricings as AllPlansAndPricings };
