import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";

import { getAppStoreCurrentState } from "../../../store";
import { handleError, showSuccess } from "../../app-messages";

export const sendOTPVerificationEmail = () => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.post("/authentication/verifications/email", { email: mainProfile.email });

      const { message } = handleAxiosResponse(response);

      dispatch(showSuccess(message));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
