import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store/index";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const fetchCustomerCard = () => {
  return async (dispatch) => {
    try {
      const { companyProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.get(`/billing/${companyProfile.stripeCustomerId}/card`);

      const { data } = handleAxiosResponse(response);

      if (data !== null) {
        dispatch(actions.setCard({ card: data }));
      }

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
