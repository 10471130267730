import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { icons } from "../icons";

export const SupportPin = ({ title = "support pin", color = "white", supportPin }) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
      <Typography
        variant="subtitle1"
        sx={{ mr: "0.4rem", mb: "-0.2rem", fontSize: "0.86rem", textTransform: "capitalize", userSelect: "none" }}
      >
        {title}
      </Typography>
      <Box sx={{ display: "flex", mt: "0.2rem" }}>
        <Button
          variant="outlined"
          sx={{
            padding: "0rem 0.5rem",
            fontSize: "0.8rem",
            color,
            borderWidth: "2px",
            borderColor: "transparent",
            ":hover": { borderWidth: "2px", borderColor: "transparent" },
          }}
          onClick={() => navigator.clipboard.writeText(supportPin)}
        >
          {supportPin}
          {icons.copy({
            sx: { ml: "0.6rem", width: "0.9rem", height: "0.9rem", color: color },
          })}
        </Button>
      </Box>
    </Box>
  );
};
