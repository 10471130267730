import { axiosInstance, handleAxiosResponse } from "../../../core/sources/network";
import { getAppStoreCurrentState } from "../../../store";

import { handleError } from "../../app-messages";
import { actions } from "../slice";

export const initializeForm = () => {
  return async (dispatch) => {
    try {
      const { mainProfile } = getAppStoreCurrentState();

      const response = await axiosInstance.get(`/jobs/${mainProfile.email}/drafts`);
      const { data } = handleAxiosResponse(response);

      const limits = {
        reachedFullTimeLimit: data.limits.full_time.hasReachedLimit,
        reachedFreelanceLimit: data.limits.freelance.hasReachedLimit,
        reachedSponsorCreditLimit: data.limits.sponsor.hasReachedLimit,
        canRecruiteForOthers: data.limits.canRecruiteForOthers,
      };

      dispatch(actions.setDraftId({ draftId: data.id }));
      dispatch(actions.setFormState({ formState: data.jobInfo }));
      dispatch(actions.setCurrentLimits({ limits }));
    } catch (error) {
      handleError(error?.response?.data?.message ?? error.message)(dispatch);
    }
  };
};
