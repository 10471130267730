import { Box, InputBase, Typography } from "@mui/material";
import { TextFieldBackground } from "../textfield/background";

export const TextInput = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  sx,
  placeholder,
  ...otherProps
}) => {
  return (
    <Box sx={{ my: "1rem", ...sx }}>
      <Typography sx={{ fontWeight: "bold", mb: "0.6rem" }}>{label}</Typography>
      <TextFieldBackground>
        <InputBase
          {...{
            label,
            name,
            value,
            onChange,
            onBlur,
            placeholder,
            ...otherProps,
          }}
        />
      </TextFieldBackground>
      {helperText && (
        <Typography
          variant="subtitle2"
          sx={{ m: "0.6rem", color: error ? "red" : "black" }}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
