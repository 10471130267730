import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { ConversationsFeature } from "../../../../features/conversations";
import { CircularLoader } from "../../../../components/loaders/circular-loader";
import { ElevatedCard } from "../../../../components/elevated-card";
import { Search } from "../search-conversations";
import { ConversationListItem } from "./conversation-list-item";

export const ConversationsList = (props) => {
  const dispatch = useDispatch();
  const { ref, inView } = useInView();
  const [initialized, setInitialized] = useState(false);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const conversations = useSelector((state) => state.conversations.conversations);

  const fetchAndSetConversations = async (options) => {
    try {
      const results = await ConversationsFeature.fetchAllConversations(options)(dispatch);
      if (results) {
        setHasMore(results.hasMore);
        setPage(results.page);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((inView && hasMore && !isLoading) || !initialized) {
      fetchAndSetConversations({
        searchTerm: search,
        page: initialized ? page + 1 : 1,
      }).then(() => {
        setInitialized(true);
      });
    }
  }, [inView, hasMore, isLoading, initialized]);

  const onSearch = async () => {
    await fetchAndSetConversations({ searchTerm: search, page: 1 });
  };

  return (
    <ElevatedCard sx={{ flex: 1, display: "flex", flexDirection: "column", padding: "0rem" }}>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ height: "3.6rem", display: "flex", borderBottom: "1px solid #e0e0e0" }}>
          <Box
            sx={{ flex: 1, padding: "1rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontSize: "1.12rem", fontWeight: "bold" }}>Conversations</Typography>
          </Box>
        </Box>
        <Search onSearch={onSearch} search={search} setSearch={setSearch} />
        <ConversationsConatiner
          initialized={initialized}
          isLoading={isLoading}
          isEmpty={conversations.length === 0}
          isSearchResult={search.length}
        >
          {conversations.map((conversation, i) => {
            return (
              <Box key={conversation.id} ref={conversations.length === i + 1 ? ref : null}>
                <ConversationListItem conversation={conversation} />
                {(i + 1 !== conversations.length || conversations.length === 1) && <Divider />}
              </Box>
            );
          })}
        </ConversationsConatiner>
      </Box>
    </ElevatedCard>
  );
};

const ConversationsConatiner = ({ children, initialized, isLoading, isEmpty, isSearchResult }) => {
  if (!initialized) {
    return <CircularLoader size="1.6rem" />;
  }

  return !isEmpty ? (
    <Box sx={{ display: "flex", flexDirection: "column", overflowY: "scroll", height: "80vh" }}>
      {children}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", py: "3rem" }}>
        {isLoading && <CircularLoader size="1.6rem" />}
      </Box>
      <Box sx={{ height: "3rem", color: "white" }}>-</Box>
    </Box>
  ) : (
    <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Typography variant="body1" sx={{ px: "2rem", textAlign: "center" }}>
        {isSearchResult ? "No conversations found" : "Looks like you don't have any conversations yet."}
      </Typography>
    </Box>
  );
};
