import { Fragment, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ConversationsFeature } from "../../../../features/conversations";
import { CircularLoader } from "../../../../components/loaders/circular-loader";
import { ElevatedCard } from "../../../../components/elevated-card";
import { icons } from "../../../../icons";
import { Message } from "./message";
import { MessageInput } from "./message-input";
import { ActivityStatus } from "./activity-status";
import { ConversationMenuOptions } from "./conversation-options-menu";

export const ConversationBox = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    hasMore: false,
  });
  const [conversation, setConversation] = useState(null);
  const messages = useSelector((state) => state.conversations.messages[conversation?.id] || []);

  useEffect(() => {
    try {
      const conversationId = searchParams.get("conversationId");

      if (!conversationId) {
        setInitialized(true);
        return;
      }

      if (conversationId) {
        ConversationsFeature.fetchConversationInfo({ conversationId })(dispatch).then((result) => {
          setConversation(result);
          setInitialized(true);
        });

        if (isLoading) {
          return;
        }

        setIsLoading(true);

        ConversationsFeature.fetchMessages({
          conversationId: conversationId,
          pageNo: pagination.currentPage,
        })(dispatch).then((result) => {
          setPagination((prev) => ({ ...prev, hasMore: result.hasMore }));
          setIsLoading(false);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [location, searchParams, dispatch]);

  return (
    <ElevatedCard
      sx={{
        flex: 1.8,
        display: "flex",
        flexDirection: "column",
        padding: "0rem",
      }}
    >
      <Box
        sx={{
          height: "3.6rem",
          display: "flex",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {initialized && !isLoading ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "1.12rem", fontWeight: "bold", mr: "0.6rem" }}>
                {!conversation ? "start a conversation" : conversation?.recipient?.name ?? "(Deleted User)"}
              </Typography>
              {conversation && <ActivityStatus activity={conversation?.recipient?.activity} />}
            </Box>
          ) : (
            <Typography sx={{ fontSize: "1.12rem", fontWeight: "bold" }}>Loading...</Typography>
          )}
          <ConversationMenuOptions conversationId={conversation?.id} cb={() => setConversation(null)} />
        </Box>
      </Box>

      <ConversationMessagesList
        conversationId={conversation?.id}
        initialized={initialized && !isLoading}
        isEmpty={messages.length === 0}
        pagination={pagination}
        setPagination={setPagination}
      >
        {messages.map((message) => {
          return (
            <Message
              key={message.id}
              conversation={conversation}
              message={message}
              myImage={conversation?.recipient?.image}
              recipient={{
                name: initialized && !isLoading ? conversation?.recipient?.name ?? "(Deleted User)" : "loading...",
              }}
            />
          );
        })}
      </ConversationMessagesList>

      <MessageInput recipient={conversation?.recipient ?? null} />
    </ElevatedCard>
  );
};

const ConversationMessagesList = ({ children, conversationId, initialized, isEmpty, pagination, setPagination }) => {
  const dispatch = useDispatch();
  const { ref: triggerRef, inView } = useInView();
  const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false);

  useEffect(() => {
    if (inView && pagination.hasMore && !isLoadingOlderMessages) {
      const pageNo = pagination.currentPage + 1;

      setIsLoadingOlderMessages(true);

      ConversationsFeature.fetchMessages({ conversationId, pageNo })(dispatch).then((result) => {
        setPagination((prev) => ({
          ...prev,
          currentPage: result.hasMore ? pageNo : pagination.currentPage,
          hasMore: result.hasMore,
        }));

        setIsLoadingOlderMessages(false);
      });
    }
  }, [conversationId, inView, pagination.hasMore, isLoadingOlderMessages, dispatch]);

  if (initialized) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column-reverse",
          overflowAnchor: "none",
          overflowY: "scroll",
        }}
      >
        {!isEmpty ? (
          <Fragment>
            <Box sx={{ flex: 1 }}>{children}</Box>
            <LoadOlderMessagesTrigger
              triggerRef={triggerRef}
              isLoading={isLoadingOlderMessages}
              hasMore={pagination.hasMore}
            />
          </Fragment>
        ) : (
          <Box sx={{ flex: 1, display: "grid", placeItems: "center" }}>
            {icons.chat({ sx: { color: "grey", fontSize: "3rem" } })}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ flex: 1, display: "grid", placeItems: "center" }}>
      <CircularLoader size="1.6rem" />
    </Box>
  );
};

const LoadOlderMessagesTrigger = ({ triggerRef, isLoading, hasMore }) => {
  let triggerContent = <Box ref={triggerRef}>{icons.arrowUp({})}</Box>;

  if (isLoading) {
    triggerContent = <CircularLoader size="1.6rem" />;
  }

  return (
    <Box style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
      {hasMore ? (
        triggerContent
      ) : (
        <Typography style={{ color: "grey", userSelect: "none" }}>
          We take your privacy seriously. All messages are encrypted
        </Typography>
      )}
    </Box>
  );
};
