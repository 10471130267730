export const PLANS_AND_PRICINGS = [
  {
    stripe_product_id: "prod_Nq5y364O1ebYYd",
    name: "Lite",
    plan: "lite",
    description: "Lite features for the recruiters",
    features: [
      {
        key: "postFullTimeJob",
        name: "Post Full Time Jobs",
        available: true,
        limit: "3 posts / m",
      },
      {
        key: "postFreelanceJob",
        name: "Post Freelance Jobs",
        available: true,
        limit: "0 posts / m",
      },
      {
        key: "sponsoring",
        name: "Sponsor Your Job Posting",
        available: false,
        limit: "0 credits",
      },
      {
        key: "chatWithCandidate",
        name: "Chat With Candidate",
        available: true,
      },
      { key: "jobAnalytics", name: "Job Analytics", available: true },
      { key: "viewCV", name: "View CV", available: true },
      { key: "downloadCV", name: "Download CV", available: false },
      {
        key: "viewProfessionalGallery",
        name: "View Professional Gallery",
        available: false,
      },
      {
        key: "viewCandidateProfile",
        name: "View Candidate Profile",
        available: false,
      },
      { key: "jobSEO", name: "Job SEO", available: false },
      {
        key: "socialMediaMarketing",
        name: "Social Media Marketing",
        available: false,
      },
      {
        key: "recruiterForOthers",
        name: "Recruit For Other",
        available: false,
      },
      { key: "addTeamMembers", name: "Add Team Members", available: false },
      { key: "jobseekersDB", name: "Jobseekers DB", available: false },
    ],
    schemes: {
      monthly: {
        stripe_price_id: "price_1N4PmGDdJnvLYzYUE0CB2wUq",
        currency: "GBP",
        amount: "FREE",
        trial_duration: "0",
      },
      yearly: {
        stripe_price_id: "price_1N4PmGDdJnvLYzYUQkZRgPCf",
        currency: "GBP",
        amount: "FREE",
        trial_duration: "0",
      },
    },
  },
  {
    stripe_product_id: "prod_Nq5xVpoL2BIqcd",
    name: "Pro",
    plan: "pro",
    description: "Pro features for the recruiters",
    features: [
      {
        key: "postFullTimeJob",
        name: "Post Full Time Jobs",
        available: true,
        limit: "9 posts / m",
      },
      {
        key: "postFreelanceJob",
        name: "Post Freelance Jobs",
        available: true,
        limit: "3 posts / m",
      },
      {
        key: "sponsoring",
        name: "Sponsor Your Job Posting",
        available: true,
        limit: "2 credits",
      },
      {
        key: "chatWithCandidate",
        name: "Chat With Candidate",
        available: true,
      },
      { key: "jobAnalytics", name: "Job Analytics", available: true },
      { key: "viewCV", name: "View CV", available: true },
      { key: "downloadCV", name: "Download CV", available: true },
      {
        key: "viewProfessionalGallery",
        name: "View Professional Gallery",
        available: true,
      },
      {
        key: "viewCandidateProfile",
        name: "View Candidate Profile",
        available: true,
      },
      { key: "jobSEO", name: "Job SEO", available: true },
      {
        key: "socialMediaMarketing",
        name: "Social Media Marketing",
        available: true,
      },
      {
        key: "recruiterForOthers",
        name: "Recruit For Other",
        available: false,
      },
      { key: "addTeamMembers", name: "Add Team Members", available: false },
      { key: "jobseekersDB", name: "Jobseekers DB", available: false },
    ],
    schemes: {
      monthly: {
        stripe_price_id: "price_1N4PlkDdJnvLYzYU4o4lLm6j",
        currency: "GBP",
        amount: "749",
        trial_duration: "14",
      },
      yearly: {
        stripe_price_id: "price_1N4PlkDdJnvLYzYUIx7likpE",
        currency: "GBP",
        amount: "599",
        offer: "20%",
        trial_duration: "14",
      },
    },
  },
  {
    stripe_product_id: "prod_Nq5wFgLNQt4OPQ",
    name: "Enterprise",
    plan: "enterprise",
    description: "Enterprise features for the recruiters",
    features: [
      {
        key: "postFullTimeJob",
        name: "Post Full Time Jobs",
        available: true,
        limit: "Unlimited",
      },
      {
        key: "postFreelanceJob",
        name: "Post Freelance Jobs",
        available: true,
        limit: "Unlimited",
      },
      {
        key: "sponsoring",
        name: "Sponsor Your Job Posting",
        available: true,
        limit: "6 credits",
      },
      {
        key: "chatWithCandidate",
        name: "Chat With Candidate",
        available: true,
      },
      { key: "jobAnalytics", name: "Job Analytics", available: true },
      { key: "viewCV", name: "View CV", available: true },
      { key: "downloadCV", name: "Download CV", available: true },
      {
        key: "viewProfessionalGallery",
        name: "View Professional Gallery",
        available: true,
      },
      {
        key: "viewCandidateProfile",
        name: "View Candidate Profile",
        available: true,
      },
      { key: "jobSEO", name: "Job SEO", available: true },
      {
        key: "socialMediaMarketing",
        name: "Social Media Marketing",
        available: true,
      },
      { key: "recruiterForOthers", name: "Recruit For Other", available: true },
      { key: "addTeamMembers", name: "Add Team Members", available: true },
      { key: "jobseekersDB", name: "Jobseekers DB", available: true },
    ],
    schemes: {
      monthly: {
        stripe_price_id: "price_1N4PkWDdJnvLYzYUmX3GKTLR",
        currency: "GBP",
        amount: "1299",
        trial_duration: "14",
      },
      yearly: {
        stripe_price_id: "price_1N4PkWDdJnvLYzYUIG5mmPqH",
        currency: "GBP",
        amount: "999",
        offer: "23%",
        trial_duration: "14",
      },
    },
  },
];
