import React from "react";
import Drawer from "@mui/material/Drawer";

import { AirportSelect } from "./selects/airport";
import { CountrySelect } from "./selects/country";
import { LicensesSelect } from "./selects/licenses";
import { Box } from "@mui/system";
import { Button, Divider, Input, Typography } from "@mui/material";
import { RoleDropDown } from "./dropdowns/role";

export const FiltersSideBar = ({
  filterOptions,
  setFilterOptions,
  isOpen,
  onClose,
  crewType,
  jobseeker,
}) => {
  const onChange = ({ target }) => {
    setFilterOptions({
      ...filterOptions,
      [target.name]: target.value,
      crewType: crewType ?? null,
    });
  };

  return (
    <Drawer
      sx={{ width: "90vw", maxWidth: "420px", flexShrink: 0 }}
      PaperProps={{ sx: { width: "90vw", maxWidth: "420px" } }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ margin: "1.6rem", display: "flex", flexDirection: "column" }}>
        <Box>
          <Typography variant="h5" sx={{ mb: "1rem", fontWeight: "bold" }}>
            Filters
          </Typography>
          <Divider />
        </Box>
        {!jobseeker && (
          <>
            {crewType && crewType === "flight crew" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: "1rem", flex: "2.5" }}
                  >
                    PIC Hrs:
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography variant="body1" sx={{ mr: "1rem", mt: "1rem" }}>
                      Min
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.minPicHours <= 0 ? "gray" : "black",
                      }}
                      name="minPicHours"
                      value={filterOptions.minPicHours}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ ml: "1rem", mr: "1rem", mt: "1rem" }}
                    >
                      Max
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.maxPicHours <= 0 ? "gray" : "black",
                      }}
                      name="maxPicHours"
                      value={filterOptions.maxPicHours}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: "1rem", flex: "2.5" }}
                  >
                    SIC Hrs:
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography variant="body1" sx={{ mr: "1rem", mt: "1rem" }}>
                      Min
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.minSicHours <= 0 ? "gray" : "black",
                      }}
                      name="minSicHours"
                      value={filterOptions.minSicHours}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ ml: "1rem", mr: "1rem", mt: "1rem" }}
                    >
                      Max
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.maxSicHours <= 0 ? "gray" : "black",
                      }}
                      name="maxSicHours"
                      value={filterOptions.maxSicHours}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Box>
              </>
            )}
            {crewType && crewType === "cabin crew" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: "1rem", flex: "2.5" }}
                  >
                    Corporate Yrs:
                  </Typography>
                  {/* <Box sx={{ display: "flex", flexDirection: "column" }}> */}
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography variant="body1" sx={{ mr: "1rem", mt: "1rem" }}>
                      Min
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.minCorporateYears <= 0
                            ? "gray"
                            : "black",
                      }}
                      name="minCorporateYears"
                      value={filterOptions.minCorporateYears}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ ml: "1rem", mr: "1rem", mt: "1rem" }}
                    >
                      Max
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.maxCorporateYears <= 0
                            ? "gray"
                            : "black",
                      }}
                      name="maxCorporateYears"
                      value={filterOptions.maxCorporateYears}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: "1rem", flex: "2.5" }}
                  >
                    Total Yrs:
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography variant="body1" sx={{ mr: "1rem", mt: "1rem" }}>
                      Min
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.minTotalYears <= 0 ? "gray" : "black",
                      }}
                      name="minTotalYears"
                      value={filterOptions.minTotalYears}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: "2" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ ml: "1rem", mr: "1rem", mt: "1rem" }}
                    >
                      Max
                    </Typography>
                    <Input
                      sx={{
                        width: "3rem",
                        color:
                          filterOptions.maxTotalYears <= 0 ? "gray" : "black",
                      }}
                      name="maxTotalYears"
                      value={filterOptions.maxTotalYears}
                      onChange={onChange}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        <RoleDropDown value={filterOptions.role} onChange={onChange} />
        <AirportSelect
          label="Homebase"
          name="homebase"
          value={filterOptions.homebase}
          onChange={onChange}
          multiple
        />
        <CountrySelect
          label="Country"
          name="country"
          value={filterOptions.country}
          onChange={onChange}
          multiple
        />
        <LicensesSelect
          label="Licenses"
          name="licenses"
          value={filterOptions.licenses}
          onChange={onChange}
          multiple
        />
        <CountrySelect
          label="Passports"
          name="passports"
          value={filterOptions.passports}
          onChange={onChange}
          multiple
        />
        <CountrySelect
          label="Visas"
          name="visas"
          value={filterOptions.visas}
          onChange={onChange}
          multiple
        />
        <Button
          sx={{ borderRadius: "2.6rem", mt: "1rem" }}
          variant="outlined"
          onClick={() => {
            setFilterOptions({
              role: "",
              homebase: [],
              country: [],
              licenses: [],
              passports: [],
              visas: [],
              minPicHours: 0,
              maxPicHours: 0,
              minSicHours: 0,
              maxSicHours: 0,
              minCorporateYears: 0,
              maxCorporateYears: 0,
              minTotalYears: 0,
              maxTotalYears: 0,
            });
          }}
        >
          Reset Filters
        </Button>
      </Box>
    </Drawer>
  );
};
