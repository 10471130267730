import { Box, Typography } from "@mui/material";
import { PostJobButton } from "../../../components/buttons/post-job";

export const PostJobBanner = (props) => {
  return (
    <Box
      sx={{
        flex: 1,
        width: "100%",
        borderRadius: "1rem",
        backgroundColor: "#FFFAFA",
        marginTop: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        No Jobs Posted
      </Typography>
      <Typography variant="subtitle1" sx={{ my: "1rem" }}>
        You Have No Candidates, Please Post Jobs To Find Candidates.
      </Typography>
      <Box sx={{ mb: "6rem" }}>
        <PostJobButton />
      </Box>
    </Box>
  );
};
