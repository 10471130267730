export class AppMessage {
  constructor({ type, message }) {
    this.id = Date.now();
    this.message = message;
    this.type = type;
  }

  get = () => {
    return {
      id: this.id,
      message: this.message,
      type: this.type,
    };
  };
}
