import Box from "@mui/material/Box";

import { PageLayout } from "../../components/page-layout";
import { RecruiterProfileCard } from "./components/recruiter-profile-card";
import { Settings } from "./settings";

export const AccountPage = (props) => {
  return (
    <PageLayout pageName="Account" showAppBar postJobButton>
      <RecruiterProfileCard />
      <Box sx={{ flex: 1, mx: { xs: "1rem", md: "1.6rem" }, my: "2rem" }}>
        <Settings />
      </Box>
    </PageLayout>
  );
};
