import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ElevatedCard } from "../../../components/elevated-card";
import { JobsFeature } from "../../../features/jobs";
import { icons } from "../../../icons";
import { useNavigate } from "react-router-dom";

export const JobStatus = ({
  job,
  statusChangeActivity,
  setStatusChangeActivity,
}) => {
  const expired = checkExpired(job.expiresOn);
  const navigate = useNavigate();

  return (
    <Box>
      <ElevatedCard>
        <UpdateStatusMenu
          jobId={job._id}
          status={job.status}
          disabled={expired}
          setStatusChangeActivity={setStatusChangeActivity}
        />
        {expired && (
          <Button sx={{ mt: "1rem" }} fullWidth>
            Repost Job
          </Button>
        )}
        {statusChangeActivity && statusChangeActivity?.length > 0 && (
          <>
            <Divider sx={{ mt: { xs: "1rem", md: "1.6rem" }, mb: "1rem" }} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "16rem",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0.4rem",
                },
                "&::-webkit-scrollbar-track": {
                  width: "0.4rem",
                },
                "&::-webkit-scrollbar-thumb": {
                  width: "0.4rem",
                  borderRadius: "0.2rem",
                  backgroundColor: "#ccc",
                },
                backgroundColor: "#f4f4fb",
                p: "0.44rem 0.88rem",
                borderRadius: "0.4rem",
              }}
            >
              {statusChangeActivity?.map((activity, i) => (
                <Box
                  key={i}
                  component="ul"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    mt: "0.5rem",
                  }}
                >
                  {i > 0 && <Divider sx={{ width: "100%", mb: "0.3rem" }} />}
                  <Typography
                    sx={{ fontSize: "0.8rem", textTransform: "capitalize" }}
                  >
                    {activity.from}{" "}
                    <span>&nbsp; &nbsp; &rarr; &nbsp; &nbsp;</span>{" "}
                    {activity.to}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      Recruiter: {activity.recruiter_name}
                    </Typography>
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {/* date and time */}
                      {moment(activity.createdAt).format("DD MMM YYYY, h:mm a")}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
        <Divider sx={{ mt: "1.6rem", mb: "1rem" }} />
        <Box component="ul" style={{ paddingLeft: "1rem", mb: "0.6rem" }}>
          <Box component="li">
            <Typography>
              Planned to hire {job.openFor} candidate
              {Number(job.openFor) > 1 ? "s" : ""}.
            </Typography>
          </Box>
          <Box component="li">
            <Typography>Posted {moment(job.createdAt).fromNow()}.</Typography>
          </Box>
          <Box component="li">
            <Typography>
              {expired ? "Expired" : "Expires"} on{" "}
              {moment(job.expiresOn).format("DD MMM YYYY")}.
            </Typography>
          </Box>
        </Box>
      </ElevatedCard>
      {expired && (
        <Button
          onClick={() => navigate(`/jobs/post?jobId=${job._id}`)}
          variant="contained"
          sx={{
            borderRadius: "2rem",
            textTransform: "capitalize",
            alignSelf: "flex-end",
          }}
        >
          Repost this job
        </Button>
      )}
    </Box>
  );
};

const checkExpired = (date) => {
  const today = new Date();
  return date < today;
};

const UpdateStatusMenu = ({
  jobId,
  status,
  disabled,
  setStatusChangeActivity,
}) => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const [prevStatus, setPrevStatus] = useState(status);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const onSelect = async (newStatus) => {
    if (status === newStatus) return;
    setOpen(false);
    setSubmitting(true);
    setPrevStatus(currentStatus);
    setCurrentStatus(newStatus);
  };

  useEffect(() => {
    if (currentStatus !== prevStatus) {
      JobsFeature.updateJobStatus({ jobId, status: currentStatus })(
        dispatch
      ).then((res) => {
        if (!res.status) {
          setCurrentStatus(prevStatus);
        } else {
          setCurrentStatus(res.status);
          setStatusChangeActivity(res.statusChangeActivity);
        }

        setSubmitting(false);
      });
    }
  }, [currentStatus, dispatch, jobId, prevStatus]);

  // const onSelect = async (newStatus) => {
  //   const result = await JobsFeature.updateJobStatus({ jobId, status: newStatus })(dispatch);
  //   setCurrentStatus(result ?? currentStatus);
  //   setOpen(false);
  // };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{
          padding: "0.4rem 1rem",
          minHeight: "2.6rem",
          borderRadius: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
          textTransform: "capitalize",
          fontSize: "1rem",
        }}
        ref={anchorRef}
        disabled={disabled}
        fullWidth
      >
        {submitting ? <CircularProgress size="1.2rem" /> : currentStatus}
        <ExpandIcon open={open} />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 3000 }}
        MenuListProps={{ sx: { padding: "0rem" } }}
        PaperProps={{
          elevation: 6,
          style: {
            borderRadius: "0.4rem",
            maxHeight: "16rem",
            width: anchorRef.current?.offsetWidth,
          },
        }}
      >
        {["open", "closed"].map((statusItem) => (
          <MenuItem
            key={statusItem}
            onClick={() => onSelect(statusItem)}
            sx={{
              padding: "0.8rem",
              textTransform: "capitalize",
              whiteSpace: "break-spaces",
              wordBreak: "break-word",
            }}
          >
            {statusItem}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ExpandIcon = ({ open }) => {
  return (
    <Box sx={{ ml: "0.6rem", display: "flex" }}>
      {open ? icons.expandLess({}) : icons.expandMore({})}
    </Box>
  );
};
