import { Chip } from "@mui/material";

export const CustomChip = ({ item }) => {
  return (
    <Chip
      label={item}
      sx={{
        mr: "0.5rem",
        mb: "0.8rem",
        textTransform: "capitalize",
        color: "white",
        backgroundImage: "linear-gradient(to right, #E53834, #e43a15)",
        border: "1px solid #EFEFEF",
        boxShadow: "0px 0px 8px 5px #EFEFEF",
        cursor: "inherit",
        wordBreak: "break-word",
      }}
    />
  );
};
