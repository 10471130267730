import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { themeOptions } from "./options";

const createAppTheme = () => {
  const theme = createTheme(themeOptions);

  return responsiveFontSizes(theme);
};

export const AppThemeProvider = ({ children }) => {
  return <ThemeProvider theme={createAppTheme()}>{children}</ThemeProvider>;
};
