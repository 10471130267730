import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { PageLayout } from "../../components/page-layout";
import { CompanyProfileCard } from "./components/company-profile";
import { Team } from "./team";
import { BillingSettings } from "./billing-settings";
import { MySubscription } from "./my-subscription";

export const CompanyProfilePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState("team");

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (!tab) {
      navigate("/company-account?tab=team", { replace: true });
    }

    setCurrentTab(tab || currentTab);
  }, [currentTab, searchParams, navigate]);

  return (
    <PageLayout pageName="Account" showAppBar postJobButton>
      <CompanyProfileCard />
      <Box sx={{ mt: "1rem", mx: "2.2rem", width: { xs: "calc(100vw - 4.4rem)", md: "calc(100% - 4.4rem)" } }}>
        <Tabs
          onChange={(_, value) => navigate(`/company-account?tab=${value}`)}
          value={currentTab}
          TabIndicatorProps={{ sx: { height: "3px", borderRadius: "2px" } }}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {tabs.map((tab) => (
            <Tab
              {...{ key: tab, label: tab, value: tab }}
              sx={{ minWidth: "0rem", paddingX: "0.8rem", textTransform: "capitalize" }}
            />
          ))}
        </Tabs>
        <Divider sx={{ mt: "-2px", borderColor: "#ECECEC" }} />
      </Box>
      <Box
        sx={{
          mx: { xs: "1rem", md: "1.6rem" },
          my: "2rem",
          minHeight: "40vh",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {currentTab === "team" && <Team />}
        {currentTab === "billing settings" && <BillingSettings />}
        {currentTab === "my subscription" && <MySubscription />}
      </Box>
    </PageLayout>
  );
};

const tabs = ["team", "billing settings", "my subscription"];
