import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { BillingFeature } from "../../features/billing";
import { PaymentMethod } from "./components/payment-method";
import { BillingAddress } from "./components/billing-address";
import { PaymentReceipts } from "./components/payment-receipts";

const BillingSettings = (props) => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    Promise.allSettled([
      BillingFeature.fetchBillingInformation()(dispatch),
      BillingFeature.fetchCustomerCard()(dispatch),
    ]).then(() => setInitialized(true));
  }, [dispatch]);

  if (!initialized) {
    return (
      <Box sx={{ flex: 1, display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <PaymentMethod />
      <Box height="2rem" />
      <BillingAddress />
      <Box height="2rem" />
      <PaymentReceipts />
    </Box>
  );
};

const MemoizedBillingSettings = React.memo(BillingSettings);

export { MemoizedBillingSettings as BillingSettings };
